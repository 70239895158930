<template>
  <div style="flex:1;width: inherit;" class="d-flex flex-column">
    <div>
      <!-- 选择小区 -->
      <v-progress-linear indeterminate :active="loading"></v-progress-linear>
      <v-row>
        <v-col>
          <v-tabs show-arrows center-active>
            <v-tab @click="get_home_info('all')">汇总</v-tab>
            <v-tab v-for="(item, index) of xiaoqu_items" :key="index" @click="get_home_info(item._id)">{{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <!-- 第一行 -->
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="red lighten-2" dark>
            <v-card-title>小区公告（本周）</v-card-title>
            <v-card-text class="text-center text-h3 font-weight-bold">
              <span>{{ notice_num }}</span>
            </v-card-text>
            <v-card-actions class="justify-end">条</v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="teal lighten-2" dark>
            <v-card-title>报事报修（本周）</v-card-title>
            <v-card-text class="text-center text-h3 font-weight-bold">
              <span>{{ report_num }}</span>
            </v-card-text>
            <v-card-actions class="justify-end">件</v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="green lighten-2" dark>
            <v-card-title>巡逻巡检（本周）</v-card-title>
            <v-card-text class="text-center text-h3 font-weight-bold">
              <span>{{ sheet_num }}</span>
            </v-card-text>
            <v-card-actions class="justify-end">条</v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="purple lighten-2" dark>
            <v-card-title>访客登记（本周）</v-card-title>
            <v-card-text class="text-center text-h3 font-weight-bold">
              <span>{{ visitor_num }}</span>
            </v-card-text>
            <v-card-actions class="justify-end">人</v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div style="flex:1;margin-top:24px;" class="d-flex flex-column">
      <!-- 第二行 -->
      <v-row>
        <v-col cols="12" md="9">
          <ComponentEchart :echart_option="echart_month_bill_option"></ComponentEchart>
        </v-col>
        <v-col cols="12" md="3">
          <div style="height:100%" class="d-flex flex-column">
            <v-card>
              <v-card-title>今日收费（总计）</v-card-title>
              <v-card-text>
                <div class="text-center text-h3 primary--text font-weight-bold">{{ today_bill_total }}</div>
              </v-card-text>
              <v-card-actions class="justify-end">元</v-card-actions>
            </v-card>
            <v-card style="flex:1;margin-top:24px;">
              <v-card-title>今日收费（方式）</v-card-title>
              <v-card-text>
                <template v-if="today_bill_fangshi.length != 0">
                  <v-chip dark class="ma-1" :color="getColor(item._id[0])" v-for="(item, index) of today_bill_fangshi"
                    :key="index">
                    {{ item._id[0] }}：{{ Math.round(item.total * 100) / 100 }}
                  </v-chip>
                </template>
                <template v-else>
                  <p>暂无收费数据</p>
                </template>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ComponentEchart from "@/components/echart-line.vue";
export default {
  components: {
    ComponentEchart
  },
  data: () => ({
    loading: false,
    xiaoqu_items: [],
    notice_num: 0,
    sheet_num: 0,
    visitor_num: 0,
    report_num: 0,
    today_bill_total: 0,
    today_bill_fangshi: [],
    echart_month_bill_option: {}
  }),

  mounted() {
    this.get_home_info('all');
    this.get_xiaoqu()
  },
  methods: {

    // 获取小区清单
    get_xiaoqu() {
      this.$axios
        .get("/xiaoqu/getlist")
        .then((res) => {
          this.xiaoqu_items = res.data;
        });
    },

    // 获取首页信息
    async get_home_info(xiaoqu_id) {
      this.loading = true
      const month_bill_option = await this.get_this_month_bill(xiaoqu_id)
      const top_num = await this.get_top_num(xiaoqu_id)
      setTimeout(() => {
        this.echart_month_bill_option = month_bill_option
        this.notice_num = top_num.notice_num
        this.sheet_num = top_num.sheet_num
        this.visitor_num = top_num.visitor_num
        this.report_num = top_num.report_num
        this.loading = false
      }, 100);
    },

    // 首页顶部数据统计
    async get_top_num(xiaoqu_id) {
      const res = await this.$axios.get("/home/index", { params: { xiaoqu_id: xiaoqu_id } })
      return res.data
    },

    // 本月收费进度
    async get_this_month_bill(xiaoqu_id) {
      const res = await this.$axios.get("/shoukuandan/echart_month_bill_info", { params: { xiaoqu_id: xiaoqu_id } })
      console.log(res.data)

      // 筛选今天的费用数组
      this.today_bill_fangshi = res.data.filter(item => item.day == new Date().getDate())
      // 获取今天的收费总计
      this.today_bill_total = 0
      this.today_bill_fangshi.forEach(item => {
        this.today_bill_total += item.total
      })
      this.today_bill_total = Math.round(this.today_bill_total * 100) / 100
      var month_day_arr = [] // 本月日期数组
      var max_date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
      for (let i = 1; i <= max_date; i++) {
        month_day_arr.push(i)
      }
      console.log('本月日期', month_day_arr)
      var bill_pay_way_arr = [...new Set(res.data.map(item => item._id[0]))] // 将得到的收费方式去重
      var month_bill_option = {
        xAxis: {data:month_day_arr},
        yAxis:{},
        series: []
      }

      bill_pay_way_arr.forEach((item, i) => { // 循环每个费用
        month_bill_option.series.push(
          { type: "bar", smooth: true, areaStyle: {}, emphasis: { focus: 'series' }, stack: 'Total', name: item, data: [] }
        )

        month_day_arr.forEach((element_day, j) => { // 循环本月日期数组
          if (element_day <= new Date().getDate()) {
            // 随机演示数据
            // month_bill_option.series[i].data[j] = Math.floor(Math.random() * 9999)
            month_bill_option.series[i].data[j] = 0
            res.data.forEach(element_doc => {
              // if (item == element_doc._id[0] && element_day >= element_doc.day) {
              if (item == element_doc._id[0] && element_day == element_doc.day) {
                month_bill_option.series[i].data[j] = month_bill_option.series[i].data[j] + element_doc.total
              }
            })
          }
        })
        // 把最后一项收费方式增加label并计算总和
        if (i == bill_pay_way_arr.length - 1) {
          month_bill_option.series[i].label = {
            show: true,
            rotate: 66,
            fontSize: 10,
            offset: [30, -5],
            position: 'top',
            // 计算总和
            formatter: params => {
              let index = params.dataIndex
              var total = 0
              month_bill_option.series.forEach(item => {
                total += item.data[index]
              })
              return Math.round(total * 100) / 100
            }
          }
        }
      })
      month_bill_option.title = {text: new Date().getMonth() + 1 + '月缴费金额趋势',}
      console.log(month_bill_option)
      return month_bill_option
    },
    getColor(type) {
      if (type == '现金') return 'error'
      else if (type == '微信') return 'success'
      else if (type == '支付宝') return 'info'
      else if (type == '对公') return 'warning'
      else if (type == '平台') return 'purple'
      else return 'grey'
    },
  },
};
</script>
