<template>
    <v-row>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 满意度调查问卷列表
                </v-card-title>
                <v-card-text>
                    <!-- 工具栏 -->
                    <vxe-toolbar ref="toolbarRef" export print custom :refresh="{ queryMethod: getTableData }">
                        <template #buttons>
                            <vxe-button icon="vxe-icon-square-plus" @click="insertEvent()">新增</vxe-button>
                        </template>
                    </vxe-toolbar>
                    <!-- 表格 -->
                    <vxe-table ref="tableRef" :loading="loading" :data="paperItems" :export-config="{}" :print-config="{}">
                        <vxe-column type="seq" width="60"></vxe-column>
                        <vxe-column field="date" title="创建时间"
                            :formatter="({ cellValue }) => new Date(cellValue).toLocaleString()"></vxe-column>
                        <vxe-column field="title" title="标题"></vxe-column>
                        <vxe-column title="有效时间">
                            <template #default="{ row }">
                                {{ new Date(row.start).toLocaleDateString() }}至{{ new Date(row.end).toLocaleDateString() }}
                            </template>
                        </vxe-column>
                        <vxe-column field="aganwsers.length" title="答卷数量">
                            <template #default="{ row }">
                                {{ row.anwsers.length }}
                            </template>
                        </vxe-column>
                        <vxe-column field="status" title="状态">
                            <template #default="{ row }">
                                <vxe-switch disabled v-model="row.status" open-label="已启用" close-label="已禁用"></vxe-switch>
                            </template>
                        </vxe-column>
                        <vxe-column title="操作" :align="'center'">
                            <template #default="{ row }">
                                <vxe-button type="text" icon="vxe-icon-edit" @click="editEvent(row)"></vxe-button>
                                <vxe-button type="text" icon="vxe-icon-feedback" @click="anwserEvent(row)"></vxe-button>
                            </template>
                        </vxe-column>
                    </vxe-table>
                    <!-- 表单 -->
                    <vxe-modal v-model="showEditModal" :title="selectRowData ? '编辑&保存' : '新增&保存'" width="800" resize
                        destroy-on-close>
                        <template #default>
                            <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100"
                                @submit="submitEvent">
                                <vxe-form-item field="_id" title="ID" :span="24"></vxe-form-item>
                                <vxe-form-item field="title" title="名称" :span="12">
                                    <template #default="{ data }">
                                        <vxe-input v-model="data.title" placeholder="请输入问卷名称"></vxe-input>
                                    </template>
                                </vxe-form-item>
                                <vxe-form-item field="status" title="状态" :span="12">
                                    <template #default="{ data }">
                                        <vxe-switch v-model="data.status" open-label="已启用" close-label="已禁用"></vxe-switch>
                                    </template>
                                </vxe-form-item>
                                <vxe-form-item field="start" title="开始时间" :span="12">
                                    <template #default="{ data }">
                                        <vxe-input type="date" v-model="data.start" placeholder="请输入问卷开始时间"
                                            transfer></vxe-input>
                                    </template>
                                </vxe-form-item>
                                <vxe-form-item field="end" title="结束时间" :span="12">
                                    <template #default="{ data }">
                                        <vxe-input type="date" v-model="data.end" placeholder="请输入问卷结束时间"
                                            transfer></vxe-input>
                                    </template>
                                </vxe-form-item>
                                <vxe-form-item :align="'right'" title-align="left" :span="24">
                                    <template #default>
                                        <vxe-button type="submit">提交</vxe-button>
                                    </template>
                                </vxe-form-item>
                            </vxe-form>
                        </template>
                    </vxe-modal>
                    <vxe-modal v-model="showAnwserModal" title="结果" width="1200" height="800" resize destroy-on-close>
                        <template #default>
                            <vxe-grid ref="xGrid" v-bind="gridOptions">
                                <template #wx_user_default="{ row }">
                                    {{ row.wx_user.loudong && row.wx_user.loudong.name }}
                                    {{ row.wx_user.danyuan && row.wx_user.danyuan.name }}
                                    {{ row.wx_user.fangwu && row.wx_user.fangwu.name }}
                                    {{ row.wx_user.fangwu && row.wx_user.fangwu.yezhu }}
                                    {{ row.wx_user.fangwu && row.wx_user.fangwu.phone }}
                                </template>
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import { VXETable } from 'vxe-table'
export default {
    components: {
        XiaoquLists,
    },
    data: () => ({
        loading: false,
        // 当前选择小区
        xiaoqu: null,
        paperItems: [],
        formData: {
            title: '',
            start: new Date().toLocaleDateString(),
            end: new Date().toLocaleDateString(),
            status: true,
        },
        defaultFormData: {
            title: '',
            start: new Date().toLocaleDateString(),
            end: new Date().toLocaleDateString(),
            status: true,
        },
        formRules: {
            title: [
                { required: true, message: '请输入名称' }
            ],
            start: [
                { required: true, message: '请输入日期' }
            ],
            end: [
                { required: true, message: '请输入日期' }
            ],
        },
        selectRowData: null,
        showEditModal: false,
        // 问题表
        showAnwserModal: false,
        gridOptions: {
            size: 'mini',
            height: '100%',
            showOverflow: true,
            exportConfig: { type: 'xlsx' },
            printConfig: {},
            customConfig: {},
            toolbarConfig: {
                export: true,
                print: true,
                custom: true,
                zoom: true,
            },
            columns: [
                { type: 'seq', title: '序列', width: 50 },
                { field: 'wx_user', title: '用户绑定房屋', slots: { default: 'wx_user_default' } },
                {
                    title: '答卷', children: [
                        { field: 'anwserCount', title: '总得分', align: 'center' }
                    ]
                },
            ],
            data: [],
        }
    }),
    watch: {

    },
    created() {
        this.$nextTick(() => {
            // 手动将表格和工具栏进行关联
            this.$refs.tableRef.connect(this.$refs.toolbarRef)
        })
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.getTableData()
            } else {
                this.paperItems = []
            }
        },

        // 获取数据***************************************************************
        // 获取问卷列表
        getTableData() {
            this.loading = true
            this.$axios.get("/paper/lists?xiaoqu=" + this.xiaoqu._id)
                .then((res) => {
                    this.paperItems = res.data
                    this.loading = false
                })
        },
        // 点击【新增】事件
        insertEvent() {
            this.formData = Object.assign({}, this.defaultFormData)
            this.formData.xiaoqu = this.xiaoqu
            // 创建的时候先把最新的问题获取保存到paperItem.questions
            this.$axios.get("/paper/question")
                .then((res) => {
                    this.formData.questions = res.data
                    this.selectRowData = null
                    this.showEditModal = true
                })
        },
        // 点击【编辑】事件
        editEvent(row) {
            this.formData = Object.assign({}, row)
            this.selectRowData = row
            this.showEditModal = true
        },
        // 提交数据
        submitEvent() {
            const $table = this.$refs.tableRef
            this.loading = true
            this.$axios.post("/paper/update", this.formData)
                .then(res => {
                    if (this.selectRowData) {
                        VXETable.modal.message({ content: '保存成功', status: 'success' })
                        Object.assign(this.selectRowData, res.data)
                    } else {
                        VXETable.modal.message({ content: '新增成功', status: 'success' })
                        $table.insert(res.data)
                    }
                    this.loading = false
                    this.showEditModal = false
                }).catch(error => {
                    VXETable.modal.message({ content: error.message, status: 'error' })
                    this.loading = false
                })
        },
        // 点击问题列表
        anwserEvent(row) {
            this.gridOptions.data = row.anwsers
            this.gridOptions.columns = [
                { type: 'seq', title: '序列', width: 50, align: 'center' },
                { field: 'wx_user', title: '用户绑定房屋', slots: { default: 'wx_user_default' } },
                {
                    title: this.xiaoqu.name + '【' + row.title + '】答卷', children: [
                        { field: 'anwserCount', title: '总得分', align: 'center' }
                    ]
                },
            ]
            this.gridOptions.data.forEach(anwser => {
                var anwserCount = 0
                anwser.questions.forEach(question => {
                    if (this.gridOptions.columns[2].children.findIndex(column => column.title === question.title) === -1) {
                        this.gridOptions.columns[2].children.push({ field: 'anwserFor' + question.title, title: question.title, align: 'center' })
                    }
                    if (question.id < 10) {
                        anwserCount += Number(question.anwser)
                    }
                    anwser['anwserFor' + question.title] = question.anwser
                })
                anwser['anwserCount'] = anwserCount
            })
            this.showAnwserModal = true
        }
    },
};
</script>
