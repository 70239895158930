import noticelistView from '../views/services/noticesView.vue'
import votelistView from '../views/services/votesView.vue'
import reportlistView from '../views/services/reportsView.vue'
import eventlistView from '../views/services/eventsView.vue'
import paperlistView from '../views/services/papersView.vue'
import forumlistView from '../views/services/forumsView.vue'

import layoutView from '../layout/default.vue'

const vote_routes = {
    path: '/services',
    component: layoutView,
    children: [
        {
            path: '/services/notices',
            name: 'notices',
            component: noticelistView
        },
        {
            path: '/services/votes',
            name: 'votes',
            component: votelistView
        },
        {
            path: '/services/reports',
            name: 'reports',
            component: reportlistView
        },
        {
            path: '/services/events',
            name: 'events',
            component: eventlistView
        }, {
            path: '/services/papers',
            name: 'papers',
            component: paperlistView
        }, {
            path: '/services/forums',
            name: 'forums',
            component: forumlistView
        }
    ]
}

export default vote_routes