import xiaoquguanliView from '../views/fangchanguanli/xiaoquguanliView.vue'
import loudongguanliView from '../views/fangchanguanli/loudongguanliView.vue'
import fangwuguanliView from '../views/fangchanguanli/fangwuguanliView.vue'
import yezhulistView from '../views/fangchanguanli/yezhulistView.vue'
import importView from '../views/fangchanguanli/importView.vue'
import reportView from '../views/fangchanguanli/reportView.vue'
import layoutView from '../layout/default.vue'

const fangchanguanli_routes = {
  path: '/fangchanguanli',
  component: layoutView,
  children: [
    {
      path: '/fangchanguanli/xiaoquguanli',
      name: 'xiaoquguanli',
      component: xiaoquguanliView
    },
    {
      path: '/fangchanguanli/loudongguanli',
      name: 'loudongguanli',
      component: loudongguanliView
    },
    {
      path: '/fangchanguanli/fangwuguanli',
      name: 'fangwuguanli',
      component: fangwuguanliView
    },
    {
      path: '/fangchanguanli/yezhu',
      name: 'yezhu',
      component: yezhulistView
    },
    {
      path: '/fangchanguanli/import',
      name: 'import',
      component: importView
    },
    {
      path: '/fangchanguanli/yezhulists',
      name: 'yezhulists',
      component: yezhulistView
    },
    {
      path: '/fangchanguanli/report',
      name: 'report',
      component: reportView
    },
  ]
}

export default fangchanguanli_routes