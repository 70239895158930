<template>
    <v-data-table :headers="headers" :items="items" sort-by="daoqishijian" class="elevation-2" style="flex:1">
        <template v-slot:top>
            <v-snackbar app top :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
            </v-snackbar>
            <v-toolbar flat>
                <v-toolbar-title>权限列表</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                            新增
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="editedItem.name" label="角色"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <div style="height:50vh;overflow: auto;">
                                            <v-treeview v-model="editedItem.navigation" transition return-object
                                                :items="navigation_items" item-key="_id" selectable>
                                            </v-treeview>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                取消
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.navigation`]="{ item }">
            <span v-for="x in item.navigation" :key="x._id">【{{ x.name }}】</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="primary" small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>

    </v-data-table>
</template>
<script>
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: ''
        },
        dialog: false,
        headers: [
            { text: 'id', width: 100, value: '_id', },
            { text: '角色名称', width: 100, value: 'name', },
            { text: '权限模块', value: 'navigation' },
            { text: '编辑/删除', width: 100, value: 'actions' },
        ],
        items: [],
        editedIndex: -1,
        navigation_items: [],
        editedItem: {}
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '添加' : '编辑'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },
    created() {
        this.$axios.get('/navigation')
            .then((res) => {
                this.navigation_items = res.data
            })
        this.getlist()
    },

    methods: {
        getlist() {
            this.$axios.get('/permission')
                .then((res) => {
                    this.items = res.data
                })
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {}
                this.editedIndex = -1
            })
        },
        deleteItem(item) {
            this.$axios.post('/permission/del', item)
                .then((res) => {
                    this.getlist()
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.snackbar = {
                        show: true,
                        text: error,
                        color: "error"
                    }
                })
        },
        save() {
            if (this.editedIndex > -1) {
                // 更新
                this.$axios.post('/permission/update', this.editedItem)
                    .then((res) => {
                        console.log(res)
                        this.close()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackbar = {
                            show: true,
                            text: error,
                            color: "error"
                        }
                    })
            } else {
                // 添加
                this.$axios.post('/permission/create', this.editedItem)
                    .then((res) => {
                        console.log(res)
                        this.close()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackbar = {
                            show: true,
                            text: error,
                            color: "error"
                        }
                    })
            }
        },
    },
}
</script>