import accountListView from '../views/account/listView.vue'
import accountLogView from '../views/account/logView.vue'
import accountPermissionView from '../views/account/permissionView.vue'
import layoutView from '../layout/default.vue'

const account_routes = {
  path: '/account',
  component: layoutView,
  children: [
    {
      path: '/account/lists',
      name: 'lists',
      component: accountListView
    },
    {
      path: '/account/logs',
      name: 'log',
      component: accountLogView
    },
    {
      path: '/account/permissions',
      name: 'permissions',
      component: accountPermissionView
    },
  ]
}

export default account_routes