<template>
    <v-row>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 库存统计
                </v-card-title>
                <v-card-text style="flex:1;height: 60%;" ref="chart">

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import * as echarts from 'echarts';
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        chart: null,
        xiaoqu: null,
        loading: false,
        items: []
    }),
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.getlist()
            } else {
                this.items = []
            }
        },
        // 获取物料列表
        getlist() {
            this.loading = true
            this.items = []
            this.$axios.get('/vms/lists', { params: { xiaoqu: this.xiaoqu._id } })
                .then((res) => {
                    this.loading = false
                    this.items = res.data
                    this.drawer()
                })
        },
        drawer() {
            var data = []
            this.items.forEach(item => {
                data.push({ value: item.total, name: item.name })
            })
            if (this.chart == null || this.chart == "" || this.chart == undefined) {
                this.chart = echarts.init(this.$refs.chart)
            }
            var option = {
                tooltip: {},
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 4
                        },
                        label: {
                            show: true
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        data: data
                    }
                ]
            }
            this.chart.setOption(option, true);
            window.onresize = () => {
                this.chart.resize();
            };
        }
    },
};
</script>
