<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <v-col cols="12" sm="3" md="3" style="height:100%">
            <v-card style="height:100%;overflow:auto">
                <v-card-title>企业列表</v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-item-group v-model="selectedCompanyItemIndex" color="primary">
                            <v-list-item v-for="(item, _id) in companyItems" :key="_id">
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.qiyejiancheng"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;" class="d-flex flex-column">
                <v-card-title>记账列表</v-card-title>
                <v-card-title class="flex-nowrap">
                    <!-- 开始时间 -->
                    <V-menu v-model="startMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="开始时间" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" @input="startMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 结束时间 -->
                    <V-menu v-model="endMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="结束时间" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" @input="endMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 收款类型下拉框 -->
                    <v-select :items="type_items" label="类型" v-model="type_selected" outlined dense hide-details
                        class="mx-5"></v-select>
                    <!-- 收款方式下拉框 -->
                    <v-select :items="pay_way_items" label="方式" v-model="pay_way_selected" outlined dense hide-details
                        class="mr-5"></v-select>
                    <!-- 状态下拉框 -->
                    <v-select :items="status_items" label="状态" v-model="status_selected" outlined dense hide-details
                        class="mr-5"></v-select>
                    <v-btn color="primary" :disabled="selectedCompanyItemIndex == null" @click="getlist" :loading="loading"
                        class="mr-5">
                        查询
                    </v-btn>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="selectedCompanyItemIndex == null">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-select :items="type_items" v-model="editedItem.type" label="类型"
                                                    :rules="[value => !!value || '必填']">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-menu v-model="date_menu" :close-on-content-click="false"
                                                    :nudge-right="40" transition="scale-transition" offset-y
                                                    min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editedItem.date" label="日期" readonly
                                                            v-bind="attrs" v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="editedItem.date"
                                                        @input="date_menu = false"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.name" label="费用名称"
                                                    :rules="[value => !!value || '必填']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.amount" type="number" suffix="元"
                                                    label="金额" :rules="[value => !!value || '必填']"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-radio-group v-model="editedItem.pay_way" row label="记账类型">
                                                    <v-radio label="现金" value="现金" color="error"></v-radio>
                                                    <v-radio label="银行卡" value="银行卡" color="warning"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.from" label="对方名称"
                                                    :rules="[value => !!value || '必填']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea v-model="editedItem.beizhu" label="费用备注"
                                                    :rules="[value => !!value || '必填']"></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-title class="red--text text-subtitle-1">
                    【功能说明】一般用于记录企业现金流水及银行卡流水<br>
                </v-card-title>
                <v-card-title>
                    <h1>现金余额：{{ cash }}</h1>
                    <h1 class="ml-10">银行卡余额：{{ blank }}</h1>
                </v-card-title>
                <v-card-text style="flex:1;overflow: auto;">
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading"
                        :options.sync="options" :server-items-length="total_num" style="height:100%;overflow: auto;">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleDateString() }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <v-chip :color="getColor(item.type)" dark>
                                {{ item.type == 'in' ? '收入' : '支出' }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="primary" class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-icon color="error" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:[`body.append`]="{ headers }">
                            <tr>
                                <td v-for="(header, i) in headers" :key="i">
                                    <div v-if="header.text == '日期'">
                                        汇总
                                    </div>
                                    <div v-if="header.text == '金额(元)'">
                                        {{ pageTotal }}
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: "",
        },
        companyItems: [],
        selectedCompanyItemIndex: null,
        selectedCompany: {},
        // 日期选择框
        startMenu: false,
        endMenu: false,
        startDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        endDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        status_items: [{ value: 1, text: '正常' }, { value: 0, text: '删除' }],
        pay_way_items: [
            { value: '全部', text: '全部' },
            { value: '现金', text: '现金' },
            { value: '银行卡', text: '银行卡' }
        ],
        type_selected: '全部',
        status_selected: 1,
        pay_way_selected: '全部',
        pageTotal: 0,
        options: {},
        total_num: 0,
        dialog: false,
        xiaoqu: null,
        loading: false,
        date_menu: false,
        editedIndex: -1,
        editedItem: {
            type: 'in',
            name: '',
            from: '',
            amount: 0,
            pay_way: '现金',
            beizhu: '',
            date: new Date().toISOString().substr(0, 10)
        },
        defaultItem: {
            type: 'in',
            name: '',
            from: '',
            amount: 0,
            pay_way: '现金',
            beizhu: '',
            date: new Date().toISOString().substr(0, 10)
        },
        // 计费类型
        type_items: [
            { value: '全部', text: '全部' },
            { text: '收入', value: 'in' },
            { text: '支出', value: 'out' }
        ],
        // 表单列表
        cash: 0,
        blank: 0,
        items: [],
        headers: [
            { text: "日期", sortable: false, value: "date" },
            { text: "类型", sortable: true, value: "type" },
            { text: "名称", sortable: false, value: "name" },
            { text: "对方", sortable: false, value: "from" },
            { text: "操作人", sortable: true, value: "user.nickname" },
            { text: "金额(元)", sortable: false, value: "amount" },
            { text: "方式", sortable: false, value: "pay_way" },
            { text: "操作", sortable: false, value: "actions" },
            { text: "删除", sortable: false, value: "delete" },
        ],
    }),
    mounted() {
        this.get_company_list();
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '添加' : '编辑'
        },
    },
    watch: {
        selectedCompanyItemIndex(index) {
            this.selectedCompany = this.companyItems[index]
            if (this.selectedCompany) {
                this.getlist()
            }
        },
        options: {
            handler() {
                if (this.selectedCompany) {
                    this.getlist()
                }
            },
            deep: true,
        },
        dialog(val) {
            val || this.close()
        }
    },
    methods: {
        // 获取企业列表
        get_company_list() {
            this.$axios.get("/company/getlist")
                .then((res) => {
                    this.companyItems = res.data
                })
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.editedItem.date = new Date(this.editedItem.date).toLocaleDateString()
            this.dialog = true
        },
        // 删除费用
        deleteItem(item) {
            this.$axios.post('/record/del', item)
                .then((res) => {
                    if (!res.data.error) {
                        this.getlist()
                    }
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.error ? "error" : "success",
                    }
                })
        },
        close() {
            this.dialog = false
            this.$refs.form.resetValidation()
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        // 获取费项列表
        getlist() {
            this.loading = true
            this.items = []
            this.pageTotal = 0
            this.cash = 0
            this.blank = 0
            var params = {
                startDate: this.startDate, endDate: this.endDate, company: this.selectedCompany._id, status: this.status_selected, page: this.options.page, itemsPerPage: this.options.itemsPerPage
            }
            if (this.pay_way_selected != '全部') {
                params.pay_way = this.pay_way_selected
            }
            if (this.type_selected != '全部') {
                params.type = this.type_selected
            }
            this.$axios.get('/record/lists/detail', { params: params })
                .then((res) => {
                    res.data.doc.forEach(element => {
                        this.pageTotal += element.amount
                    });
                    this.pageTotal = Math.round(this.pageTotal * 100) / 100

                    this.loading = false
                    this.items = res.data.doc
                    this.total_num = res.data.total_num
                    res.data.total_money_arr.forEach(item => {
                        this.cash += item._id.pay_way == '现金' ? item._id.type == 'in' ? item.total : (-1 * item.total) : 0
                        this.blank += item._id.pay_way == '银行卡' ? item._id.type == 'in' ? item.total : (-1 * item.total) : 0
                    })
                })
        },
        // 保存到数据库---------------------------------------------------------------
        save() {
            if (this.$refs.form.validate()) {
                this.editedItem.amount = Math.round(this.editedItem.amount * 100) / 100
                if (this.editedIndex > -1) {
                    // 更新
                    this.$axios.post('/record/update', this.editedItem)
                        .then((res) => {
                            console.log(res)
                            this.close()
                            this.getlist()
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: res.data.errors ? "error" : "success",
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.snackbar = {
                                show: true,
                                text: error,
                                color: "error"
                            }
                        })
                } else {
                    // 添加
                    this.editedItem.company = this.selectedCompany
                    console.log(this.editedItem)
                    this.$axios.post('/record/create', this.editedItem)
                        .then((res) => {
                            console.log(res)
                            this.close()
                            this.getlist()
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: res.data.errors ? "error" : "success",
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.snackbar = {
                                show: true,
                                text: error,
                                color: "error"
                            }
                        })
                }
            }
        },
        getColor(type) {
            if (type == 'in') return 'primary'
            else return 'red'
        },
    },
};
</script>
