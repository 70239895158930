<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <v-col cols="12" sm="12" md="12" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>
                    微信商户对账
                </v-card-title>
                <v-card-text class="d-flex">
                    <!-- 日期范围 -->
                    <v-date-picker multiple v-model="dates" locale="zh-CN"></v-date-picker>
                    <!-- 公司下拉框 -->
                    <v-sheet>
                        <v-select class="mx-5" return-object :items="companys" label="企业简称(子商户号)" item-text="qiyejiancheng"
                            item-value="_id" v-model="company_selected">
                            <template v-slot:item="{ item }">
                                {{ item.qiyejiancheng }}<span class="red--text">({{ item.sub_mchid }})</span>
                            </template>
                        </v-select>
                        <v-btn :disabled="!company_selected.sub_mchid" @click="tradebill" class="mx-5" color="primary"
                            :loading="loading">
                            下载交易账单
                        </v-btn>
                        <v-btn :disabled="!company_selected.sub_mchid" @click="fundflowbill" class="mx-5" color="primary"
                            :loading="loading">
                            下载资金账单
                        </v-btn>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        companys: [],
        company_selected: '',
        // 日期选择框
        datesMenu: false,
        dates: [],
        loading: false,
        content: ''
    }),
    mounted() {
        this.get_companys();
    },
    methods: {
        // 获取当前用户下的子商户号
        get_companys() {
            this.$axios.get("/company/getlist")
                .then((res) => {
                    this.companys = res.data
                })
        },
        // 下载交易账单
        tradebill() {
            this.loading = true
            var get_data = {
                dates: this.dates, sub_mchid: this.company_selected.sub_mchid
            }
            this.$axios.post("/wechat/check/tradebill", get_data)
                .then((res) => {
                    this.content = res.data.replace(/,/g, '	').replace(/`/g, '')
                    let blob = new Blob([this.content], { type: "application/vnd.ms-excel" });
                    console.log(blob)
                    let url = window.URL.createObjectURL(blob);
                    console.log(url)
                    let a = document.createElement('a')
                    a.setAttribute('download', this.company_selected.qiyejiancheng + '交易账单')
                    a.href = url
                    a.click()
                    this.loading = false
                })
        },
        fundflowbill() {
            this.loading = true
            var get_data = {
                dates: this.dates, sub_mchid: this.company_selected.sub_mchid
            }
            this.$axios.post("/wechat/check/fundflowbill", get_data)
                .then((res) => {
                    this.content = res.data.replace(/,/g, '	').replace(/`/g, '')
                    let blob = new Blob([this.content], { type: "application/vnd.ms-excel" });
                    console.log(blob)
                    let url = window.URL.createObjectURL(blob);
                    console.log(url)
                    let a = document.createElement('a')
                    a.setAttribute('download', this.company_selected.qiyejiancheng + '资金账单')
                    a.href = url
                    a.click()
                    this.loading = false
                })
        }
    }
}
</script>
