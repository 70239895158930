<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
        <v-col cols="12" sm="3" md="3" style="height:100%">
            <v-card style="height:100%" class="d-flex flex-column">
                <v-card-title>
                    房屋列表
                    <v-spacer></v-spacer>
                    {{ listModel ? '列表模式' : '搜索模式' }}
                    <v-btn icon small @click="listModel = !listModel"><v-icon
                            color="primary">mdi-cached</v-icon></v-btn>
                </v-card-title>
                <!-- 列表模式 -->
                <v-card-text class="d-flex flex-column" style="height:100%;overflow:auto;" v-if="listModel">
                    <v-select :items="xiaoqu_items" item-text="name" item-value="_id" label="选择小区" v-model="xiaoqu"
                        single-line @change="change_xiaoqu"></v-select>
                    <!-- 判断小区对象是否为空 -->
                    <v-progress-linear v-show="Object.keys(xiaoqu).length != 0 && loudong_items.length == 0"
                        indeterminate color="primary"></v-progress-linear>

                    <v-alert type="error" outlined dense v-show="Object.keys(xiaoqu).length == 0">您还没有选择小区！
                    </v-alert>
                    <v-treeview style="height:100%;overflow:auto;" :items="loudong_items" item-key="_id" transition
                        :load-children="fetchFangwus" item-disabled="locked" :active.sync="active" return-object
                        open-on-click dense activatable>
                        <template v-slot:prepend="{ item, open, active }">
                            <v-icon v-if="item.xiaoqu" :color="open ? 'primary' : ''" small>
                                mdi-city
                            </v-icon>
                            <!-- 单元开 -->
                            <v-icon v-if="item.loudong && open" color="primary" small>
                                mdi-door-open
                            </v-icon>
                            <!-- 单元关 -->
                            <v-icon v-if="item.loudong && !open" small>
                                mdi-door-sliding
                            </v-icon>
                            <v-icon v-if="item.danyuan" :color="active ? 'primary' : ''" small>
                                mdi-home
                            </v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <span v-if="item.danyuan">
                                {{ item.name }}【{{ item.yezhu }}】
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="item.wx_users.length" color="success" small v-bind="attrs"
                                            v-on="on">
                                            mdi-wechat
                                        </v-icon>
                                    </template>
                                    <div>共计{{ item.wx_users.length }}人绑定该房产</div>
                                    <div v-for="(wx_user, index) in item.wx_users" :key="index">
                                        {{ index + 1 }} :
                                        {{ wx_user.nickname ? wx_user.nickname : '未实名' }} - {{ wx_user.phone }}
                                    </div>
                                </v-tooltip>
                            </span>
                            <span v-else>{{ item.name }}</span>
                        </template>
                    </v-treeview>
                </v-card-text>
                <!-- 搜索模式 -->
                <v-card-text class="d-flex flex-column" style="height:100%;overflow:auto;" v-else>
                    <v-select :items="xiaoqu_items" item-text="name" item-value="_id" label="选择小区" v-model="xiaoqu"
                        single-line @change="change_xiaoqu"></v-select>
                    <v-text-field v-model="yezhuSearch" label="业主姓名" hint="输入业主姓名" @click:append="searchYezhu" dense
                        outlined append-icon="mdi-account-search"
                        v-show="Object.keys(xiaoqu).length > 0"></v-text-field>
                    <v-treeview style="height:100%;overflow:auto;" :items="searchFangwuLists" item-key="_id"
                        item-disabled="locked" :active.sync="active" return-object open-on-click dense activatable>
                        <template v-slot:label="{ item }">
                            {{ item.danyuan.loudong.name }}{{ item.danyuan.name }}{{ item.name }}【{{ item.yezhu }}】
                        </template>
                    </v-treeview>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card style="height:100%" class="d-flex flex-column">
                <v-card-title>

                    {{ active.length ? active[0].danyuan.loudong.name + '#' + active[0].danyuan.name + '#' +
                        active[0].name
                        :
                        null }}
                    费用列表
                    <!-- 刷新 -->
                    <v-btn class="ml-5" :disabled="!active.length" small color="accent" @click="refresh">刷 新
                    </v-btn>
                    <!-- 新增费用 -->
                    <v-dialog v-model="feixiang_dialog" max-width="680px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-5" color="primary" v-bind="attrs" v-on="on"
                                :disabled="!active.length || !active[0].danyuan" small>
                                添 加
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                {{ formTitle }}
                            </v-card-title>

                            <v-card-text>
                                <div class="d-flex flex-column">
                                    <div class="mt-2 pa-2 d-flex" :style="'border:1px solid ' + leixing.color" v-for="(leixing, index) in [
                                        { value: '按面积', color: '#1976d2', unit: '/m²/月' },
                                        { value: '按户数', color: 'orange', unit: '/户/月' },
                                        { value: '按读表', color: 'green', unit: '/单位' },
                                        { value: '暖气费', color: 'red', unit: '/m²/月' },
                                        { value: '临时费', color: 'grey' }]" :key="index">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="3" class="align-self-center">
                                                    <v-chip dark :color="leixing.color">
                                                        {{ leixing.value }} ￥{{ leixing.unit }}
                                                    </v-chip>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-progress-circular indeterminate color="primary"
                                                        v-show="loading_feixiang">
                                                    </v-progress-circular>
                                                    <div class="d-flex flex-wrap">
                                                        <div v-for="item in feixiang_items" :key="item._id">
                                                            <template v-if="item.leixing == leixing.value">
                                                                <v-checkbox :value="item" :disabled="!item.status" dense
                                                                    hide-details v-model="check_feixiang_items"
                                                                    class="mr-4">
                                                                    <template v-slot:label>
                                                                        <!-- 名称 -->
                                                                        <span
                                                                            :class="!item.status ? 'text-decoration-line-through' : ''">{{
                                                                                item.mingcheng }}
                                                                            <span class="red--text">{{ item.danjia
                                                                                }}</span>
                                                                        </span>
                                                                    </template>
                                                                </v-checkbox>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </div>
                                </div>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="feixiang_dialog = false">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="create">
                                    添加
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" :disabled="selected_feiyong.length == 0" small @click="notice_print">
                        缴费通知单
                    </v-btn>
                    <v-btn color="success" :disabled="!active.length" small @click="getHistoryShoukuandanEvent"
                        class="ml-5">
                        收款记录
                    </v-btn>
                    <!-- 删除选择费用 -->
                    <v-btn color="error" :disabled="selected_feiyong.length == 0" small @click="btn_del" class="ml-5">
                        删 除
                    </v-btn>
                </v-card-title>
                <v-card-text style="flex:1;height:0" class="d-flex flex-column">
                    <v-data-table fixed-header v-model="selected_feiyong" :headers="headers" :items="feiyong_items"
                        item-key="_id" :loading="loading_feiyong" show-select hide-default-footer disable-pagination
                        height="38vh" checkbox-color="primary">
                        <!-- 是否欠费字段 -->
                        <template v-slot:[`item.type`]="{ item }">
                            <span :class="item.type == '待缴' ? 'error--text' : 'success--text'">{{ item.type }}</span>
                        </template>
                        <!-- 名称字段 -->
                        <template v-slot:[`item.mingcheng`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.danjia" @save="save_mingcheng(item)"
                                @cancel="cancel" @open="open" @close="close" large cancel-text="取消" save-text="更新">
                                <span class="blue--text">{{ item.mingcheng }}</span>
                                <template v-slot:input>
                                    <v-text-field v-model="item.mingcheng" style="width: 150px;"></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <!-- 单价字段 -->
                        <template v-slot:[`item.danjia`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.danjia" @save="save_danjia(item)" @cancel="cancel"
                                @open="open" @close="close" large cancel-text="取消" save-text="更新">
                                <span class="blue--text">{{ item.danjia }}</span>
                                <template v-slot:input>
                                    <v-text-field v-model="item.danjia" type="number"
                                        style="width: 150px;"></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <!-- 起数字段 -->
                        <template v-slot:[`item.start`]="{ item }">
                            <v-edit-dialog v-if="item.feixiang.leixing == '按读表'" :return-value.sync="item.start"
                                @save="save_start(item)" @cancel="cancel" @open="open" @close="close" large
                                cancel-text="取消" save-text="更新">
                                <span class="blue--text">{{ item.start }}</span>
                                <template v-slot:input>
                                    <v-text-field v-model="item.start" type="number"
                                        style="width: 150px;"></v-text-field>
                                </template>
                            </v-edit-dialog>
                            <span v-else-if="item.feixiang.leixing == '临时费'">{{ item.start }}</span>
                            <!-- 如果是按面积||按住户||暖气费 -->
                            <v-edit-dialog v-else :return-value.sync="item.start" @save="save_start(item)"
                                @cancel="cancel" @open="open" @close="close" large cancel-text="取消" save-text="更新">
                                <span class="blue--text">{{ item.start }}</span>
                                <template v-slot:input>
                                    <v-date-picker no-title v-model="item.start" scrollable locale="zh-cn" class="mt-4">
                                    </v-date-picker>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <!-- 止数字段 -->
                        <template v-slot:[`item.end`]="{ item }">
                            <v-edit-dialog v-if="item.feixiang.leixing == '按读表'" :return-value.sync="item.end"
                                @save="save_end(item)" @cancel="cancel" @open="open" @close="close" large
                                cancel-text="取消" save-text="更新">
                                <span class="blue--text">{{ item.end }}</span><span class="error--text">{{ item.end * 1
                                    < item.start * 1 ? '/轮转' : '' }}</span>
                                        <template v-slot:input>
                                            <v-text-field v-model="item.end" type="number"
                                                style="width: 150px;"></v-text-field>
                                        </template>
                            </v-edit-dialog>
                            <span v-else-if="item.feixiang.leixing == '临时费'">{{ item.end }}</span>
                            <!-- 如果是按面积||按住户||暖气费 -->
                            <v-edit-dialog v-else :return-value.sync="item.end" @save="save_end(item)" @cancel="cancel"
                                @open="open" @close="close" large cancel-text="取消" save-text="更新">
                                <span class="blue--text">{{ item.end }}</span>
                                <template v-slot:input>
                                    <v-date-picker no-title v-model="item.end" scrollable locale="zh-cn" class="mt-4">
                                    </v-date-picker>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <!-- 周期/倍数字段 -->
                        <template v-slot:[`item.zhouqi_beishu`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.zhouqi_beishu" @save="save_zhouqi_beishu(item)"
                                @cancel="cancel" @open="open" @close="close" large cancel-text="取消" save-text="更新"
                                absolute>
                                <span class="blue--text">{{ item.zhouqi_beishu }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-if="item.feixiang.leixing == '按面积' || item.feixiang.leixing == '按户数' || item.feixiang.leixing == '取暖费'"
                                        v-model="item.zhouqi_beishu" type="number" prefix="周期" suffix="个月"
                                        style="width: 150px;"
                                        :rules="[value => !!value || '必填', value => value > 0 || '必须大于0']">
                                    </v-text-field>
                                    <v-text-field v-else v-model="item.zhouqi_beishu" type="number" prefix="倍数"
                                        style="width: 150px;"
                                        :rules="[value => !!value || '必填', value => value > 0 || '必须大于0']"></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <!-- 数量字段 -->
                        <template v-slot:[`item.shuliang`]="{ item }">
                            <span v-if="item.feixiang.leixing == '临时费' || item.feixiang.leixing == '按户数'">
                                <v-edit-dialog :return-value.sync="item.shuliang" @save="save(item)" @cancel="cancel"
                                    @open="open" @close="close" large cancel-text="取消" save-text="更新">
                                    <span class="blue--text">{{ item.shuliang }}</span>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.shuliang" type="number" prefix="数量"
                                            style="width: 150px;"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </span>
                            <span v-else>
                                {{ item.shuliang }}
                                <span v-if="item.feixiang.leixing == '按面积'">m²</span>
                                <span v-else></span>
                            </span>
                        </template>
                        <!-- 总计字段 -->
                        <template v-slot:[`item.zongji`]="{ item }">
                            <span :class="item.zongji == 0 ? '' : 'red--text'">￥{{ Math.round(item.zongji * 10000) /
                                10000
                                }}</span>
                        </template>
                        <!-- 优惠字段 -->
                        <template v-slot:[`item.youhui`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.youhui" @save="save_youhui(item)" @cancel="cancel"
                                @open="open" @close="close" large cancel-text="取消" save-text="更新" absolute>
                                <span class="orange--text">￥{{ item.youhui }}</span>
                                <template v-slot:input>
                                    <v-text-field v-model="item.youhui" type="number" prefix="优惠￥"
                                        style="width: 150px;"></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <!-- 备注字段 -->
                        <template v-slot:[`item.beizhu`]="{ item }">
                            <v-edit-dialog :return-value.sync="item.beizhu" @save="save_beizhu(item)" @cancel="cancel"
                                @open="open" @close="close" large cancel-text="取消" save-text="更新" absolute>
                                <span v-if="item.beizhu" class="blue--text">{{ item.beizhu.substr(0, 2) }}...</span>
                                <span v-else class="blue--text">[备注]</span>
                                <template v-slot:input>
                                    <v-textarea placeholder="费用备注" :autofocus="true" v-model="item.beizhu"></v-textarea>
                                </template>
                            </v-edit-dialog>
                        </template>
                    </v-data-table>
                    <div class="mt-2 d-flex justify-space-between" style="flex:1;height:0">
                        <!-- 收费/变更记录 -->
                        <v-list two-line subheader dark color="grey darken-3" class="mr-5 rounded logs">
                            <v-subheader>操作记录【 存储10条操作记录】</v-subheader>
                            <div class="text-center">
                                <v-progress-circular indeterminate color="primary" v-show="loading_history">
                                </v-progress-circular>
                            </div>
                            <v-list-item v-for="item in history_items   " :key="item._id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ new Date(item.date).toLocaleString() }} {{ item.user ?
                                        item.user.nickname : ''
                                        }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.info }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon :color="item.shoukuandan.status ? 'error' : 'success'"
                                        v-if="item.shoukuandan"
                                        @click="shoukuandan_id = item.shoukuandan._id; shoukuandan_dialog = true">
                                        <v-icon>mdi-database-search</v-icon>
                                    </v-btn>
                                    <v-btn icon v-else @click=" printActionLog(item)">
                                        <v-icon>mdi-printer</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <!-- 收费配置 -->
                        <div style="flex:1;overflow: auto;" class="getTool">
                            <div class="pa-4 d-flex justify-space-around align-center blue lighten-5 rounded">
                                <span class="text-button">余额：{{ active.length ? Math.round(active[0].yu_e * 100) / 100 :
                                    '0.00'
                                    }}</span>
                                <v-text-field v-model="text_field_yu_e" type="number" class="pa-0 mt-0 mx-5"
                                    placeholder="负数为支取，正数为存入" :disabled="!active.length" dense hide-details>
                                </v-text-field>
                                <v-text-field v-model="text_field_yu_e_beizhu" class="pa-0 mt-0 mr-5" placeholder="作用备注"
                                    :disabled="!active.length" dense hide-details>
                                </v-text-field>
                                <v-btn small :disabled="!active.length" @click="btn_modify_yu_e">确定</v-btn>
                            </div>

                            <v-radio-group v-model="radio_pay_way" row label="收费方式"
                                :disabled="selected_feiyong.length == 0 ? true : false">
                                <v-radio label="现金" value="现金" color="error"></v-radio>
                                <v-radio label="刷卡" value="刷卡" color="purple"></v-radio>
                                <v-radio label="微信" value="微信" color="success"></v-radio>
                                <v-radio label="支付宝" value="支付宝" color="info"></v-radio>
                                <v-radio label="对公" value="对公" color="warning"></v-radio>
                                <v-radio label="预存" value="预存" color="gray"></v-radio>
                            </v-radio-group>
                            <v-radio-group v-model="roundType" row label="计费方式"
                                :disabled="selected_feiyong.length == 0 ? true : false" @change="getTotal">
                                <v-radio label="默认" value="默认"></v-radio>
                                <v-radio label="四舍五入" value="四舍五入"></v-radio>
                                <v-radio label="向下取整" value="向下取整"></v-radio>
                                <v-radio label="向上取整" value="向上取整"></v-radio>
                            </v-radio-group>
                            <!-- <v-text-field :disabled="selected_feiyong.length == 0 ? true : false" label="优惠金额"
                                v-model="youhui" type="number"></v-text-field> -->
                            <v-textarea :disabled="selected_feiyong.length == 0 ? true : false" label="收费备注"
                                v-model="remark"></v-textarea>
                            <!-- 历史收费日期 -->
                            <v-container>
                                <v-row>
                                    <v-checkbox class="ml-3" :disabled="!active.length" @change="historyOpenChange"
                                        v-model="historyOpen" label="历史收费"></v-checkbox>
                                    <v-menu transition="slide-y-transition" :offset-y="true" v-model="dateMenu"
                                        :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="ml-3" :disabled="!historyOpen" v-model="shoukuanDate"
                                                v-bind="attrs" v-on="on" style="max-width:150px"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="shoukuanDate" locale="zh-CN" @input=" dateMenu = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-row>
                            </v-container>

                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="blue lighten-5">
                    <span>【姓名:{{ active.length ? active[0].yezhu : null }}】【面积:{{ active.length ? active[0].mianji :
                        null
                        }}m²】【电话:{{ active.length ? active[0].phone : null }}】</span>

                    <!-- 编辑房屋 -->
                    <v-btn class="ml-5" color="primary" small @click=" fangwu_dialog = true" :disabled="!active.length">
                        编 辑
                    </v-btn>
                    <v-dialog v-model="fangwu_dialog" max-width="500px">
                        <fangwu @fangwu_update=" fangwu_dialog = false; refresh(); fangwu_update($event)"
                            @close=" fangwu_dialog = false;" :fangwu_id="active.length ? active[0]._id : null">
                        </fangwu>
                    </v-dialog>
                    <!-- 微信 -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small class="ml-5"
                                :disabled="!(active.length && active[0].wx_users && active[0].wx_users.length && selected_feiyong.length)"
                                color="success" @click="push_weixin" :loading="loading_shouqu" v-bind="attrs"
                                v-on="on">微信通知
                            </v-btn>
                        </template>
                        <div class="pa-5">
                            <div>缴费提醒</div>
                            <div>业主姓名：{{ active.length ? active[0].danyuan.loudong.xiaoqu.name + '【' + active[0].yezhu +
                                '】'
                                : null }}</div>
                            <div>地址：{{ active.length ? active[0].danyuan.loudong.name + '#' + active[0].danyuan.name +
                                '#' +
                                active[0].name
                                :
                                null }}</div>
                            <div>账单金额：{{ total }}</div>
                        </div>
                    </v-tooltip>
                    <!-- 电话 -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small class="ml-5"
                                :disabled="!(active.length && active[0].phone && selected_feiyong.length)" color="error"
                                @click="push_phone" :loading="loading_shouqu" v-bind="attrs" v-on="on">电话通知
                            </v-btn>
                        </template>
                        <div class="pa-5">
                            <div class="red--text mb-2">电话0.2元/条</div>
                            <div style="width:300px;">
                                温馨提醒：尊敬的业主
                                {{ active.length ? active[0].yezhu : null }}
                                您好，您有管理服务费
                                {{ total }}元
                                待缴纳，请尽快到
                                {{ active.length ? active[0].danyuan.loudong.xiaoqu.name : null }}
                                物业服务中心缴清费用，感谢您的支持。
                            </div>
                        </div>
                    </v-tooltip>
                    <!-- 短信 -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small class="ml-5"
                                :disabled="!(active.length && active[0].phone && selected_feiyong.length)"
                                color="warning" @click="push_message" :loading="loading_shouqu" v-bind="attrs"
                                v-on="on">短信通知
                            </v-btn>
                        </template>
                        <div class="pa-5">
                            <div class="red--text mb-2">短信0.1元/条</div>
                            <div style="width:300px;">
                                温馨提醒：尊敬的业主
                                {{ active.length ? active[0].yezhu : null }}
                                您好，您有管理服务费
                                {{ total }}元
                                待缴纳，请尽快到
                                {{ active.length ? active[0].danyuan.loudong.xiaoqu.name : null }}
                                物业服务中心缴清费用，感谢您的支持。
                            </div>
                        </div>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <!-- 收款 -->
                    <span class="mr-5">总计:￥{{ zongji }}
                        <span v-if="youhui">- 优惠:￥{{ Math.round(youhui * 100) / 100 }}</span>
                        = <span class="text-h5 font-weight-bold red--text">￥{{ total }}</span>
                    </span>
                    <v-btn x-large color="success" :disabled="selected_feiyong.length == 0 ? true : false"
                        @click="btn_ok" :loading="loading_shouqu">确认收取</v-btn>
                </v-card-actions>
                <v-dialog v-model="shoukuandan_dialog" width="900">
                    <shoukuandan @close=" shoukuandan_dialog = false" @refresh=" refresh()"
                        :shoukuandan_id="shoukuandan_id">
                    </shoukuandan>
                </v-dialog>
                <!-- 收款记录 -->
                <v-dialog v-model="showHistoryShoukuandanModal" width="1200">
                    <v-card>
                        <v-card-title>收款记录</v-card-title>
                        <v-card-text>
                            <v-data-table :headers="shoukuandanHeaders" :items="historyShoukuandanItems"
                                :items-per-page="10">
                                <template v-slot:[`item.date`]="{ item }">
                                    {{ new Date(item.date).toLocaleString() }}
                                </template>
                                <template v-slot:[`item.user`]="{ item }">
                                    {{ item.user ? item.user.nickname : '无' }}
                                </template>
                                <template v-slot:[`item.total`]="{ item }">
                                    {{ Math.round(item.total * 100) / 100 }}
                                </template>
                                <template v-slot:[`item.youhui`]="{ item }">
                                    {{ Math.round(item.youhui * 100) / 100 }}
                                </template>
                                <template v-slot:[`item.zongji`]="{ item }">
                                    {{ Math.round(item.zongji * 100) / 100 }}
                                </template>
                                <template v-slot:[`item.status`]="{ item }">
                                    <v-chip v-if="item.status == 0" color="green" dark>已收款</v-chip>
                                    <v-chip v-if="item.status == 1" color="red" dark>已撤销</v-chip>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-btn color="primary"
                                        @click="shoukuandan_id = item._id; shoukuandan_dialog = true">详情</v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import shoukuandanComponent from './components/shoukuandanView.vue'
import fangwuComponent from './components/fangwuView.vue'
import { getLodop } from '@/utils/LodopFuncs' //导入模块
import dayjs from "dayjs";
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        overlay: false,
        listModel: true,
        yezhuSearch: '',
        searchFangwuLists: [],
        loading_feixiang: false,
        loading_feiyong: false,
        loading_history: false,
        loading_shouqu: false,
        // 活动节点
        active: [],
        // 房屋历史记录
        history_items: [],

        feixiang_dialog: false,

        fangwu_dialog: false,
        shoukuandan_dialog: false,
        shoukuandan_id: '',

        // 收款记录
        showHistoryShoukuandanModal: false,
        shoukuandanHeaders: [
            { text: "日期", sortable: false, value: "date" },
            { text: "收款单", sortable: false, value: "_id" },
            { text: "收款人", sortable: false, value: "user" },
            { text: "收款方式", sortable: false, value: "pay_way" },
            { text: "应收", sortable: false, value: "zongji" },
            { text: "优惠", sortable: false, value: "youhui" },
            { text: "实收", sortable: false, value: "total" },
            { text: "状态", sortable: false, value: "status" },
            { text: "查看", sortable: false, value: "actions" },
        ],
        historyShoukuandanItems: [],
        // 预存金额
        text_field_yu_e: '',
        text_field_yu_e_beizhu: '预存备注',

        // 历史收费
        historyOpen: false,
        shoukuanDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        dateMenu: false,
        formTitle: '添加',
        // 公司及小区数组
        xiaoqu_items: [],
        loudong_items: [],
        feixiang_items: [],
        // 被选择的费项
        check_feixiang_items: [],

        // 费用列表
        headers: [
            { text: "状态", sortable: false, align: 'center', value: "type" },
            { text: "类型", sortable: false, align: 'center', value: "feixiang.leixing" },
            { text: "名称", sortable: true, align: 'center', value: "mingcheng" },
            { text: "单价", sortable: false, align: 'center', value: "danjia" },
            { text: "起数", sortable: false, align: 'center', value: "start" },
            { text: "止数", sortable: false, align: 'center', value: "end" },
            { text: "周期/倍数", sortable: false, align: 'center', value: "zhouqi_beishu" },
            { text: "面积/数量", sortable: false, align: 'center', value: "shuliang" },
            { text: "总计", sortable: false, align: 'center', value: "zongji" },
            { text: "优惠", sortable: false, align: 'center', value: "youhui" },
            { text: "备注", sortable: false, align: 'left', value: "beizhu" },
        ],
        feiyong_items: [],

        // 费用选择数据
        selected_feiyong: [],

        // 方式
        radio_pay_way: '现金',

        // 计费方式
        roundType: '默认',

        // 选择的小区
        xiaoqu: '',

        // 优惠金额
        youhui: 0.00,

        // 费用总计
        zongji: 0.00,

        // 费用总计
        total: 0.00,

        // 收款备注
        remark: ''

    }),
    components: {
        'shoukuandan': shoukuandanComponent,
        'fangwu': fangwuComponent
    },
    mounted() {
        this.get_xiaoqu_list()
    },
    watch: {
        // 监控选中的房屋
        active: {
            handler: function (val) {
                console.log(val)
                this.refresh()
            },
            deep: true // 深度监测
        },
        // 监控选中的费用
        selected_feiyong: {
            handler: function (val) {
                console.log('监控选中的费用', val)
                this.getTotal()
                this.getRemark()
            },
            deep: true // 深度监测
        },

        // 监控新增费用弹窗
        feixiang_dialog(val) {
            if (val) {
                this.get_feixiang_lists(this.xiaoqu)
            } else {
                this.feixiang_items = []
                this.check_feixiang_items = []
            }
        },
    },
    methods: {
        // 清空 初始化
        init() {
            this.feiyong_items = [] // 清空费用列表
            this.selected_feiyong = [] // 清空选择的费用列表
            this.history_items = [] // 清空历史记录
            this.remark = '' // 清空备注
            this.text_field_yu_e = '' // 清空余额数据
            this.text_field_yu_e_beizhu = '' // 清空余额数据
            this.youhui = '' // 清空优惠
            this.historyOpen = false // 禁用历史收费
            this.shoukuanDate = new Date().toLocaleDateString().replace(/\//g, '-')
        },

        // 获取费项列表
        get_feixiang_lists(xiaoqu) {
            this.loading_feixiang = true
            this.$axios.get('/feixiang/lists', { params: { xiaoqu: xiaoqu } })
                .then((res) => {
                    setTimeout(() => {
                        this.feixiang_items = res.data
                        this.loading_feixiang = false
                    }, 500);
                })
        },

        // 获取小区列表
        get_xiaoqu_list() {
            this.$axios.get('/xiaoqu/getlist')
                .then((res) => {
                    this.xiaoqu_items = res.data
                })
        },
        // 选择小区
        change_xiaoqu(val) {
            this.get_loudong_list(val) // 获取该小区下的楼栋列表
            this.active = []
        },
        // 获取楼栋列表
        get_loudong_list(xiaoqu) {
            this.loudong_items = []
            this.$axios.get('/loudong/get_loudong_danyuan', { params: { xiaoqu: xiaoqu } })
                .then((res) => {
                    // 循环每个楼栋
                    if (res.data.length) {
                        res.data.forEach((loudong_item) => {
                            // 如果楼栋下存在单元，则每个单元添加一个用于存放房屋的空children数组
                            if (loudong_item.children) {
                                loudong_item.children.forEach((danyuan_item) => {
                                    danyuan_item.children = []
                                })
                            }
                        })
                    }
                    this.loudong_items = res.data
                })
        },

        // 获取单元子节点的房屋列表
        async fetchFangwus(item) {
            const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
            await pause(500)
            await this.$axios.get('fangwu/getlist', { params: { danyuan: item._id } })
                .then((res) => {
                    // 可能会有重复，先清空一下
                    item.children = []
                    item.children.push(...res.data)
                })
        },

        // 刷新
        async refresh() {
            this.init()
            // 如果有选择房屋
            if (this.active.length && this.active[0]) {
                this.overlay = true
                // 获取费用列表
                await this.get_feiyong_lists(this.active[0])
                // 获取历史记录
                await this.get_history_items(this.active[0])
                this.overlay = false
            }
        },
        searchYezhu() {
            this.searchFangwuLists = []
            if (this.yezhuSearch) {
                this.overlay = true,
                    this.$axios.get('fangwu/search', { params: { xiaoqu: this.xiaoqu, yezhu: this.yezhuSearch } })
                        .then((res) => {
                            // 可能会有重复，先清空一下
                            this.searchFangwuLists = res.data
                            this.overlay = false
                        })
            }
        },
        fangwu_update(new_fangwu_info) {
            this.active[0].name = new_fangwu_info.name
            this.active[0].mianji = new_fangwu_info.mianji
            this.active[0].yezhu = new_fangwu_info.yezhu
            this.active[0].phone = new_fangwu_info.phone
            this.active[0].idcard = new_fangwu_info.idcard
            this.active[0].plate = new_fangwu_info.plate
            this.active[0].beizhu = new_fangwu_info.beizhu
        },
        // 获取费用列表
        get_feiyong_lists(fangwu) {
            this.loading_feiyong = true
            this.feiyong_items = []
            // 封装成异步函数
            return new Promise((resolve) => {
                this.$axios.get('/feiyong/lists', { params: { fangwu: fangwu._id } })
                    .then((res) => {
                        this.feiyong_items = res.data
                        this.loading_feiyong = false
                        resolve(true)
                    })
            })
        },
        // 获取历史记录
        get_history_items(fangwu) {
            this.history_items = []
            this.loading_history = true
            this.$axios.get('/log/fangwu', { params: { fangwu: fangwu._id } })
                .then((res) => {
                    this.history_items = res.data
                    this.loading_history = false
                })
        },
        // 获取历史收款单
        getHistoryShoukuandanEvent() {
            this.showHistoryShoukuandanModal = true
            this.historyShoukuandanItems = []
            this.$axios.get('/shoukuandan/lists', { params: { fangwu: this.active[0]._id } })
                .then((res) => {
                    this.historyShoukuandanItems = res.data
                })
        },
        // 删除选择的费用
        btn_del() {
            this.$axios.post('/feiyong/delete', this.selected_feiyong)
                .then((res) => {
                    console.log(res.data)
                    this.selected_feiyong = []
                    this.get_feiyong_lists(this.active[0])
                })
        },

        // 新增费项
        create() {
            this.$axios.post('/feiyong/create', { feixiang_obj_arr: this.check_feixiang_items, fangwu_obj: this.active[0] })
                .then((res) => {
                    console.log(res.data)
                    this.get_feiyong_lists(this.active[0])
                    this.feixiang_dialog = false
                })
        },
        // 字段编辑弹出框
        // 单价
        save_mingcheng(item) {
            // 更新
            this.feiyongUpdate(item)
        },
        save_danjia(item) {
            // 更新
            item.zongji = item.shuliang * item.danjia * item.zhouqi_beishu
            item.zongji = Math.round(item.zongji * 10000) / 10000
            this.feiyongUpdate(item)
        },
        // 起数
        save_start(item) {
            if (item.feixiang.leixing == '按读表') {
                item.shuliang = item.end - item.start
                if (item.shuliang < 0) {
                    item.end = item.start
                    item.shuliang = 0
                }
                item.shuliang = Math.round(item.shuliang * 100) / 100
            } else {
                var start_date = new Date(item.start)
                item.end = this.formatDate(this.addMonthDate(start_date, Number(item.zhouqi_beishu)))
            }
            // 更新
            item.zongji = item.shuliang * item.danjia * item.zhouqi_beishu
            item.zongji = Math.round(item.zongji * 10000) / 10000
            this.feiyongUpdate(item)
        },

        // 止数
        save_end(item) {
            if (item.feixiang.leixing == '按读表') {
                // js float 计算有误 2.11-1 = 1.1099999999999999
                item.shuliang = item.end - item.start
                // 翻转表
                if (item.shuliang < 0) {
                    var max = Math.pow(10, item.start.length)
                    item.shuliang = max - item.start * 1 + item.end * 1
                }
                item.shuliang = Math.round(item.shuliang * 100) / 100
                item.zongji = item.shuliang * item.danjia * item.zhouqi_beishu
                item.zongji = Math.round(item.zongji * 10000) / 10000
            } else {
                var start_date = new Date(item.start)
                var end_date = new Date(item.end)

                // 计算每天的单价
                var dayDanjia = item.feixiang.danjia * item.shuliang * 12 / 365
                console.log('每天的单价：', dayDanjia)
                // 计算两个日期相差的天数
                var diffDays = (end_date - start_date) / 1000 / 60 / 60 / 24 + 1
                console.log('相差的天数', diffDays)
                // 计算两个日期相差的月数
                var diffMonth = end_date.getMonth() - start_date.getMonth() +
                    (12 * (end_date.getFullYear() - start_date.getFullYear()))

                if (diffDays <= 0) {
                    this.snackbar = {
                        show: true,
                        text: '止数必须大于起数',
                        color: "error",
                    }
                    item.end = this.formatDate(this.addMonthDate(start_date, Number(item.zhouqi_beishu)))
                    return
                } else {
                    item.zhouqi_beishu = diffMonth > 0 ? diffMonth : 1
                    item.zongji = dayDanjia * diffDays
                    item.zongji = Math.round(item.zongji * 10000) / 10000
                }
            }
            // 更新
            this.feiyongUpdate(item)
        },

        // 周期/倍数
        save_zhouqi_beishu(item) {
            if (item.feixiang.leixing == '按面积' || item.feixiang.leixing == '按户数' || item.feixiang.leixing == '取暖费') {
                item.zhouqi_beishu = Math.trunc(item.zhouqi_beishu > 0 ? item.zhouqi_beishu : 1)
                var start_date = new Date(item.start)
                item.end = this.formatDate(this.addMonthDate(start_date, item.zhouqi_beishu))
            }
            // 更新
            item.zongji = item.shuliang * item.danjia * item.zhouqi_beishu
            item.zongji = Math.round(item.zongji * 10000) / 10000
            this.feiyongUpdate(item)
        },

        // 面积/数量编辑弹出框
        save(item) {
            // 更新
            item.zongji = item.shuliang * item.danjia * item.zhouqi_beishu
            item.zongji = Math.round(item.zongji * 10000) / 10000
            this.feiyongUpdate(item)
        },
        // 优惠编辑弹出框
        save_youhui(item) {
            // 更新
            item.youhui = Math.round(item.youhui * 10000) / 10000
            this.feiyongUpdate(item)
        },
        // 备注编辑弹出框
        save_beizhu(item) {
            // 更新
            this.feiyongUpdate(item)
        },
        // 更新数据库费用
        feiyongUpdate(item) {
            this.$axios.post('/feiyong/update', item)
                .then((res) => {
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: "success",
                    }
                })
        },
        cancel() {
            this.snackbar = {
                show: true,
                text: '取消',
                color: "error",
            }
        },
        open() {
            console.log('Dialog opened')
        },
        close() {
            console.log('Dialog closed')
        },

        // 修改余额
        btn_modify_yu_e() {
            if (Number(this.text_field_yu_e) && this.text_field_yu_e_beizhu) {
                this.active[0].yu_e = Math.round((this.active[0].yu_e * 1 + this.text_field_yu_e * 1) * 100) / 100

                var postData = {
                    fangwuObj: this.active[0],
                    add_yu_e: Math.round(this.text_field_yu_e * 100) / 100,
                    beizhu: this.text_field_yu_e_beizhu
                }

                this.$axios.post('/fangwu/update/yu_e', postData)
                    .then((res) => {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "success",
                        }
                        this.text_field_yu_e = ''
                        this.text_field_yu_e_beizhu = ''
                        this.get_history_items(this.active[0])
                    })
            } else {
                this.snackbar = {
                    show: true,
                    text: '余额操作数或备注不能为空',
                    color: "error",
                }
            }
        },
        // 打印操作记录
        printActionLog(item) {
            let LODOP = getLodop()//调用getLodop获取LODOP对象
            LODOP.PRINT_INIT("")
            LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Width");

            LODOP.SET_PRINT_PAGESIZE(1, 2100, 930, "");
            LODOP.SET_SHOW_MODE("PREVIEW_NO_MINIMIZE", true); //预览窗口禁止最小化，并始终最前	
            LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印后自动关闭预览窗口

            var strBodyHtml = "<body style='line-height: 1.8;'>";
            strBodyHtml = strBodyHtml + "<p style='text-align:center;font-weight:bold;font-size:20px;'>" + item.xiaoqu.name + "物业服务中心记录凭据</p>";
            strBodyHtml = strBodyHtml + "<hr><p>" + this.active[0].danyuan.loudong.name + "#" + this.active[0].danyuan.name + "#" + this.active[0].name + "</p>";
            strBodyHtml = strBodyHtml + "<p>" + item.info + "</p>";
            strBodyHtml = strBodyHtml + "<p style='text-align:right'>" + item.user.nickname + " @ " + dayjs(item.date).format('YYYY年MM月DD日 HH:mm:ss') + "</p>";
            strBodyHtml = strBodyHtml + "</body>";
            LODOP.ADD_PRINT_HTM(32, 16, "100%", "100%", strBodyHtml);

            LODOP.PREVIEW();
        },
        // 监听是否开启历史缴费,每次都重置
        historyOpenChange() {
            this.shoukuanDate = new Date().toLocaleDateString().replace(/\//g, '-')
        },
        // 计算总的价格
        getTotal() {
            this.youhui = 0
            this.zongji = 0
            this.selected_feiyong.forEach((item) => {
                this.youhui += item.youhui * 1
                this.zongji += item.zongji
            })
            this.youhui = Math.round(this.youhui * 10000) / 10000
            this.zongji = Math.round(this.zongji * 10000) / 10000
            // 将总计四舍五入
            if (this.roundType == '默认') {
                this.total = Math.round((this.zongji - this.youhui) * 100) / 100
            } else if (this.roundType == '四舍五入') {
                this.total = Math.round(this.zongji - this.youhui)
            } else if (this.roundType == '向下取整') {
                this.total = Math.floor(this.zongji - this.youhui)
            } else if (this.roundType == '向上取整') {
                this.total = Math.ceil(this.zongji - this.youhui)
            } else {
                this.total = Math.round((this.zongji - this.youhui) * 100) / 100
            }
        },
        // 计算备注
        getRemark() {
            this.remark = ''
            this.selected_feiyong.forEach((item) => {
                if (item.beizhu) {
                    this.remark += item.mingcheng + '：' + item.beizhu + '；'
                }
            })
        },
        // 推送微信费用--------------------------------------------------
        push_weixin() {
            this.overlay = true
            this.loading_shouqu = true
            this.$axios.post('/feiyong/push_weixin', { fangwu: this.active[0], feiyongArr: this.selected_feiyong, total: this.total })
                .then((res) => {
                    if (!res.data.error) {
                        setTimeout(() => {
                            // get_history_items返回promise，所以能.then
                            this.get_history_items(this.active[0]).then(() => {
                                this.snackbar = {
                                    show: true,
                                    text: res.data,
                                    color: "success",
                                }
                            })
                        }, 500);
                    } else {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "error",
                        }
                    }
                    this.loading_shouqu = false
                    this.overlay = false
                })
        },
        // 推送语音通知--------------------------------------------------
        push_phone() {
            this.overlay = true
            this.loading_shouqu = true
            this.$axios.post('/feiyong/push_phone', { total: this.total, xiaoquObj: this.active[0].danyuan.loudong.xiaoqu, fangwuObj: this.active[0] })
                .then((res) => {
                    if (!res.data.error) {
                        setTimeout(() => {
                            // get_history_items返回promise，所以能.then
                            this.get_history_items(this.active[0]).then(() => {
                                this.snackbar = {
                                    show: true,
                                    text: res.data.message,
                                    color: "success",
                                }
                            })
                        }, 500);
                    } else {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "error",
                        }
                    }
                    this.loading_shouqu = false
                    this.overlay = false
                })
        },
        // 推送短信通知--------------------------------------------------
        push_message() {
            this.overlay = true
            this.loading_shouqu = true
            this.$axios.post('/feiyong/push_message', { total: this.total, xiaoquObj: this.active[0].danyuan.loudong.xiaoqu, fangwuObj: this.active[0] })
                .then((res) => {
                    if (!res.data.error) {
                        setTimeout(() => {
                            // get_history_items返回promise，所以能.then
                            this.get_history_items(this.active[0]).then(() => {
                                this.snackbar = {
                                    show: true,
                                    text: res.data.message,
                                    color: "success",
                                }
                            })
                        }, 500);
                    } else {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "error",
                        }
                    }
                    this.loading_shouqu = false
                    this.overlay = false
                })
        },
        // 欠款打印
        notice_print() {
            // var feiyong = this.selected_feiyong
            let LODOP = getLodop()//调用getLodop获取LODOP对象
            LODOP.PRINT_INIT("")
            LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Width");

            LODOP.SET_PRINT_PAGESIZE(1, 2100, 930, "");
            LODOP.SET_SHOW_MODE("PREVIEW_NO_MINIMIZE", true); //预览窗口禁止最小化，并始终最前	
            LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印后自动关闭预览窗口

            var strBodyHtml = "<style type='text/css'>table,td,th{border:1px solid black;text-align:center;}</style><body style='font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;'>";
            strBodyHtml = strBodyHtml + "<p style='text-align:center;font-weight:bold;font-size:20px;'>" + this.active[0].danyuan.loudong.xiaoqu.name + "物业服务中心缴费通知单</p>";
            strBodyHtml = strBodyHtml + "<div style='font-size:small'>温馨提示：按时缴纳费用是每位业主的义务也是物业公司维持日常运营的基本保障，如不按时足额缴纳费用，将会对物业管理工作造成严重的影响，同时，也侵犯了已缴纳费用业主的切身利益。请您在百忙之中及时缴纳费用，感谢您对我们工作的支持。</div>";
            strBodyHtml = strBodyHtml + "<div style='float:left;line-height: 1.8;'>房号：" + this.active[0].danyuan.loudong.name + "#" + this.active[0].danyuan.name + "#" + this.active[0].name + "</div>";
            strBodyHtml = strBodyHtml + "<div style='float:right;line-height: 1.8;'>业主姓名：" + this.active[0].yezhu + "</div>";
            strBodyHtml = strBodyHtml + "<table style='width:100%;border-collapse:collapse;line-height: 1.8;'><thead><tr><th>项目</th><th>单价</th><th>起数</th><th>止数</th><th>周期/倍数</th><th>面积/数量</th><th>总计</th></tr></thead>";
            strBodyHtml = strBodyHtml + "<tbody>";
            this.selected_feiyong.forEach(item => {
                console.log(item)
                strBodyHtml = strBodyHtml + "<tr>";
                strBodyHtml = strBodyHtml + "<td>" + item.mingcheng + "</td>";
                strBodyHtml = strBodyHtml + "<td>" + item.danjia + "</td>";
                strBodyHtml = strBodyHtml + "<td>" + item.start + "</td>";
                strBodyHtml = strBodyHtml + "<td>" + item.end + "</td>";
                strBodyHtml = strBodyHtml + "<td>" + item.zhouqi_beishu + "</td>";
                strBodyHtml = strBodyHtml + "<td>" + item.shuliang + "</td>";
                strBodyHtml = strBodyHtml + "<td>" + item.zongji + "</td>";
                strBodyHtml = strBodyHtml + "</tr>";
            })
            strBodyHtml = strBodyHtml + "</tbody>";
            strBodyHtml = strBodyHtml + "</table>";
            strBodyHtml = strBodyHtml + "<div style='float:right;line-height: 1.8;'>"+ dayjs().format('YYYY年MM月DD日 HH:mm:ss') +" @ 待缴合计：¥ " + this.total + "</div>";
            strBodyHtml = strBodyHtml + "</body>";
            LODOP.ADD_PRINT_HTM(32, 16, "100%", "100%", strBodyHtml);
            LODOP.PREVIEW();
        },
        // 确认收取--------------------------------------------
        btn_ok() {
            var haveZeroBill = this.selected_feiyong.some(v => v.zongji == 0)
            if (haveZeroBill) {
                this.snackbar = {
                    show: true,
                    text: '不允许收取费用为0的条目!',
                    color: "error",
                }
                return
            }
            var haveLinshiBill = this.selected_feiyong.some(v => v.feixiang.leixing == '临时费')
            var NotHaveLinshiBill = this.selected_feiyong.some(v => v.feixiang.leixing != '临时费')
            if (haveLinshiBill && NotHaveLinshiBill) {
                this.snackbar = {
                    show: true,
                    text: '临时费只能单独收取!',
                    color: "error",
                }
                return
            }
            this.loading_shouqu = true
            // 打开遮罩层
            this.overlay = true
            var shoukuandan_obj = {
                remark: this.remark, //备注
                zongji: this.zongji, //总计费用
                feiyong: this.selected_feiyong, //费用列表
                pay_way: this.radio_pay_way, //收款方式
                youhui: this.youhui, //优惠金额
                total: this.total // 实收金额
            }
            if (this.historyOpen) {
                shoukuandan_obj.date = this.shoukuanDate
            }
            console.log(shoukuandan_obj)
            this.$axios.post('/shoukuandan/create', shoukuandan_obj)
                .then((res) => {
                    if (res.data.shoukuandan_doc) {
                        this.youhui = ''
                        this.remark = ''
                        this.selected_feiyong = []

                        // 获取费用列表
                        this.get_feiyong_lists(this.active[0])
                        this.shoukuandan_id = res.data.shoukuandan_doc._id
                        // 获取历史记录
                        this.get_history_items(this.active[0])

                        setTimeout(() => {
                            // 关闭遮罩层
                            this.overlay = false
                            this.loading_shouqu = false
                            // 展示收款单
                            this.shoukuandan_dialog = true
                        }, 1000);
                    } else {
                        this.overlay = false
                        this.loading_shouqu = false
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "error",
                        }
                    }
                })
        },

        // 日期增加月份
        addMonthDate(date, month) {
            // 加费项周期月份
            date.setMonth(date.getMonth() + month)
            // 减去一天
            date.setDate(date.getDate() - 1)
            return date
        },

        // 格式化日期为YYYY-MM-DD
        formatDate(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? '0' + m : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        }
    }
}
</script>
<style>
.logs {
    height: 100%;
    overflow: auto;
    width: 40%;
}

/* logs记录滚动条隐藏 */
.logs::-webkit-scrollbar {
    display: none;
}

/* 费用table滚动条隐藏 */
.v-data-table__wrapper::-webkit-scrollbar {
    display: none;
}

/* 收费工具条滚动条隐藏 */
.getTool::-webkit-scrollbar {
    display: none;
}
</style>