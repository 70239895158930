import shoplistView from '../views/shop/listView.vue'
import shopproductView from '../views/shop/productView.vue'
import shoporderView from '../views/shop/orderView.vue'
import shopcategoryView from '../views/shop/categoryView.vue'
import layoutView from '../layout/default.vue'


const shop_routes = {
  path: '/shop',
  component: layoutView,
  children: [
    {
      path: '/shop/list',
      name: 'shoplist',
      component: shoplistView
    },
    {
      path: '/shop/product',
      name: 'shopproduct',
      component: shopproductView
    },
    {
      path: '/shop/order',
      name: 'shoporder',
      component: shoporderView
    },
    {
      path: '/shop/category',
      name: 'shopcategory',
      component: shopcategoryView
    }
  ]
}

export default shop_routes