<template>
    <v-row>
        <ShopLists @select_shop="select_shop"></ShopLists>

        <!-- 分类列表 -->
        <v-col cols="12" sm="2" md="2" style="height:100%">
            <v-card style="height:100%;overflow:auto">
                <v-card-title>分类列表</v-card-title>
                <v-card-text>
                    <v-list-item-group v-model="category_selected">
                        <v-list-item v-for="(item, i) in category_items" :key="i">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- 商品列表 -->
        <v-col cols="12" sm="7" md="7">
            <v-card style="height:100%;overflow:auto">
                <v-card-title>
                    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{
                        snackbar.text }}
                    </v-snackbar>
                    商品列表
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on"
                                :disabled="!shop || !category_items[category_selected]">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">编辑</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-avatar tile size="100">
                                                <img :src="'https://api.youzhuguanjia.com/' + editedItem.thumbnail">
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="12" sm="9" md="9">
                                            <v-file-input accept="image/*" label="商品图片" :loading="fileLoading"
                                                @change="upload_thumbnail"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="editedItem.title" label="商品名称"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="editedItem.new_price" label="商品售价" type="number"
                                                prefix="￥"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field v-model="editedItem.score" label="兑换积分"
                                                type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea v-model="editedItem.describe" label="商品描述" rows="2"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-title>
                    <v-text-field label="商品名称" v-model="title_select" outlined dense hide-details
                        class="mr-2"></v-text-field>
                    <v-btn color="primary" @click="getProductItems" :disabled="!shop || !category_items[category_selected]">
                        查询
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="productItems" :loading="loading" height="60vh">
                        <!-- logo -->
                        <template v-slot:[`item.thumbnail`]="{ item }">
                            <v-avatar tile>
                                <img :src="'https://api.youzhuguanjia.com/' + item.thumbnail">
                            </v-avatar>
                        </template>
                        <!-- 状态 -->
                        <template v-slot:[`item.state`]="{ item }">
                            <v-switch v-model="item.state" @change="update_state(item)"></v-switch>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="primary" class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>

import ShopLists from "@/components/shop-lists.vue";
export default {
    components: {
        ShopLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: ''
        },
        shop: undefined,
        category_selected: undefined,
        fileLoading: false,
        loading: false,
        dialog: false,
        category_items: [],
        productItems: [],
        title_select: '',
        editedItem: {
            thumbnail: '',
            title: '',
            describe: '',
            new_price: 0,
            score:0,
            state: true,
        },
        defaultItem: {
            thumbnail: '',
            title: '',
            describe: '',
            new_price: 0,
            score:0,
            state: true,
        },
        headers: [
            { text: '图片', value: 'thumbnail' },
            { text: '商品名称', value: 'title', },
            { text: '商品售价', value: 'new_price' },
            { text: '兑换积分', value: 'score' },
            { text: '售出数量', value: 'sale' },
            { text: '上架/下架', value: 'state' },
            { text: '操作', align: 'center', value: 'actions' },
        ],
    }),

    watch: {
        dialog(val) {
            if (!val) {
                this.close()
            }
        },
        category_selected(val) {
            this.productItems = []
            if (val || val == 0) {
                this.getProductItems()
            }
        },
    },
    created() {
        // 获取商家列表
        this.$axios.get('/shop/lists')
            .then((res) => {
                this.shop_items = res.data
            })
    },

    methods: {
        // 子组件事件 选择小区
        select_shop(shop) {
            this.category_selected = undefined
            this.category_items = []
            this.productItems = []
            this.shop = shop
            if (shop) {
                this.get_category_lists()
            }
        },
        // 获取商品列表
        getProductItems() {
            this.loading = true
            var queryObj = {
                category: this.category_items[this.category_selected]._id
            }
            if (this.title_select) {
                queryObj.title = this.title_select
            }
            this.$axios.get('/shop/product/lists', { params: queryObj })
                .then((res) => {
                    this.loading = false
                    this.productItems = res.data
                })
        },

        // 获取分类列表
        get_category_lists() {
            this.$axios.get('/shop/category/lists', { params: { shop: this.shop._id } })
                .then((res) => {
                    this.category_items = res.data
                })
        },

        // 编辑
        editItem(item) {
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        // 关闭
        close() {
            this.dialog = false
            this.editedItem = Object.assign({}, this.defaultItem)
        },

        // 上下架
        update_state(item) {
            this.$axios.post('/shop/product/update', item)
                .then((res) => {
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
        },
        // 上传logo
        upload_thumbnail(e) {
            if (e) {
                this.fileLoading = true
                const fileData = new FormData()
                fileData.append('uploads', e)
                this.$axios.post('/uploads', fileData)
                    .then((res) => {
                        this.editedItem.thumbnail = res.data.path
                        this.fileLoading = false
                    })
            } else {
                this.editedItem.picture = ''
            }
        },

        // 保存
        save() {
            if (this.editedItem._id) {
                // 更新
                this.$axios.post('/shop/product/update', this.editedItem)
                    .then((res) => {
                        this.close()
                        this.getProductItems()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            } else {
                // 添加
                this.editedItem.shop = this.shop
                this.editedItem.category = this.category_items[this.category_selected]
                this.$axios.post('/shop/product/create', this.editedItem)
                    .then((res) => {
                        this.close()
                        this.getProductItems()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            }
        }
    }
}
</script>