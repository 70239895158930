import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VXETable from './plugins/vxetable'

import http from "@/utils/axios"

import router from './router'

import store from './store'

Vue.config.productionTip = false
// 全局引入axios
Vue.prototype.$axios = http

new Vue({
  vuetify,
  VXETable,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
