<template>
    <div class="flex-grow-1">
        <div ref="watermark"></div>
        <vxe-grid ref='xGrid' v-bind="gridOptions">
            <template #toolbar_buttons>
                <span class="mr-2">收款开始日期</span><vxe-input v-model="startDate" placeholder="日期选择" type="date"></vxe-input>
                <span class="ml-2 mr-2">收款结束日期</span><vxe-input v-model="endDate" placeholder="日期选择"
                    type="date"></vxe-input>
                <vxe-button @click="getAll">钻取所有</vxe-button>
                <vxe-button @click="getShoukuandanOfAll">收费风云榜</vxe-button>
            </template>
            <template #feixiang_default="{ row }">
                <vxe-button type="text" status="primary" content="费项统计" :loading="loading"
                    @click="getFeixiangLists(row)"></vxe-button>
            </template>
            <template #baoheyingshouOfYear_header>
                <vxe-button type="text" status="primary" content="钻取" @click="baoheyingshouOfYearEvent"
                    :loading="loading"></vxe-button>
                面积类、住户类年度饱和应收
            </template>
            <template #baoheyishouBetweenStartAndEnd_header>
                <vxe-button type="text" status="primary" content="钻取" @click="baoheyishouBetweenStartAndEndEvent"
                    :loading="loading"></vxe-button>
                面积类、住户类年度收款区间饱和已收
            </template>
            <template #totalOfPayWay_header>
                <vxe-button type="text" status="primary" content="钻取" @click="totalOfPayWayEvent"
                    :loading="loading"></vxe-button>
                面积类、住户类、仪表类、临时类区间实收收款方式
                <div class="red--text">按照收款单统计</div>
            </template>
            <template #zongJiAndYouHuiAndTotal_header>
                <vxe-button type="text" status="primary" content="钻取" @click="zongJiAndYouHuiAndTotalEvent"
                    :loading="loading"></vxe-button>
                面积类、住户类、仪表类、临时类区间应收优惠实收
                <div class="red--text">按照收款单统计</div>
            </template>
            <template #shoukuandanOfCount_default="{ row }">
                <vxe-button type="text" status="primary"
                    :content="row.zongJiAndYouHuiAndTotal ? row.zongJiAndYouHuiAndTotal.count.toString() : '0'"
                    :loading="loading" @click="getShoukuandanLists(row._id)"></vxe-button>
            </template>
            <template #totalOfFeixiang_header>
                <vxe-button type="text" status="primary" content="钻取" @click="totalOfFeixiangEvent"
                    :loading="loading"></vxe-button>
                面积类、住户类、仪表类、临时类、多经区间实收费用类型
            </template>
            <template #businessOfAmount_default="{ row }">
                <vxe-button type="text" status="primary"
                    :content="formatMoney(row.totalOfFeixiang ? row.totalOfFeixiang.多经类 : 0)" :loading="loading"
                    @click="getBusinessLists(row._id)"></vxe-button>
            </template>
            <template #businessOut_header>
                <vxe-button type="text" status="primary" content="钻取" @click="businessOutEvent"
                    :loading="loading"></vxe-button>
                成本支出
            </template>
        </vxe-grid>
        <vxe-modal resize v-model="showShoukuandanOfAllModal" title="收费风云榜" width="1200" height="800">
            <template #default>
                <ComponentEchart :echart_option="echart_option"></ComponentEchart>
            </template>
        </vxe-modal>
        <vxe-modal resize v-model="showFeixiangListsModal" :title="currentXiaoqu.name + '费项统计'" width="1200" height="800">
            <template #default>
                <vxe-grid ref='xGridOfFeixiang' v-bind="gridOptionsOfFeixiang">
                    <template #toolbar_buttons>
                        <vxe-button :loading="loading" type="text" status="primary" @click="getFeixiangAll"><span
                                class="font-weight-bold">钻取</span></vxe-button>
                    </template>
                    <template #youhuiAndZongji_total_footer="{ items, _columnIndex }">
                        <template v-if="items[1] === '常规收入汇总'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ items[_columnIndex] }}</span>
                                </template>
                                <div>四五差值：应收与实收可能存在由于收款计费四舍五入方式导致的相对误差。</div>
                            </v-tooltip>
                            <vxe-button type="text" status="primary" content="收款单" :loading="loading"
                                @click="getShoukuandanLists(currentXiaoqu._id)"></vxe-button>
                        </template>
                        <template v-else-if="items[1] === '多经收入汇总'">
                            {{ items[_columnIndex] }}
                            <vxe-button type="text" status="primary" content="多经单统计" :loading="loading"
                                @click="getBusinessLists(currentXiaoqu._id)"></vxe-button>
                        </template>
                        <template v-else>
                            {{ items[_columnIndex] }}
                        </template>
                    </template>
                </vxe-grid>
            </template>
        </vxe-modal>
        <vxe-modal v-model="showShoukuandanListsModal" title="收款单列表" width="800" height="400">
            <template #default>
                <vxe-table :data="shoukuandanLists" height="auto" :loading="loading">
                    <vxe-column type="seq" width="60"></vxe-column>
                    <vxe-column field="_id" title="ID"></vxe-column>
                    <vxe-column field="user.nickname" title="收款人"></vxe-column>
                    <vxe-column field="pay_way" title="收款方式"></vxe-column>
                    <vxe-column field="zongji" title="应收" :formatter="['formatAmount']"></vxe-column>
                    <vxe-column field="youhui" title="优惠" :formatter="['formatAmount']"></vxe-column>
                    <vxe-column field="total" title="实收" :formatter="['formatAmount']"></vxe-column>
                </vxe-table>
            </template>
        </vxe-modal>
        <vxe-modal v-model="showBusinessListsModal" title="多经列表" width="800" height="400">
            <template #default>
                <vxe-table :data="businessLists" height="auto" :loading="loading">
                    <vxe-column type="seq" width="60"></vxe-column>
                    <vxe-column field="_id" title="名称"></vxe-column>
                    <vxe-column field="amount" title="金额" :formatter="['formatAmount']"></vxe-column>
                </vxe-table>
            </template>
        </vxe-modal>
    </div>
</template>
<script>
import { VXETable } from 'vxe-table'
import XEUtils from 'xe-utils'
import ComponentEchart from "@/components/echart-line.vue";
export default {
    components: {
        ComponentEchart
    },
    data() {
        let self = this
        return {
            loading: false,
            // 日期选择框
            startDate: new Date().toLocaleDateString().replace(/\//g, '-'),
            endDate: new Date().toLocaleDateString().replace(/\//g, '-'),
            showShoukuandanListsModal: false,
            shoukuandanLists: [],
            showBusinessListsModal: false,
            businessLists: [],
            // 风云榜
            showShoukuandanOfAllModal: false,
            echart_option: {},
            gridOptions: {
                size: 'mini',
                height: 'auto',
                showFooter: true,
                exportConfig: { type: 'xlsx' },
                printConfig: {},
                customConfig: {},
                toolbarConfig: {
                    export: true,
                    print: true,
                    custom: true,
                    zoom: true,
                    slots: {
                        buttons: 'toolbar_buttons'
                    }
                },
                columns: [
                    { type: 'checkbox', title: '选择', width: 50 },
                    { type: 'seq', title: '序列', width: 50 },
                    { field: 'company.qiyejiancheng', title: '企业全称', sortable: true, },
                    { field: 'name', title: '小区名称' },
                    { title: '费项统计', align: 'center', slots: { default: 'feixiang_default' } },
                    { field: 'baoheyingshouOfYear', title: '面积类、住户类年度饱和应收', slots: { header: 'baoheyingshouOfYear_header' } },
                    { title: '面积类、住户类年度收款区间饱和已收', children: [], slots: { header: 'baoheyishouBetweenStartAndEnd_header' } },
                    {
                        title: '面积类、住户类、仪表类、临时类区间实收收款方式', children: [], slots: { header: 'totalOfPayWay_header' }
                    },
                    {
                        title: '面积类、住户类、仪表类、临时类区间应收优惠实收', children: [], slots: { header: 'zongJiAndYouHuiAndTotal_header' }
                    },
                    {
                        title: '面积类、住户类、仪表类、临时类、多经区间实收费用类型', children: [], slots: { header: 'totalOfFeixiang_header' }
                    },
                    { field: 'businessOut', title: '成本支出', slots: { header: 'businessOut_header' } },
                ],
                data: [],
                footerMethod: ({ columns, data }) => {
                    return [
                        columns.map((column, columnIndex) => {
                            if (columnIndex === 0) {
                                return '汇总'
                            }
                            if (columnIndex >= 3) {
                                return self.formatMoney(self.sumNum(data, column.field))
                            }
                            return ''
                        })
                    ]
                }
            },
            // 费项目列表统计
            currentXiaoqu: '',
            businessInAmount: 0,
            shoukuandanTotal: 0,
            shoukuandanPayWay: [],
            showFeixiangListsModal: false,
            gridOptionsOfFeixiang: {
                size: 'mini',
                height: '100%',
                showFooter: true,
                exportConfig: { type: 'xlsx' },
                printConfig: {},
                customConfig: {},
                toolbarConfig: {
                    export: true,
                    print: true,
                    custom: true,
                    zoom: true,
                    slots: {
                        buttons: 'toolbar_buttons'
                    }
                },
                columns: [{
                    title: '各费项收款统计', children: [
                        { type: 'seq', title: '序列', width: 50 },
                        { field: 'mingcheng', title: '名称' },
                        { field: 'danjia', title: '单价' },
                        { field: 'baoheyishouBetweenStartAndEnd', title: '收款区间饱和已收', children: [] },
                        { field: 'youhuiAndZongji', title: '收款区间优惠与实收', children: [] },
                    ]
                }
                ],
                data: [],
                footerMethod: ({ columns, data }) => {
                    return [
                        columns.map((column, columnIndex) => {
                            if (columnIndex === 1) {
                                return '常规收入汇总'
                            }
                            if (column.field === 'youhuiAndZongji.total') {
                                return '【应收 ' + self.formatMoney(self.sumNum(data, column.field)) + '】【实收 ' + self.formatMoney(self.shoukuandanTotal) + '】【四五差值 ' + self.formatMoney(self.shoukuandanTotal - self.sumNum(data, column.field)) + '】'
                            }
                            if (columnIndex >= 3) {
                                return self.formatMoney(self.sumNum(data, column.field))
                            }
                            return ''
                        }),
                        columns.map((column, columnIndex) => {
                            if (columnIndex === 1) {
                                return '实收路径说明'
                            }
                            if (column.field === 'youhuiAndZongji.total') {
                                var result = ''
                                self.shoukuandanPayWay.forEach(item => {
                                    result = result + '【' + (item._id === 'wxpay-jsapi' ? '小程序' : item._id) + ' ' + self.formatMoney(item.total) + '】'
                                })
                                return result + '【汇总 ' + self.formatMoney(self.shoukuandanTotal) + '】'
                            }
                            return ''
                        }),
                        columns.map((column, columnIndex) => {
                            if (columnIndex === 1) {
                                return '多经收入汇总'
                            }
                            if (column.field === 'youhuiAndZongji.total') {
                                return self.formatMoney(self.businessInAmount)
                            }
                            return ''
                        })
                    ]
                }
            }
        }
    },
    mounted() {
        this.getXiaoquLists()
        const $watermark = this.$refs.watermark
        const text = '优住管家 - ' + JSON.parse(sessionStorage.getItem('user_doc')).nickname
        $watermark.style = "background:url(" + this.getCanvasUrl(text) + ");height:100%;width:100%;position: absolute;left:0;top:0;z-index: 9999;pointer-events:none;"
    },
    methods: {
        getXiaoquLists() {
            this.loading = true
            this.$axios.get('/xiaoqu/getlist')
                .then((res) => {
                    this.gridOptions.data = res.data
                    this.loading = false
                })
        },
        async baoheyingshouOfYearEvent() {
            this.loading = true
            const $grid = this.$refs.xGrid
            var id = this.gridOptions.columns.findIndex(column => column.title === '面积类、住户类年度饱和应收')
            this.gridOptions.columns[id].formatter = ['formatAmount']
            for (let index = 0; index < $grid.getCheckboxRecords().length; index++) {
                const xiaoqu = $grid.getCheckboxRecords()[index];
                const result = await this.$axios.post('/feixiang/report/baoheyingshouOfYear', { xiaoqu: xiaoqu._id })
                xiaoqu.baoheyingshouOfYear = result.data.length ? result.data[0].yingshouSum : 0
                $grid.loadColumn(this.gridOptions.columns)
                $grid.loadData(this.gridOptions.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        async baoheyishouBetweenStartAndEndEvent() {
            this.loading = true
            const $grid = this.$refs.xGrid
            var id = this.gridOptions.columns.findIndex(column => column.title === '面积类、住户类年度收款区间饱和已收')
            this.gridOptions.columns[id].children.push({ field: 'baoheyishouBetweenStartAndEnd.sumZongji', title: '汇总', formatter: ['formatAmount'] })
            for (let index = 0; index < $grid.getCheckboxRecords().length; index++) {
                const xiaoqu = $grid.getCheckboxRecords()[index];
                xiaoqu.baoheyishouBetweenStartAndEnd = {}
                const result = await this.$axios.post('/shoukuandan/report/baoheyishouBetweenStartAndEnd', { xiaoqu: xiaoqu._id, startDate: this.startDate, endDate: this.endDate })
                var sumZongji = 0
                result.data.forEach(year => {
                    if (this.gridOptions.columns[id].children.findIndex(column => column.title === year._id) === -1) {
                        this.gridOptions.columns[id].children.push({ field: 'baoheyishouBetweenStartAndEnd.' + this.numToChines(year._id), title: year._id, formatter: ['formatAmount'] })
                    }
                    // 年份排序
                    this.gridOptions.columns[id].children.sort((a, b) => {
                        return a.title - b.title
                    })
                    sumZongji += year.zongji
                    xiaoqu.baoheyishouBetweenStartAndEnd[this.numToChines(year._id)] = year.zongji
                })
                xiaoqu.baoheyishouBetweenStartAndEnd.sumZongji = sumZongji
                $grid.loadColumn(this.gridOptions.columns)
                $grid.loadData(this.gridOptions.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        async totalOfPayWayEvent() {
            this.loading = true
            const $grid = this.$refs.xGrid
            var id = this.gridOptions.columns.findIndex(column => column.title === '面积类、住户类、仪表类、临时类区间实收收款方式')
            for (let index = 0; index < $grid.getCheckboxRecords().length; index++) {
                const xiaoqu = $grid.getCheckboxRecords()[index];
                xiaoqu.totalOfPayWay = {}
                xiaoqu.shoukuandanOfNumAndTotal = {}
                // 获取收款单收款方式统计
                const result = await this.$axios.post('/shoukuandan/report/getTotalOfPayWay', { xiaoqu: xiaoqu._id, startDate: this.startDate, endDate: this.endDate })
                result.data.forEach(payWay => {
                    switch (payWay._id) {
                        case 'wxpay-jsapi':
                            payWay._id = '小程序'
                            break;
                        case 'wxpay-app':
                            payWay._id = 'APP'
                            break;
                        default:
                            break;
                    }
                    if (this.gridOptions.columns[id].children.findIndex(column => column.title === payWay._id) === -1) {
                        this.gridOptions.columns[id].children.push({ field: 'totalOfPayWay.' + payWay._id, title: payWay._id, formatter: ['formatAmount'] })
                    }
                    xiaoqu.totalOfPayWay[payWay._id] = payWay.total
                })
                $grid.loadColumn(this.gridOptions.columns)
                $grid.loadData(this.gridOptions.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        async zongJiAndYouHuiAndTotalEvent() {
            this.loading = true
            const $grid = this.$refs.xGrid
            var id = this.gridOptions.columns.findIndex(column => column.title === '面积类、住户类、仪表类、临时类区间应收优惠实收')
            this.gridOptions.columns[id].children.push({ field: 'zongJiAndYouHuiAndTotal.count', title: '条数', align: 'center', slots: { default: 'shoukuandanOfCount_default' } })
            this.gridOptions.columns[id].children.push({ field: 'zongJiAndYouHuiAndTotal.zongji', title: '应收', formatter: ['formatAmount'] })
            this.gridOptions.columns[id].children.push({ field: 'zongJiAndYouHuiAndTotal.youhui', title: '优惠', formatter: ['formatAmount'] })
            this.gridOptions.columns[id].children.push({ field: 'zongJiAndYouHuiAndTotal.total', title: '实收', formatter: ['formatAmount'] })
            for (let index = 0; index < $grid.getCheckboxRecords().length; index++) {
                const xiaoqu = $grid.getCheckboxRecords()[index];
                const result = await this.$axios.post('/shoukuandan/report/getZongJiAndYouHuiAndTotal', { xiaoqu: xiaoqu._id, startDate: this.startDate, endDate: this.endDate })
                xiaoqu.zongJiAndYouHuiAndTotal = result.data[0]
                $grid.loadColumn(this.gridOptions.columns)
                $grid.loadData(this.gridOptions.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        async totalOfFeixiangEvent() {
            this.loading = true
            const $grid = this.$refs.xGrid
            var id = this.gridOptions.columns.findIndex(column => column.title === '面积类、住户类、仪表类、临时类、多经区间实收费用类型')
            for (let index = 0; index < $grid.getCheckboxRecords().length; index++) {
                const xiaoqu = $grid.getCheckboxRecords()[index];
                xiaoqu.totalOfFeixiang = {}
                const result = await this.$axios.post('/shoukuandan/report/getTotalOfFeixiang', { xiaoqu: xiaoqu._id, startDate: this.startDate, endDate: this.endDate })
                result.data.forEach(leixing => {
                    if (this.gridOptions.columns[id].children.findIndex(column => column.title === leixing._id) === -1) {
                        this.gridOptions.columns[id].children.push({ field: 'totalOfFeixiang.' + leixing._id, title: leixing._id, formatter: ['formatAmount'] })
                    }
                    xiaoqu.totalOfFeixiang[leixing._id] = leixing.total
                })
                // 获取多经收入
                const business = await this.$axios.post('/business/report/getAmountOfIn', { xiaoqu: xiaoqu._id, startDate: this.startDate, endDate: this.endDate })
                business.data.forEach(leixing => {
                    if (this.gridOptions.columns[id].children.findIndex(column => column.title === '多经类') === -1) {
                        this.gridOptions.columns[id].children.push({ field: 'totalOfFeixiang.多经类', title: '多经类', formatter: ['formatAmount'], slots: { default: 'businessOfAmount_default' } })
                    }
                    xiaoqu.totalOfFeixiang['多经类'] = leixing.amount
                })

                $grid.loadColumn(this.gridOptions.columns)
                $grid.loadData(this.gridOptions.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        async businessOutEvent() {
            this.loading = true
            const $grid = this.$refs.xGrid
            var id = this.gridOptions.columns.findIndex(column => column.title === '成本支出')
            this.gridOptions.columns[id].formatter = ['formatAmount']
            for (let index = 0; index < $grid.getCheckboxRecords().length; index++) {
                const xiaoqu = $grid.getCheckboxRecords()[index];
                const result = await this.$axios.post('/business/report/getAmountOfOut', { xiaoqu: xiaoqu._id, startDate: this.startDate, endDate: this.endDate })
                xiaoqu.businessOut = result.data.length ? result.data[0].amount : 0

                $grid.loadColumn(this.gridOptions.columns)
                $grid.loadData(this.gridOptions.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        async getShoukuandanLists(xiaoqu_id) {
            this.loading = true
            this.shoukuandanLists = []
            this.showShoukuandanListsModal = true
            var params = {
                xiaoqu: xiaoqu_id, startDate: this.startDate, endDate: this.endDate,
                page: 1, itemsPerPage: -1, status: 0
            }
            const result = await this.$axios.get('/shoukuandan/xiaoqu/lists', { params: params })
            this.shoukuandanLists = result.data.doc
            this.loading = false
        },
        async getBusinessLists(xiaoqu_id) {
            this.loading = true
            this.businessLists = []
            this.showBusinessListsModal = true
            var params = {
                xiaoqu: xiaoqu_id, startDate: this.startDate, endDate: this.endDate, type: 'in', status: 1
            }
            const result = await this.$axios.post('/business/report/getAmountOfInByName', params)
            this.businessLists = result.data
            this.loading = false
        },
        // 获取小区所有指标
        getAll() {
            VXETable.modal.alert({ content: '因数据量较大，暂不支持钻取所有数据！', status: 'info' })
        },
        // 期间各项目收费风云榜
        async getShoukuandanOfAll() {
            this.showShoukuandanOfAllModal = true
            this.echart_option = {
                title: { text: this.startDate + '至' + this.endDate + '期间各项目收费风云榜' },
                yAxis: { type: 'category', data: [], axisLabel: { interval: 0 } },
                xAxis: { type: 'value', position: 'top' },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        label: { show: true, fontSize: 10, position: 'right' },
                        showBackground: true,
                        backgroundStyle: { borderRadius: [0, 100, 100, 0,] },
                        itemStyle: {
                            borderRadius: [0, 100, 100, 0,]
                        }
                    }
                ]
            }
            var params = {
                startDate: this.startDate, endDate: this.endDate,
            }
            const { data: { xiaoqus, shoukuandans } } = await this.$axios.post('/shoukuandan/report/getShoukuandanOfAll', params)
            xiaoqus.forEach(xiaoqu => {
                var index = shoukuandans.findIndex(item => item._id === xiaoqu._id)
                if (index === -1) {
                    xiaoqu.total = 0
                } else {
                    xiaoqu.total = shoukuandans[index].total
                }
            })
            xiaoqus.sort((a, b) => {
                return a.total - b.total
            })
            xiaoqus.forEach(item => {
                this.echart_option.yAxis.data.push(item.name)
                this.echart_option.series[0].data.push(item.total)
                this.echart_option.series[0].itemStyle.color = ({ dataIndex }) => {
                    if (dataIndex < xiaoqus.length / 2) {
                        return {
                            x: 1,
                            y: 0,
                            x2: 0,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#ff0000' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#ff000006' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    } else if (dataIndex >= xiaoqus.length - 3) {
                        return {
                            x: 1,
                            y: 0,
                            x2: 0,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#008000' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#00800006' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    } else {
                        return {
                            x: 1,
                            y: 0,
                            x2: 0,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: '#4290f7' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#4290f706' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    }
                }
            })
        },
        // 汇总
        sumNum(list, field) {
            let count = 0
            list.forEach(item => {
                try {
                    count += Number(typeof eval('item.' + field) != 'number' ? 0 : eval('item.' + field))
                } catch (error) {
                    console.log(error, item);
                }
            })
            return count
        },
        // ---------------------------------------------------------------
        // 获取小区的费项列表
        getFeixiangLists(row) {
            this.currentXiaoqu = row
            this.gridOptionsOfFeixiang.columns = [{
                title: this.startDate + '至' + this.endDate + '期间【' + this.currentXiaoqu.name + '】各费项收款统计', children: [
                    { type: 'seq', title: '序列', width: 50 },
                    { field: 'mingcheng', title: '名称' },
                    { field: 'danjia', title: '单价' },
                    { field: 'baoheyishouBetweenStartAndEnd', title: '收款区间饱和已收', children: [] },
                    { field: 'youhuiAndZongji', title: '收款区间优惠与实收', children: [] },
                ]
            }
            ]
            this.gridOptionsOfFeixiang.data = []
            this.loading = true
            this.showFeixiangListsModal = true
            this.$axios.get('/feixiang/lists', { params: { xiaoqu: this.currentXiaoqu._id } })
                .then((res) => {
                    this.gridOptionsOfFeixiang.data = res.data
                    this.loading = false
                })
        },
        getFeixiangAll() {
            this.getBaoheyishouBetweenStartAndEndOfFeixiang()
            this.getYouhuiAndZongjiOfFeixiang()
            this.getBusinessInAmount()
            this.getShoukuandanTotal()
            this.getShoukuanPayWay()
        },
        // 获取各费项的收款区间饱和已收
        async getBaoheyishouBetweenStartAndEndOfFeixiang() {
            this.loading = true
            const $grid = this.$refs.xGridOfFeixiang
            var id = this.gridOptionsOfFeixiang.columns[0].children.findIndex(column => column.title === '收款区间饱和已收')
            this.gridOptionsOfFeixiang.columns[0].children[id].children.push({ field: 'baoheyishouBetweenStartAndEnd.sumZongji', title: '汇总', formatter: ['formatAmount'] })
            for (let index = 0; index < this.gridOptionsOfFeixiang.data.length; index++) {
                const feixiang = this.gridOptionsOfFeixiang.data[index];
                feixiang.baoheyishouBetweenStartAndEnd = {}
                const result = await this.$axios.post('/shoukuandan/report/baoheyishouBetweenStartAndEndOfFeixiang', { xiaoqu: this.currentXiaoqu._id, feixiang: feixiang._id, startDate: this.startDate, endDate: this.endDate })
                var sumZongji = 0
                result.data.forEach(year => {
                    if (this.gridOptionsOfFeixiang.columns[0].children[id].children.findIndex(column => column.title === year._id) === -1) {
                        this.gridOptionsOfFeixiang.columns[0].children[id].children.push({ field: 'baoheyishouBetweenStartAndEnd.' + this.numToChines(year._id), title: year._id, formatter: ['formatAmount'] })
                    }
                    // 年份排序
                    this.gridOptionsOfFeixiang.columns[0].children[id].children.sort((a, b) => {
                        return a.title - b.title
                    })
                    sumZongji += year.zongji
                    feixiang.baoheyishouBetweenStartAndEnd[this.numToChines(year._id)] = year.zongji
                })
                feixiang.baoheyishouBetweenStartAndEnd.sumZongji = sumZongji
                $grid.loadColumn(this.gridOptionsOfFeixiang.columns)
                $grid.loadData(this.gridOptionsOfFeixiang.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        // 获取各费项的优惠及实收
        async getYouhuiAndZongjiOfFeixiang() {
            this.loading = true
            const $grid = this.$refs.xGridOfFeixiang
            var id = this.gridOptionsOfFeixiang.columns[0].children.findIndex(column => column.title === '收款区间优惠与实收')
            this.gridOptionsOfFeixiang.columns[0].children[id].children.push({ field: 'youhuiAndZongji.zongji', title: '应收', formatter: ['formatAmount'] })
            this.gridOptionsOfFeixiang.columns[0].children[id].children.push({ field: 'youhuiAndZongji.youhui', title: '优惠', formatter: ['formatAmount'] })
            this.gridOptionsOfFeixiang.columns[0].children[id].children.push({ field: 'youhuiAndZongji.total', title: '实收', width: '200', formatter: ['formatAmount'], slots: { footer: 'youhuiAndZongji_total_footer' } })
            for (let index = 0; index < this.gridOptionsOfFeixiang.data.length; index++) {
                const feixiang = this.gridOptionsOfFeixiang.data[index];
                const result = await this.$axios.post('/shoukuandan/report/getYouhuiAndZongjiOfFeixiang', { xiaoqu: this.currentXiaoqu._id, feixiang: feixiang._id, startDate: this.startDate, endDate: this.endDate })
                feixiang.youhuiAndZongji = result.data[0]
                $grid.loadColumn(this.gridOptionsOfFeixiang.columns)
                $grid.loadData(this.gridOptionsOfFeixiang.data)
                $grid.updateFooter()
            }
            this.loading = false
        },
        // 获取多经收入
        async getBusinessInAmount() {
            this.businessInAmount = 0
            const result = await this.$axios.post('/business/report/getAmountOfIn', { xiaoqu: this.currentXiaoqu._id, startDate: this.startDate, endDate: this.endDate })
            this.businessInAmount = result.data[0] ? result.data[0].amount : 0
        },
        // 获取收款单汇总金额
        async getShoukuandanTotal() {
            this.shoukuandanTotal = 0
            const result = await this.$axios.post('/shoukuandan/report/getShoukuandanTotal', { xiaoqu: this.currentXiaoqu._id, startDate: this.startDate, endDate: this.endDate })
            this.shoukuandanTotal = result.data[0] ? result.data[0].total : 0
        },
        // 获取收费方式
        async getShoukuanPayWay() {
            this.shoukuandanPayWay = []
            const result = await this.$axios.post('/shoukuandan/report/getTotalOfPayWay', { xiaoqu: this.currentXiaoqu._id, startDate: this.startDate, endDate: this.endDate })
            this.shoukuandanPayWay = result.data
        },
        // ------------------------------------------------------------------
        // 添加水印
        getCanvasUrl(text) {
            const angle = -30
            const txt = text
            var canvas = document.createElement('canvas')
            canvas.width = 400
            canvas.height = 200
            var ctx = canvas.getContext('2d')
            ctx.fillStyle = '#ff000020'
            ctx.rotate((Math.PI / 180) * angle)
            ctx.fillText(txt, 0, 200)
            ctx.fillText("隐私数据", 0, 160);
            return canvas.toDataURL()
        },
        // 数字转换为中文
        numToChines(num) {
            let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
            var result = ''
            num.toString().split("").forEach(singleNum => {
                result = result + changeNum[singleNum]
            })
            return result
        },
        // 四舍五入金额，每隔3位逗号分隔，默认2位数
        formatMoney(value, digits = 2) {
            return XEUtils.commafy(XEUtils.toNumber(value), { digits })
        },
    },
};
</script>
