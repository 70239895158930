import getView from '../views/bill/getView.vue'
import projectView from '../views/bill/projectView.vue'
import statisticView from '../views/bill/statisticView.vue'
import receiptView from '../views/bill/receiptView.vue'
import businessView from '../views/bill/businessView.vue'
import pushView from '../views/bill/pushView.vue'
import recordView from '../views/bill/recordView.vue'
import reportView from '../views/bill/reportView.vue'
import initView from '../views/bill/initView.vue'

import layoutView from '../layout/default.vue'

const bill_routes = {
  path: '/bill',
  component: layoutView,
  children: [
    {
      path: '/bill/get',
      name: 'get',
      component: getView
    },
    {
      path: '/bill/project',
      name: 'project',
      component: projectView
    },
    {
      path: '/bill/statistic',
      name: 'statistic',
      component: statisticView
    },
    {
      path: '/bill/receipt',
      name: 'receipt',
      component: receiptView
    },
    {
      path: '/bill/business/:type',
      name: 'business',
      component: businessView
    },
    {
      path: '/bill/push',
      name: 'push',
      component: pushView
    },
    {
      path: '/bill/record',
      name: 'record',
      component: recordView
    },
    {
      path: '/bill/report',
      name: 'report',
      component: reportView
    },
    {
      path: '/bill/init',
      name: 'init',
      component: initView
    },
  ]
}

export default bill_routes