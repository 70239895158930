<template>
  <v-row>
    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
    </v-snackbar>
    <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
    <v-col cols="12" sm="9" md="9" style="height:100%">
      <v-card height="100%" style="overflow: auto;" class="d-flex flex-column">
        <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 导入导出 【<span class="green--text">房屋数量：{{ fangwu_count }}</span>】
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="disabled" :loading="loading" @click="export_file">导出房屋基础数据</v-btn>
        </v-card-title>
        <v-card-text style="flex:1;overflow: auto;">
          <div class="d-flex">
            <v-file-input v-model="file_obj" :disabled="disabled" :loading="loading" placeholder="选择即将导入的房屋详细信息数据">
            </v-file-input>
            <v-btn color="primary" :disabled="disabled" :loading="loading" @click="import_file">导入更新房屋详细信息</v-btn>
          </div>
          <div>
            <div class="red--text">注意：<br>
              手动添加小区、楼栋、单元、房屋等信息后，导出所有房屋基础信息再进行导入修改。<br>
              导出的房屋基础信息只可以修改'房屋', '面积', '业主', '性别(男:1,女:2,未知:0)', '电话'。<br>
              所有字段不能为空（为空的数据将不更新）。<br>
            </div>
            <div class="green--text text-h3">{{ info }}</div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
  components: {
    XiaoquLists
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: "success",
      site_error: "",
    },
    xiaoqu: null,
    fangwu_count: 0,
    disabled: true,
    loading: false,
    file_obj: null,
    info: ''
  }),
  methods: {
    // 子组件事件 选择小区
    select_xiaoqu(xiaoqu) {
      this.xiaoqu = xiaoqu
      this.fangwu_count = 0
      this.file_obj = null
      this.info = ''
      if (xiaoqu) {
        this.disabled = false
        this.get_fangwu_count()
      } else {
        this.disabled = true
      }
    },

    // 获取小区数量
    get_fangwu_count() {
      this.$axios.post('/xiaoqu/count', this.xiaoqu)
        .then((res) => {
          this.fangwu_count = res.data.fangwu_count
        })
    },

    // 导出
    export_file() {
      this.loading = true
      this.$axios.post('/xiaoqu/export', this.xiaoqu, { responseType: 'blob' })
        .then((res) => {
          const url = URL.createObjectURL(res.data)
          let a = document.createElement('a')
          a.href = url
          a.download = this.xiaoqu.name + '_房产数据.xlsx'
          a.click()
          setTimeout(() => {
            this.loading = false
          }, 3000);

        })
    },

    // 导入
    import_file() {
      // 如果没有导入文件
      if (!this.file_obj) {
        this.snackbar = {
          show: true,
          text: '文件还没有上传',
          color: "error",
        };
      } else {
        // this.loading = true
        const formData = new FormData()
        formData.append('file_obj', this.file_obj)
        this.$axios.post('/xiaoqu/import', formData)
          .then(res => {
            console.log(res.data)
            // this.loading = false
            this.snackbar = {
              show: true,
              text: '已导入并更新' + (res.data.length-1) + '条数据。',
              color: "success",
            };
            this.info = '已导入并更新' + (res.data.length-1) + '条数据。'
          })
      }
    }
  }
}
</script>
