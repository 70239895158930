<template>
  <v-main>
    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}</v-snackbar>
    <v-container style="height: 100vh;" class="d-flex align-center">
      <v-row class="justify-center">
        <v-col cols="12" md="8" lg="6">
          <v-card elevation="24" class="rounded-lg overflow-auto" style="min-height:48vh">
            <v-container>
              <v-row>

                <!-- 左侧 -->
                <v-col class="primary d-flex flex-column justify-center align-center" style="min-height:48vh;" cols="12"
                  sm="6">
                  <v-img src="@/assets/logo.png" max-height="100" max-width="100" class="rounded-lg">
                  </v-img>
                  <div class="text-h6 white--text">下一代物业管理系统</div>
                  <div class="text-caption white--text">── 让物业管理更规范、更安全 ──</div>
                </v-col>

                <!-- 右侧 -->
                <v-col class="d-flex flex-column justify-center" style="min-height:48vh">
                  <v-card elevation="0">

                    <!-- 登录 -->
                    <template v-if="logining">
                      <v-card-title class="flex-column justify-center">
                        <h2 class="primary--text">登 录</h2>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field autofocus clearable v-model="form_login.username" outlined
                          prepend-inner-icon="mdi-account" label="账号">
                        </v-text-field>
                        <v-text-field hide-details @click:append="password_show = !password_show"
                          :type="password_show ? 'text' : 'password'"
                          :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'" v-model="form_login.password" outlined
                          prepend-inner-icon="mdi-key" label="密码">
                        </v-text-field>
                      </v-card-text>
                    </template>

                    <!-- 注册 -->
                    <template v-else>
                      <v-card-text>
                        <p class="red--text"><span
                            class="font-weight-bold">通知：</span>由于近期注册用户较多，且部分用户存在恶意注册行为，目前暂时关闭账号自助注册功能，您可以微信扫码联系平台客服，由客服人员为您免费注册账号，同时指导您使用本系统！
                        </p>
                        <div class="text-center"><img style="width:100%" src="@/assets/kefu.jpg"></div>
                      </v-card-text>
                    </template>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <template v-if="logining">
                        <v-btn color="primary" @click="login">
                          登 录
                        </v-btn>
                        <v-btn color="primary" @click="regist" text>
                          注 册
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn color="primary" @click="goback">
                          返回登录
                        </v-btn>
                      </template>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <div style="position: fixed;bottom: 0;left: 0;right: 0;text-align: center;">
        <p class="text-caption">客服电话：<span
            class="primary--text">18869808142</span>&nbsp;&nbsp;&nbsp;&nbsp;客服微信：<span
            class="primary--text">Ethan-You-China</span>&nbsp;&nbsp;&nbsp;&nbsp;客服电子邮件：<span
            class="primary--text">348740861@qq.com</span></p>
        <p class="text-caption">Copyright © Yunnan Youzhu Network Technology Co., Ltd. All rights reserved
          <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2023003418号-1</a>
          云南优住网络科技有限公司版权所有</p>
      </div>
    </v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
export default {
  name: "LoginView",
  data: () => {
    return {
      password_show: false,
      snackbar: {
        show: false,
        text: "",
        color: "success"
      },
      // 登录窗口
      logining: true,
      loading: false,
      form_login: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    // 登录
    login() {
      if (!this.form_login.username || !this.form_login.password) {
        this.snackbar = {
          show: true,
          text: "用户名和密码不能为空！",
          color: "error"
        }
      } else {
        this.loading = true
        this.$axios.post('/user/login', this.form_login)
          .then(res => {
            console.log(res.data)
            if (res.data.token) {
              sessionStorage.setItem('token', res.data.token)
              sessionStorage.setItem('user_doc', JSON.stringify(res.data.user_doc))
              setTimeout(() => {
                this.loading = false
                this.$router.push('/home')
              }, 500);
            } else {
              setTimeout(() => {
                this.loading = false
                this.snackbar = {
                  show: true,
                  text: res.data.message,
                  color: res.data.errors ? "error" : "success",
                }
              }, 500);
            }
          })
          .catch(error => {
            this.snackbar = {
              show: true,
              text: error,
              color: "error"
            }
            this.loading = false
          })
      }
    },
    // 注册
    regist() {
      this.logining = false
    },
    // 提交注册
    goback() {
      this.logining = true
    }
  }
};
</script>
<style scoped>
.v-main {
  background: url(@/assets/login_bg.jpg);
}
</style>