<template>
  <v-row>
    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
    </v-snackbar>
    <XiaoquLists @select_xiaoqu="select_xiaoqu" v-bind:is-all="true"></XiaoquLists>
    <v-col cols="12" sm="9" md="9" style="height:100%">
      <v-card height="100%" style="overflow: auto;">
        <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 授权账号 {{ select_user.length }} 个
          <v-spacer></v-spacer>
          <v-btn color="primary" small :disabled="xiaoqu ? false : true" @click="btn_save">保存
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table show-select v-model="select_user" checkbox-color="primary" :headers="headers" :items="user_items"
            item-key="_id" :loading="loading">
            <template v-slot:[`item.status`]="{ item }">
              <v-switch disabled v-model="item.status"></v-switch>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
// 权限管理需要用户能获取所有的小区列表，所以传了一个isall参数过去
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
  components: {
    XiaoquLists
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: "success",
      site_error: "",
    },
    loading: false,
    xiaoqu: null,
    select_user: [],
    // 用户列表
    user_items: [],
    headers: [
      { text: "账号", sortable: false, value: "username" },
      { text: "姓名", sortable: false, value: "nickname" },
      { text: "企业", sortable: false, value: "company.qiyejiancheng" },
      { text: "权限", sortable: false, value: "permission.name" },
      { text: "状态", sortable: false, value: "status" },
    ],
  }),
  methods: {
    // 子组件事件 选择小区
    select_xiaoqu(xiaoqu) {
      this.loading = true
      this.xiaoqu = xiaoqu
      if (xiaoqu) {
        this.get_auth_user_list(xiaoqu)
      } else {
        this.loading = false
        this.user_items = []
        this.select_user = []
      }
    },
    // 获取授权的用户列表
    get_auth_user_list(xiaoqu) {
      this.$axios
        .get("/xiaoqu/userlists", { params: { xiaoqu: xiaoqu._id } })
        .then((res) => {
          this.loading = false
          this.select_user = res.data.users;
          this.get_all_user_list(xiaoqu)
        });
    },
    // 通过传物业id获取物业的所有用户列表
    get_all_user_list(xiaoqu) {
      this.loading = true
      this.$axios.get('/user/lists', { params: { company: xiaoqu.company._id } })
        .then((res) => {
          this.loading = false
          // 可能有别的公司的用户数据，这里合并后去重
          this.user_items = [...res.data,...this.select_user].filter((item,index,self)=>{
            return self.findIndex(el=>el._id==item._id)===index
          })
        })
    },
    // 保存到数据库---------------------------------------------------------------
    btn_save() {
      this.xiaoqu.users = this.select_user;
      this.$axios.post("/xiaoqu/update", this.xiaoqu).then((res) => {
        console.log(res.data)
        this.snackbar = {
          show: true,
          text: '保存成功',
          color: "success",
        };
      });
    },
  },
};
</script>
