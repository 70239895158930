//==获取LODOP对象主过程,判断是否安装、需否升级:==
function getLodop() {
    var strFontTag = "<font color='#FF00FF'>打印控件";
    var strCLodopInstallA = "<font color='#FF00FF'>Web打印服务CLodop未安装启动，点击这里<a href='/download/lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>下载执行安装</a>";
    var strCLodopInstallB = "<br>（若此前已安装过，可<a href='CLodop.protocol:setup' target='_self'>点这里直接再次启动</a>）";
    var strCLodopUpdate = "<font color='#FF00FF'>Web打印服务CLodop需升级!点击这里<a href='/download/lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>执行升级</a>";

    var strInstallOK = "，成功后请重启浏览器。</font>";
    var LODOP;
    try {
        if (window.getCLodop) {
            LODOP = window.getCLodop();
            if (LODOP.CVERSION < "6.5.6.9") {
                return { error: 1, msg: strFontTag + strCLodopUpdate + strInstallOK };
            } else {
                return LODOP;
            }
        } else {
            return { error: 1, msg: strFontTag + strCLodopInstallA + strCLodopInstallB + strInstallOK };
        }

    } catch (err) {
        alert("getLodop出错:" + err);
    }
}
export { getLodop }; //导出getLodop

