import weixinUserView from '../views/weixin/userView.vue'
import weixinOrderView from '../views/weixin/orderView.vue'
import weixinCheckView from '../views/weixin/checkView.vue'
import layoutView from '../layout/default.vue'

const weixin_routes = {
  path: '/weixin',
  component: layoutView,
  children: [
    {
      path: '/weixin/users',
      name: 'weixinuser',
      component: weixinUserView
    },{
      path: '/weixin/orders',
      name: 'weixinorder',
      component: weixinOrderView
    },{
      path: '/weixin/check',
      name: 'weixincheck',
      component: weixinCheckView
    }
  ]
}

export default weixin_routes