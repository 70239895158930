<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;" class="d-flex flex-column">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 费项设置
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on" :disabled="xiaoqu ? false : true">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-form ref="form">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-select :items="leixing_items" item-text="name" item-value="name"
                                                    v-model="editedItem.leixing" label="类型"
                                                    :rules="[value => !!value || '必填']">
                                                    <template v-slot:item="{ item }">
                                                        <v-tooltip right max-width="300px">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                                            </template>
                                                            <div class="pa-2">{{ item.info }}</div>
                                                        </v-tooltip>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.mingcheng" label="名称"
                                                    :rules="[value => !!value || '必填']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.danjia" type="number" suffix="元"
                                                    label="单价" :rules="[value => !!value || '必填']"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.zhouqi" type="number" suffix="月"
                                                    :disabled="disabled_zhouqi" label="周期"
                                                    :rules="[value => !!value || '必填', value => !/[\.]/.test(value) || '整数']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.discount" type="number" label="线上折扣"
                                                    :rules="[value => !!value || '必填']"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-checkbox v-model="editedItem.score"
                                                    :label="editedItem.score?'开启积分（业主线上支付后获得同等积分）':'关闭积分'"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text style="flex:1;overflow: auto;">
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading" hide-default-footer
                        disable-pagination style="height:100%;overflow: auto;">
                        <template v-slot:[`item.leixing`]="{ item }">
                            <v-chip :color="getColor(item.leixing)" dark>
                                {{ item.leixing }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.zhouqi`]="{ item }">
                            <div>{{ (item.leixing == '临时费' || item.leixing == '按读表') ? '/' : item.zhouqi }}</div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-switch @change="change_status(item)" v-model="item.status"></v-switch>
                        </template>
                        <template v-slot:[`item.score`]="{ item }">
                            <v-switch disabled v-model="item.score"></v-switch>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="primary" small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: "",
        },
        dialog: false,
        xiaoqu: null,
        loading: false,
        disabled_zhouqi: false,

        editedIndex: -1,
        editedItem: {
            leixing: '',
            mingcheng: '',
            danjia: 0,
            zhouqi: 0,
            discount: 1,
            score:false
        },
        defaultItem: {
            leixing: '',
            mingcheng: '',
            danjia: 0,
            zhouqi: 0,
            discount: 1,
            score:false
        },
        // 计费类型
        leixing_items: [
            { name: '按面积', info: '根据房屋面积进行计费，收取费用后自动生成下个周期的账单，上次缴费日期的次日为下次缴费日期的起日，比如物业费' },
            { name: '按户数', info: '根据户数进行计费，收取费用后自动生成下个周期的账单，上次缴费日期的次日为下次缴费日期的起日，比如环卫费' },
            { name: '按读表', info: '根据读表数量进行计费，收取费用后最新的读数自动更新，比如水费、电费' },
            { name: '取暖费', info: '根据房屋面积进行计费，收取费用过后自动生成下个周期的账单，起日、止日按年轮询' },
            { name: '临时费', info: '收取费用后不自动生成新账单' },
        ],
        // 费项列表
        items: [],
        headers: [
            { text: "类型", sortable: true, value: "leixing" },
            { text: "名称", sortable: false, value: "mingcheng" },
            { text: "状态", sortable: false, value: "status" },
            { text: "单价(元)", sortable: false, value: "danjia" },
            { text: "周期(月)", sortable: false, value: "zhouqi" },
            { text: "线上折扣", sortable: false, value: "discount" },
            { text: "积分状态", sortable: false, value: "score" },
            { text: "操作", sortable: false, value: "actions" },
        ],
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '添加' : '编辑'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        editedItem: {
            handler: function (val) {

                if (val.leixing == '临时费' || val.leixing == '按读表') {
                    val.zhouqi = '0'
                    this.disabled_zhouqi = true
                } else {
                    this.disabled_zhouqi = false
                }
            },
            deep: true
        }
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.getlist()
            } else {
                this.items = []
            }
        },

        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        // 删除费用
        deleteItem(item) {
            this.$axios.post('/feixiang/del', item)
                .then((res) => {
                    if (!res.data.error) {
                        this.getlist()
                    }
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.error ? "error" : "success",
                    }
                })
        },
        close() {
            this.dialog = false
            this.$refs.form.resetValidation()
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        // 获取费项列表
        getlist() {
            this.loading = true
            this.items = []
            this.$axios.get('/feixiang/lists', { params: { xiaoqu: this.xiaoqu._id } })
                .then((res) => {
                    this.loading = false
                    this.items = res.data
                })
        },

        // 更新状态
        change_status(item) {
            this.$axios.post('/feixiang/update', item)
                .then((res) => {
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.snackbar = {
                        show: true,
                        text: error,
                        color: "error"
                    }
                })
        },
        // 保存到数据库---------------------------------------------------------------
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex > -1) {
                    // 更新
                    this.$axios.post('/feixiang/update', this.editedItem)
                        .then((res) => {
                            console.log(res)
                            this.close()
                            this.getlist()
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: res.data.errors ? "error" : "success",
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.snackbar = {
                                show: true,
                                text: error,
                                color: "error"
                            }
                        })
                } else {
                    // 添加
                    this.editedItem.xiaoqu = this.xiaoqu
                    console.log(this.editedItem)
                    this.$axios.post('/feixiang/create', this.editedItem)
                        .then((res) => {
                            console.log(res)
                            this.close()
                            this.getlist()
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: res.data.errors ? "error" : "success",
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.snackbar = {
                                show: true,
                                text: error,
                                color: "error"
                            }
                        })
                }
            }
        },
        getColor(leixing) {
            if (leixing == '按面积') return 'primary'
            else if (leixing == '按户数') return 'orange'
            else if (leixing == '按读表') return 'green'
            else if (leixing == '取暖费') return 'red'
            else return 'grey'
        },
    },
};
</script>
