<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 投票列表
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on" :disabled="xiaoqu ? false : true">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">编辑</span>
                            </v-card-title>

                            <v-card-text>

                                <v-text-field v-model="voteItem.title" label="标题"></v-text-field>
                                <v-textarea v-model="voteItem.content" rows="5" label="内容">
                                </v-textarea>
                                <!-- 开始结束日期 -->
                                <v-dialog v-model="dates_dialog" width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field readonly v-model="dateRangeText" label="有效时间范围"
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="voteItem.dates" range full-width @input="dates_dialog = false"
                                        locale="zh-cn"></v-date-picker>
                                </v-dialog>

                                <!-- 选项 -->
                                <v-text-field v-for="(item, index) in voteItem.options" :key="index" v-model="item.label"
                                    :label="'选项' + index"></v-text-field>
                                <!-- 新增选项 -->
                                <v-btn @click="add_vote_options">
                                    新增选项
                                </v-btn>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="dialog = false" :loading="loading">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save" :loading="loading">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="voteItems" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.options`]="{ item }">
                            <div>{{ item.options }}</div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="primary" class="mr-2" small @click="editItem(item)">编辑</v-btn>
                            <v-btn color="error" small @click="deleteItem(item)">删除</v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        total_num: 0,
        // 每项数据
        voteItem: {
            date: new Date(),
            xiaoqu: '',
            title: '',
            content: '',
            dates: [],
            options: [{
                label: '同意',
                score: 0
            }, {
                label: '不同意',
                score: 0
            }]
        },
        xiaoqu: null,
        dates_dialog: false,
        options: {},
        dialog: false,
        loading: false,
        table_loading: false,

        // 费项列表
        voteItems: [],
        headers: [
            { text: "时间", width: 100, sortable: false, value: "date" },
            { text: "标题", width: 200, sortable: false, value: "title" },
            { text: "结果", width: 300,sortable: false, value: "options" },
            { text: "操作", width: 100, sortable: false, value: "actions", align: 'center' },
        ],
    }),
    watch: {
        dialog(val) {
            val || this.close()
        },
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_vote_list(this.xiaoqu._id)
                }
            },
            deep: true,
        },
    },
    computed: {
        dateRangeText() {
            return this.voteItem.dates.join(' ~ ')
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.voteItem.xiaoqu = xiaoqu
                this.get_vote_list(xiaoqu._id)
            } else {
                this.voteItems = []
            }
        },

        add_vote_options() {
            this.voteItem.options.push({
                label: '',
                score: 0
            })
        },
        // 获取公告列表
        get_vote_list(xiaoqu) {
            this.table_loading = true
            this.$axios.get("/vote/lists", { params: { xiaoqu: xiaoqu, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    setTimeout(() => {
                        this.table_loading = false
                        this.voteItems = res.data.vote_doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        },

        close() {
            this.voteItem = {
                date: new Date(),
                xiaoqu: this.xiaoqu,
                title: '',
                content: '',
                dates: [],
                options: [{
                    label: '同意',
                    score: 0
                }, {
                    label: '不同意',
                    score: 0
                }]
            }
        },
        // 保存到数据库---------------------------------------------------------------
        save() {
            if (this.voteItem.title == '' || this.voteItem.content == '' || !this.voteItem.options.length || !this.voteItem.dates.length) {
                this.snackbar = {
                    show: true,
                    text: "不允许为空",
                    color: "error",
                }
                return
            }
            this.loading = true
            if (this.voteItem._id) {
                this.$axios.post("/vote/update", this.voteItem)
                    .then(() => {
                        this.dialog = false
                        this.loading = false
                        this.get_vote_list(this.xiaoqu._id)
                    })
            } else {
                this.$axios.post("/vote/create", this.voteItem)
                    .then(() => {
                        this.dialog = false
                        this.loading = false
                        this.get_vote_list(this.xiaoqu._id)
                    })
            }

        },

        // 编辑投票
        editItem(item) {
            this.voteItem = item
            this.dialog = true
        },
        // 删除公告
        deleteItem(item) {
            this.$axios.post("/vote/del", item)
                .then(() => {
                    this.get_vote_list(this.xiaoqu._id)
                })
        }
    },
};
</script>
