<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%" class="d-flex">
            <v-card height="100%" style="flex:1;overflow: auto;">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 报事报修列表
                    <v-spacer></v-spacer>
                    <v-dialog v-model="reportDialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on" :disabled="xiaoqu ? false : true">
                                新建工单
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">新建工单</span>
                            </v-card-title>
                            <v-card-text>
                                <v-textarea v-model="content" label="内容"></v-textarea>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="reportDialog = false">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="saveReport">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">回复</span>
                            </v-card-title>

                            <v-card-text>
                                <v-textarea v-model="reply_content" label="内容"
                                    value="The Woodman set to work at once, aned nearly through.">
                                </v-textarea>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="close" :loading="loading">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save" :loading="loading">
                                    回复
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-data-table :headers="headers" :items="report_items" item-key="_id" :loading="list_loading"
                        show-expand single-expand :options.sync="options" :server-items-length="total_num" height="60vh">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.fangchan`]="{ item }">
                            <span v-if="item.create_wx_user">
                                {{ item.loudong.name + '#' + item.danyuan.name + '#' + item.fangwu.name + '#' +
                                    item.fangwu.yezhu }}
                            </span>
                            <span v-if="item.create_sys_user">
                                内部工单
                            </span>
                        </template>
                        <template v-slot:[`item.create_user`]="{ item }">
                            <span v-if="item.create_wx_user">
                                {{ item.create_wx_user.nickname + item.create_wx_user.phone }}
                            </span>
                            <span v-if="item.create_sys_user">
                                {{ item.create_sys_user.nickname }}
                            </span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            {{ statusStr(item.status) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn v-if="item.status == 0" color="primary" small @click="acceptItem(item)"
                                :loading="loading">受理</v-btn>
                            <v-btn v-if="item.status == 1" color="success" small @click="replyItem(item)">处理归档</v-btn>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div class="pa-4">
                                    <p>【报事报修内容】{{ item.content }}</p>
                                    <p v-if="item.video"><video controls width="600" height="300" :src="'https://api.youzhuguanjia.com/' + item.video"></video></p>
                                    <p v-if="item.status >= 1">【{{ item.accept_user.nickname }}】受理</p>
                                    <p v-if="item.status >= 2">【{{ item.reply_user.nickname }}】处理归档：{{ item.reply_content }}
                                    </p>
                                    <p v-if="item.status == 3">【评分】
                                        <v-rating readonly length="10" :value="item.score" size="20"
                                            color="warning"></v-rating>
                                    </p>
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        user_doc: JSON.parse(sessionStorage.getItem('user_doc')),
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        // 报事报修新增/编辑弹出框
        reportDialog: false,
        content: '',

        // 回复弹出框
        reply_item: {},
        reply_content: '',
        dialog: false,
        loading: false,
        list_loading: false,
        total_num: 0,
        options: {},
        xiaoqu: null,
        // 费项列表
        report_items: [],
        headers: [
            { value: "data-table-expand" },
            { text: "时间", sortable: true, width: 200, value: "date" },
            { text: "房产", sortable: true, width: 300, value: "fangchan" },
            { text: "上报人", sortable: true, width: 200, value: "create_user" },
            { text: "内容", sortable: false, value: "content" },
            { text: "状态", sortable: false, width: 100, value: "status" },
            { text: "操作", sortable: false, width: 100, value: "actions" },
        ],
    }),
    computed: {

    },
    watch: {
        reportDialog(val) {
            val || this.reportDialogClose()
        },
        dialog(val) {
            val || this.close()
        },
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_report_list()
                }
            },
            deep: true,
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.get_report_list()
            } else {
                this.report_items = []
            }
        },

        // 获取列表
        get_report_list() {
            this.report_items = []
            this.list_loading = true
            this.$axios.post("/report", { sql_query: { xiaoqu: this.xiaoqu._id }, page: this.options.page, itemsPerPage: this.options.itemsPerPage })
                .then((res) => {
                    setTimeout(() => {
                        this.list_loading = false
                        this.report_items = res.data.report_doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        },
        // 报事报修新增/编辑弹出框关闭
        reportDialogClose() {
            this.reportDialog = false,
                this.content = ''
        },
        close() {
            this.dialog = false
            this.loading = false
            this.reply_item = {}
            this.reply_content = ''
        },
        // 保存到数据库---------------------------------------------------------------
        saveReport() {
            this.$axios.post("/report/content", { xiaoqu: this.xiaoqu, content: this.content, create_sys_user: this.user_doc._id })
                .then((res) => {
                    console.log(res.data)
                    this.reportDialogClose()
                    this.get_report_list()
                })
        },
        save() {
            if (this.reply_content == '') {
                this.snackbar = {
                    show: true,
                    text: "不允许为空",
                    color: "error",
                }
                return
            }
            this.loading = true
            this.reply_item.reply_content = this.reply_content
            this.$axios.post("/report/reply", this.reply_item)
                .then((res) => {
                    console.log(res)
                    this.close()
                    this.get_report_list()
                })
        },

        // 受理
        acceptItem(item) {
            this.loading = true
            this.$axios.post("/report/accept", item)
                .then((res) => {
                    console.log(res)
                    this.close()
                    this.get_report_list()
                    this.loading = false
                })
        },

        // 处理归档
        replyItem(item) {
            this.dialog = true
            this.reply_item = item
        },

        // 状态
        statusStr(status) {
            if (status == 0) {
                return '待受理'
            } else if (status == 1) {
                return '待处理'
            } else if (status == 2) {
                return '待评分'
            } else if (status == 3) {
                return '已评分'
            }
        }
    },
};
</script>
