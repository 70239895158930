<template>
    <v-row>
        <v-col cols="12" sm="12" md="12" style="height:100%">
            <v-card height="100%" style="overflow: auto;" class="d-flex flex-column">
                <v-card-title>
                    {{ user_doc.company ? user_doc.company.qiyequancheng : '' }} 所有小区【房产数量】统计
                </v-card-title>
                <v-card-text style="flex:1;overflow: auto;">
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading" fixed-header
                        disable-pagination hide-default-footer style="height:100%;overflow: auto;">
                        <template v-slot:[`item.money`]="{ item }">
                            <span>￥{{ getMoney(item.fangwuCount) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        user_doc: JSON.parse(sessionStorage.getItem('user_doc')),
        loading: false,
        items: [],
        headers: [
            { text: '企业全称', value: 'companyObj.qiyequancheng' },
            { text: '小区名称', value: 'name' },
            { text: '楼栋数量', value: 'loudongCount' },
            { text: '单元数量', value: 'danyuanCount' },
            { text: "房屋数量", value: "fangwuCount" },
            { text: "含费房屋", value: "feiyongCount" },
            { text: "终端用户", value: "userCount" },
            { text: "服务费/年", value: "money" },
        ],
    }),
    mounted() {
        this.getItems();
    },
    methods: {
        getItems() {
            this.loading = true
            this.$axios.post('/xiaoqu/all/fangwu/count', { company: this.user_doc.company, type: this.$route.params.type })
                .then((res) => {
                    this.items = res.data
                    this.loading = false
                })
        },
        // 计算费用
        getMoney(count) {
            console.log(count)
            return count * 29
        }
    },
};
</script>
