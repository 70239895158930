<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 活动列表
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on" :disabled="xiaoqu ? false : true">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">编辑</span>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field v-model="eventItem.title" label="标题"></v-text-field>
                                <htmlEditor :html-content="htmlContent" @htmlChange="htmlChange" v-if="dialog"></htmlEditor>
                                <!-- 开始结束日期 -->
                                <v-dialog v-model="dates_dialog" width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field readonly v-model="dateRangeText" label="有效时间范围"
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="eventItem.dates" range full-width @input="dates_dialog = false"
                                        locale="zh-cn"></v-date-picker>
                                </v-dialog>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="dialog = false" :loading="loading">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save" :loading="loading">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="eventItems" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.wx_users`]="{ item }">
                            <div>{{ item.wx_users.length }} 人</div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="primary" class="mr-2" small @click="editItem(item)">编辑</v-btn>
                            <v-btn color="error" small @click="deleteItem(item)">删除</v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import htmlEditor from "@/components/htmlEditor.vue"

export default {
    components: {
        XiaoquLists, htmlEditor
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        total_num: 0,

        // 子组件 富文本编辑器传递过来的内容htmlContent
        htmlContent: '',
        eventItem: {
            date: new Date(),
            xiaoqu: '',
            title: '',
            content: '',
            dates: []
        },
        xiaoqu: null,
        dates_dialog: false,
        options: {},
        dialog: false,
        loading: false,
        table_loading: false,
        // 列表
        eventItems: [],
        headers: [
            { text: "时间", sortable: false, value: "date" },
            { text: "标题", sortable: true, value: "title" },
            { text: "报名数", sortable: true, value: "wx_users" },
            { text: "操作", sortable: false, value: "actions", align: 'center' },
        ],
    }),
    watch: {
        dialog(val) {
            val || this.close()
        },
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_event_list(this.xiaoqu._id)
                }
            },
            deep: true,
        },
    },
    computed: {
        dateRangeText() {
            return this.eventItem.dates.join(' ~ ')
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.eventItem.xiaoqu = xiaoqu
                this.get_event_list(xiaoqu._id)
            } else {
                this.eventItems = []
            }
        },
        htmlChange(html) {
            this.htmlContent = html
        },
        // 获取列表
        get_event_list(xiaoqu) {
            this.table_loading = true
            this.$axios.get("/event/lists", { params: { xiaoqu: xiaoqu, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    this.table_loading = false
                    this.eventItems = res.data.event_doc
                    this.total_num = res.data.total_num
                })
        },

        close() {
            this.htmlContent = ''
            this.eventItem = {
                date: new Date(),
                xiaoqu: this.xiaoqu,
                title: '',
                content: '',
                dates: []
            }
        },

        // 保存到数据库---------------------------------------------------------------
        save() {
            this.eventItem.content = this.htmlContent
            if (this.eventItem.title == '' || this.eventItem.content == '' || this.eventItem.dates.length != 2) {
                this.snackbar = {
                    show: true,
                    text: "信息错误",
                    color: "error",
                }
                return
            }
            this.loading = true
            if (this.eventItem._id) {
                this.$axios.post("/event/update", this.eventItem)
                    .then((res) => {
                        console.log(res)
                        this.dialog = false
                        this.loading = false
                        this.get_event_list(this.xiaoqu._id)
                    })
            } else {
                this.$axios.post("/event/create", this.eventItem)
                    .then((res) => {
                        console.log(res)
                        this.dialog = false
                        this.loading = false
                        this.get_event_list(this.xiaoqu._id)
                    })
            }
        },

        // 编辑
        editItem(item) {
            this.htmlContent = item.content
            this.eventItem = item
            this.dialog = true
        },
        // 删除
        deleteItem(item) {
            this.$axios.post("/event/del", item)
                .then(() => {
                    this.get_event_list(this.xiaoqu._id)
                })
        }
    },
};
</script>
