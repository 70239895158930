<template>
	<v-card>
		<v-card-title class="text-button">
			<v-progress-circular indeterminate color="primary" v-show="loading"> </v-progress-circular>
			收款单：{{ shoukuandan_id }}
			<v-spacer></v-spacer>
			<template v-if="skd_info.status == 1">
				<div class="red--text">撤单原因：{{ skd_info.cancel_remark }}</div>
			</template>
			<template v-else-if="skd_info.status == 0">
				<v-form v-model="valid">
					<v-text-field label="撤单说明" v-model="cancel_remark" outlined dense hide-details="auto" color="error" clearable :rules="[(v) => !!v]"></v-text-field>
				</v-form>
				<v-btn color="error" class="ml-5" :disabled="!valid" @click.once="btn_cancel"> 撤 单 </v-btn>
			</template>
		</v-card-title>
		<v-card-text>
			<!-- lodop错误提示信息 -->
			<div ref="lodop_msg"></div>
			<!-- 字体为elementUI默认字体 -->
			<div style="border: 2px dashed #1976d2 !important; font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif" ref="print" class="pa-4 red lighten-5">
				<!-- 打印区域开始 -->
				<div style="color: #000">
					<!-- header -->
					<div style="text-align: center; font-size: 20px; font-weight: bold; line-height: 1.8">{{ skd_info.xiaoqu.name }}收费凭证【{{ skd_info.user ? skd_info.user.company.qiyejiancheng : skd_info.company.qiyejiancheng }}】</div>
					<v-divider></v-divider>
					<table width="100%" style="line-height: 1.8">
						<tr>
							<td width="30%">房号：{{ skd_info.loudong.name + "#" + skd_info.danyuan.name + "#" + skd_info.fangwu.name }}</td>
							<td width="40%" style="text-align: center">
								<div :style="skd_info.status == 1 ? 'text-transform: uppercase;text-decoration: line-through' : 'text-transform: uppercase;'">凭证：{{ skd_info._id }}</div>
							</td>
							<td width="30%" style="text-align: right">业主姓名：{{ skd_info.fangwu.yezhu }}</td>
						</tr>
					</table>
					<!-- 撤单 -->
					<div v-if="skd_info.status == 1" style="position: absolute; z-index: 999; transform: rotate(320deg); right: 300px; left: 300px; text-align: center; color: red; font-size: 66px; line-height: 66px">已撤单</div>
					<!-- content -->
					<table cellspacing="0" width="100%" style="text-align: center; color: #666; border-collapse: collapse; table-layout: fixed; border: solid 1px black; line-height: 1.8">
						<tbody>
							<tr>
								<th width="200" style="border: solid 1px black">项目</th>
								<th width="100" style="border: solid 1px black">单价</th>
								<th width="100" style="border: solid 1px black">起数</th>
								<th width="100" style="border: solid 1px black">止数</th>
								<th width="100" style="border: solid 1px black">周期/倍数</th>
								<th width="100" style="border: solid 1px black">面积/数量</th>
								<th width="100" style="border: solid 1px black">总计</th>
							</tr>
							<tr v-for="item in skd_info.feiyong" :key="item._id">
								<td style="border: solid 1px black">{{ item.mingcheng }}</td>
								<td style="border: solid 1px black">{{ item.danjia }}</td>
								<td style="border: solid 1px black">{{ item.start }}</td>
								<td style="border: solid 1px black">{{ item.end }}</td>
								<td style="border: solid 1px black">{{ item.zhouqi_beishu }}</td>
								<td style="border: solid 1px black">{{ item.shuliang }}</td>
								<td style="border: solid 1px black">{{ item.zongji }}</td>
							</tr>
						</tbody>
					</table>
					<!-- footer -->
					<table width="100%" style="line-height: 1.8">
						<tr>
							<td>
								<div>应收：￥{{ zongji }}{{ youhui ? " - 优惠：￥" + skd_info.youhui : "" }} = 实收：￥{{ total }}</div>
								<div>
									备注：<span style="white-space: pre-wrap">{{ skd_info.remark ? skd_info.remark : "" }}</span>
								</div>
							</td>
							<td style="text-align: right">
								<div>实收（大写）：{{ convertCurrency(total) }}</div>
								<div>
									收费员：{{ skd_info.user ? skd_info.user.nickname : "无" }} @ 收费方式：{{ skd_info.pay_way }} @
									{{ skd_info.date_china }}
								</div>
							</td>
						</tr>
					</table>
				</div>
				<!-- 打印区域结束 -->
			</div>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text @click="$emit('close')" :loading="loading"> 关 闭 </v-btn>
			<v-btn @click="print" color="primary" :loading="loading"> 打 印 </v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import { getLodop } from "@/utils/LodopFuncs"; //导入模块
export default {
	data: () => ({
		skd_info: {
			company: { qiyejiancheng: "企业简称" },
			xiaoqu: { name: "小区名称" },
			loudong: { name: "楼栋" },
			danyuan: { name: "单元" },
			fangwu: { name: "房号", yezhu: "业主姓名" },
			user: { company: { qiyejiancheng: "企业简称" } },
		},
		loading: false,
		total: 0.0,
		youhui: 0.0,
		zongji: 0.0,
		cancel_remark: "",
		valid: false,
	}),
	props: {
		shoukuandan_id: String,
	},
	watch: {
		shoukuandan_id(val) {
			this.skd_info = {
				company: { qiyejiancheng: "企业简称" },
				xiaoqu: { name: "小区名称" },
				loudong: { name: "楼栋" },
				danyuan: { name: "单元" },
				fangwu: { name: "房号", yezhu: "业主姓名" },
				user: { company: { qiyejiancheng: "企业简称" } },
			};
			this.total = 0.0;
			this.youhui = 0.0;
			this.zongji = 0.0;
			this.cancel_remark = "";
			// 获取收款单详细信息
			if (val != "") {
				this.get_shoukuandan(val);
			}
		},
	},
	created() {
		// 首次挂载时先加载一次信息
		this.get_shoukuandan(this.shoukuandan_id);
	},
	methods: {
		get_shoukuandan(_id) {
			this.loading = true;
			this.$axios.get("shoukuandan", { params: { _id: _id } }).then((res) => {
				setTimeout(() => {
					this.skd_info = res.data;
					this.total = res.data.total;
					this.youhui = res.data.youhui;
					this.zongji = res.data.zongji;
					this.loading = false;
				}, 500);
			});
		},

		// 撤销
		btn_cancel() {
			this.$axios.post("/shoukuandan/cancel", { shoukuandan: this.skd_info, remark: this.cancel_remark }).then((res) => {
				if (res.data.message == "撤销成功") {
					this.get_shoukuandan(this.shoukuandan_id);
					this.$emit("refresh");
				}
			});
		},

		// 打印
		print() {
			let LODOP = getLodop(); //调用getLodop获取LODOP对象
			if (LODOP.error) {
				this.$refs.lodop_msg.innerHTML = LODOP.msg;
			} else {
				LODOP.PRINT_INIT("");
				LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Width");

				LODOP.SET_PRINT_PAGESIZE(1, 2100, 930, "");
				LODOP.SET_SHOW_MODE("PREVIEW_NO_MINIMIZE", true); //预览窗口禁止最小化，并始终最前
				LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印后自动关闭预览窗口
				var strFormHtml = this.$refs.print.innerHTML;
				console.log(strFormHtml);
				LODOP.ADD_PRINT_HTM(16, 16, "100%", "100%", strFormHtml);
				LODOP.PREVIEW();
			}
		},

		// 转换大小写
		convertCurrency(n) {
			var fraction = ["角", "分"];
			var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
			var unit = [
				["元", "万", "亿"],
				["", "拾", "佰", "仟"],
			];
			var head = n < 0 ? "欠" : "";
			n = Math.abs(n);
			var s = "";
			for (let i = 0; i < fraction.length; i++) {
				s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, "");
			}
			s = s || "整";
			n = Math.floor(n);
			for (let i = 0; i < unit[0].length && n > 0; i++) {
				var p = "";
				for (let j = 0; j < unit[1].length && n > 0; j++) {
					p = digit[n % 10] + unit[1][j] + p;
					n = Math.floor(n / 10);
				}
				s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
			}
			return (
				head +
				s
					.replace(/(零.)*零元/, "元")
					.replace(/(零.)+/g, "零")
					.replace(/^整$/, "零元整")
			);
		},
	},
};
</script>
