<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;" class="d-flex flex-column">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 出库入库
                    <v-spacer></v-spacer>
                    <v-dialog v-model="materialNameDialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on" :disabled="xiaoqu ? false : true">
                                新增物料
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ materialNameDialogFormTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field v-model="materialNameItem.name" label="物料名称">
                                </v-text-field>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="materialNameDialog = false">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="saveMaterialName">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text style="flex:1;overflow: auto;">
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-card>
                            <v-card-title>
                                物料名称：{{ editedItem.name }}
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea v-model="editedItem.beizhu" :label="editedItem.type + '备注'"
                                                :rules="[value => !!value || '必填']"></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="editedItem.date" label="日期" readonly
                                                        v-bind="attrs" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.date"
                                                    @input="date_menu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="editedItem.num" type="number" label="数量"
                                                :rules="[value => !!value || '必填']"></v-text-field>
                                        </v-col>

                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading" hide-default-footer
                        disable-pagination style="height:100%;overflow: auto;" show-expand :single-expand="true">
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div v-for="(log, index) in item.logs" :key="index">
                                    {{ log.date.slice(0, 10) }}
                                    <span :class="log.type == '出库' ? 'red--text' : 'primary--text'">{{ log.type }}数量【{{ log.num }}】</span>
                                    {{ log.type }}前数量【{{ log.total }}】
                                    @ {{ log.user.nickname }}:{{ log.beizhu }}
                                </div>
                            </td>
                        </template>
                        <template v-slot:[`item.inout`]="{ item }">
                            <v-btn text color="primary" class="mr-2" @click="editItem(item, '入库')">入库</v-btn>
                            <v-btn text color="error" class="mr-2" @click="editItem(item, '出库')">出库</v-btn>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="primary" small class="mr-2" @click="materialNameEdit(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="error" small @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: "",
        },
        xiaoqu: null,
        loading: false,
        date_menu: false,
        // 新增/编辑弹出框
        materialNameItem: {},
        materialNameDialog: false,
        materialNameDialogFormTitle: '新增',

        // 出入库弹出框
        dialog: false,
        editedItem: {
            vms_id: '',
            name: '',
            type: '入库',
            num: 0,
            total:0,
            beizhu: '',
            date: new Date().toISOString().substr(0, 10)
        },
        // 表单列表
        items: [],
        headers: [
            { text: '', value: 'data-table-expand' },
            { text: "名称", value: "name" },
            { text: "当前库存", value: "total" },
            { text: "操作", sortable: false, value: "inout" },
            { text: "编辑", sortable: false, value: "actions" },
        ],
    }),

    watch: {
        materialNameDialog(val) {
            val || this.materialNameDialogClose()
        },
        dialog(val) {
            val || this.dialogClose()
        }
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.getlist()
            } else {
                this.items = []
            }
        },
        materialNameEdit(item) {
            this.materialNameDialog = true
            this.materialNameDialogFormTitle = '编辑'
            this.materialNameItem = Object.assign({}, item)
        },
        editItem(item, type) {
            this.dialog = true
            this.editedItem = {
                vms_id: item._id,
                name: item.name,
                type: type,
                num: 0,
                total:item.total, //当前数量
                beizhu: '',
                date: new Date().toISOString().substr(0, 10)
            }
            this.$refs.form.resetValidation()
        },

        // 删除费用
        deleteItem(item) {
            this.$axios.post('/vms/del', item)
                .then((res) => {
                    if (!res.data.error) {
                        this.getlist()
                    }
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.error ? "error" : "success",
                    }
                })
        },
        // 关闭新增物料弹出框
        materialNameDialogClose() {
            this.materialNameDialog = false
            this.materialNameDialogFormTitle = '新增'
            this.materialNameItem = {}
        },
        // 关闭出入库弹窗口
        dialogClose() {
            this.dialog = false
            this.$refs.form.resetValidation()
        },
        // 获取物料列表
        getlist() {
            this.loading = true
            this.items = []
            this.$axios.get('/vms/lists', { params: { xiaoqu: this.xiaoqu._id } })
                .then((res) => {
                    this.loading = false
                    this.items = res.data
                    // 对记录进行排序
                    this.items.forEach(element => {
                        element.logs.sort((a, b) => {
                            return b._id.localeCompare(a._id)
                        })
                    });
                })
        },
        // 保存到数据库---------------------------------------------------------------
        saveMaterialName() {
            if (this.materialNameDialogFormTitle == '编辑') {
                // 更新
                this.$axios.post('/vms/update', this.materialNameItem)
                    .then((res) => {
                        this.materialNameDialogClose()
                        this.getlist()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackbar = {
                            show: true,
                            text: error,
                            color: "error"
                        }
                    })
            } else {
                // 添加
                this.$axios.post('/vms/create', { xiaoqu: this.xiaoqu, name: this.materialNameItem.name })
                    .then((res) => {
                        this.materialNameDialogClose()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackbar = {
                            show: true,
                            text: error,
                            color: "error"
                        }
                    })
            }
        },
        // 出库入库保存
        save() {
            if (this.$refs.form.validate()) {
                this.$axios.post('/vms/inOrOut', this.editedItem)
                    .then((res) => {
                        this.dialog = false
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.snackbar = {
                            show: true,
                            text: error,
                            color: "error"
                        }
                    })
            }
        }
    },
};
</script>
