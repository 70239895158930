<template>
    <v-col cols="12" sm="3" md="3" style="height:100%">
        <v-card style="height:100%;overflow:auto">
            <v-card-title>商家列表</v-card-title>
            <v-card-text>
                <v-list-item-group v-model="shop_selected">
                    <v-list-item v-for="(item, i) in shop_items" :key="i" two-line>
                        <v-list-item-avatar>
                            <v-img :src="'https://api.youzhuguanjia.com/' + item.logo"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.address }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-card-text>
        </v-card>
    </v-col>
</template>
<script>
export default {
    data: () => ({
        // 小区列表
        shop_items: [],
        // 当前选择项目
        shop_selected: null,
    }),
    mounted() {
        this.get_shop_list();
    },
    watch: {
        shop_selected(val) {
            // 有选择
            if (val || val == 0) {
                this.$emit('select_shop', this.shop_items[val])
            } else {
                this.$emit('select_shop')
            }
        },
    },
    methods: {
        // 获取列表
        get_shop_list() {
            var query = {}
            if (JSON.parse(sessionStorage.user_doc).company) {
                query = { company: JSON.parse(sessionStorage.user_doc).company._id }
            }
            this.$axios.get('/shop/lists', { params: query })
                .then((res) => {
                    this.shop_items = res.data
                })
        }
    }
};
</script>