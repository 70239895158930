<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }}【巡逻记录】列表
                    <v-spacer></v-spacer>
                    <!-- 开始时间 -->
                    <V-menu v-model="startMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="开始时间" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" @input="startMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 结束时间 -->
                    <V-menu v-model="endMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="结束时间" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" @input="endMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <v-btn color="primary" :disabled="!xiaoqu" @click="search" :loading="loading" class="mx-5">
                        查询
                    </v-btn>
                    <download-excel :data="items" :fields="excelFields" :name="(xiaoqu ? xiaoqu.name : '') + '巡逻记录'">
                        <v-btn color="success" :disabled="!items.length">
                            导出
                        </v-btn>
                    </download-excel>
                </v-card-title>
                <v-card-text style="flex:1;">
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading"
                        :options.sync="options" :server-items-length="total_num" height="68vh">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            {{ item.status ? '异常' : '正常' }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import JsonExcel from "vue-json-excel";
export default {
    components: {
        XiaoquLists,
        'downloadExcel': JsonExcel
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },

        // 日期选择框
        startMenu: false,
        endMenu: false,
        startDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        endDate: new Date().toLocaleDateString().replace(/\//g, '-'),

        loading: false,
        total_num: 0,
        options: {},
        items: [],
        xiaoqu: null,
        headers: [
            { text: "时间", sortable: true, value: "date" },
            { text: "点位", sortable: true, value: "qrcode_name" },
            { text: "姓名", sortable: false, value: "nickname" },
            { text: "电话", sortable: false, value: "phone" },
            { text: "状态", sortable: false, value: "status" },
            { text: "内容", sortable: false, value: "content" },
        ],
        excelFields: {
            "日期": {
                field: "date",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
            "点位": "qrcode_name",
            "姓名": "nickname",
            "电话": "phone",
            "状态": {
                field: "status",
                callback: (value) => {
                    return value ? '异常' : '正常'
                },
            },
            "内容": "content"
        }
    }),
    watch: {
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.search()
                }
            },
            deep: true,
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            this.items = []
        },

        // 获取巡逻巡检记录
        search() {
            this.loading = true
            var params = {
                startDate: this.startDate, endDate: this.endDate, xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage
            }
            this.$axios.get('/qrcode/record/get_sheet_lists', { params: params })
                .then((res) => {
                    res.data.doc.forEach(element => {
                        element.detail.forEach(element_detail => {
                            element[element_detail.name] = element_detail.value
                        })
                    });
                    console.log(res.data.doc)
                    setTimeout(() => {
                        this.loading = false
                        this.items = res.data.doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        },
    },
};
</script>
