<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }}【巡检点位】管理
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!xiaoqu" @click="add_sheet" color="primary">
                        新增点位
                    </v-btn>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <v-card>
                            <v-card-title>
                                {{ dialog_title }}
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-text-field autofocus label="点位名称" v-model="edit_qrcode.qrcode_name"
                                        :rules="[value => !!value || '必填']">
                                    </v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    确定
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="sheet_items" item-key="_id" :loading="loading"
                        :options.sync="options" :server-items-length="total_num" height="68vh">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn @click="del_sheet(item)" text color="red">
                                删除
                            </v-btn>
                            <v-btn @click="edit_sheet(item)" text color="primary">
                                编辑
                            </v-btn>
                            <v-btn color="primary" dark @click="download_qrcode(item)">
                                下载二维码
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialogQrcode" max-width="548px">
                        <v-card>
                            <v-card-title>
                                下载巡逻巡检二维码
                            </v-card-title>
                            <v-card-text>
                                <div ref="qrcode_print">
                                    <img :src="sheetObj.qrcode_img_src" :alt="sheetObj.qrcode_name">
                                    <div class="grey lighten-3 font-weight-black text-h4 text-center pa-5">{{
                                        sheetObj.qrcode_name }}</div>
                                </div>
                            </v-card-text>

                            <v-card-actions>
                                {{ sheetObj.qrcode_url }}
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="dialogQrcode = false">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="downloadQrcode(sheetObj)">
                                    下载
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import html2canvas from 'html2canvas'
import QRCode from "qrcode";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        xiaoqu: null,
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        loading: false,
        total_num: 0,
        options: {},
        dialogQrcode: false,
        sheetObj: {},
        dialog: false,
        dialog_title: '',
        edit_qrcode: {
            qrcode_name: '',
            xiaoqu: '',
        },
        sheet_items: [],
        headers: [
            { text: "名称", sortable: true, value: "qrcode_name" },
            { text: "操作", align: 'center', sortable: false, value: "action" },
        ],
    }),
    watch: {
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_sheet_lists()
                }
            },
            deep: true,
        },
    },
    methods: {

        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.get_sheet_lists()
            } else {
                this.sheet_items = []
            }
        },

        // 获取点位列表
        get_sheet_lists() {
            this.loading = true
            var params = {
                xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage
            }
            this.$axios.get('/qrcode/sheet/lists', { params: params })
                .then(res => {
                    this.loading = false
                    this.sheet_items = res.data.doc
                    this.total_num = res.data.total_num
                })
        },

        // 打开对话框
        add_sheet() {
            this.dialog = true
            this.dialog_title = '新增'
            this.edit_qrcode = {
                qrcode_name: '',
                xiaoqu: this.xiaoqu._id
            }
        },
        // 关闭对话框
        close() {
            this.dialog = false
            this.edit_qrcode = {
                qrcode_name: '',
                xiaoqu: '',
            }
        },

        // 保存点位
        save() {
            if (this.$refs.form.validate()) {
                if (this.dialog_title == '新增') {
                    this.$axios.post('/qrcode/sheet/create', this.edit_qrcode)
                        .then(res => {
                            console.log(res)
                            this.dialog = false
                            this.get_sheet_lists()
                        })
                } else {
                    this.$axios.post('/qrcode/sheet/updata', this.edit_qrcode)
                        .then(res => {
                            console.log(res)
                            this.dialog = false
                            this.get_sheet_lists()
                        })
                }
            }
        },

        // 编辑点位
        edit_sheet(item) {
            this.dialog = true
            this.edit_qrcode = Object.assign({}, item)
            this.dialog_title = '编辑'
        },

        // 删除点位
        del_sheet(item) {
            this.$axios.post('/qrcode/sheet/del', item)
                .then(res => {
                    if (res.data.error) {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "error",
                        }
                    } else {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "success",
                        }
                        this.get_sheet_lists()
                    }
                })
        },
        // 下载二维码
        download_qrcode(item) {
            this.sheetObj = item
            this.dialogQrcode = true
            let _this = this
            this.sheetObj.qrcode_url = 'https://api.youzhuguanjia.com/qrcode/' + this.xiaoqu.company.site.site_code + '?type=sheet' + '&id=' + this.sheetObj._id
            // 生成普通二维码，微信扫码打开小程序
            QRCode.toDataURL(this.sheetObj.qrcode_url, { width: 500, margin: 2 }, function (err, url) {
                _this.sheetObj.qrcode_img_src = url
            })

        },
        downloadQrcode(item) {
            html2canvas(this.$refs.qrcode_print).then((canvas) => {
                const link = canvas.toDataURL("image/jpg");
                let a = document.createElement('a')
                a.setAttribute('download', this.xiaoqu.name + '-' + item.qrcode_name)
                a.href = link
                a.click()
            })
        },
    },
};
</script>
<style></style>
