<template>
    <v-row>
        <v-col cols="12" sm="12" md="12" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>合作申请列表</v-card-title>
                <v-card-text>
                    <!-- 表格 -->
                    <v-data-table :headers="headers" :items="join_items" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num" height="66vh">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        options: {},
        total_num: 0,
        table_loading: false,
        // 费项列表
        join_items: [],
        headers: [
            { text: '时间', sortable: false,value: 'date' },
            { text: "姓名", sortable: false, value: "nickname" },
            { text: "电话", sortable: false, value: "phone" },
            { text: "详情", sortable: false, value: "content" },
        ],
    }),
    watch: {
        options: {
            handler() {
                this.get_join_lists()
            },
            deep: true,
        },
    },
    methods: {
        // 获取订单列表
        get_join_lists() {
            this.table_loading = true
            this.$axios.get("/join", { params: { page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    res.data.doc.forEach(element => {
                        element.detail.forEach(element_detail => {
                            element[element_detail.name] = element_detail.value
                        })
                    });
                    this.table_loading = false
                    this.join_items = res.data.doc
                    this.total_num = res.data.total_num
                })
        },
    }
}
</script>
