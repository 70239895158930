<template>
    <v-col cols="12" sm="3" md="3" style="height:100%">
        <v-card style="height:100%;overflow:auto">
            <v-card-title>小区列表</v-card-title>
            <v-card-text>
                <v-progress-circular indeterminate color="primary" v-show="loading"></v-progress-circular>
                <v-item-group active-class="primary" v-model="xiaoqu_selected">
                    <v-row>
                        <v-col v-for="(item, index) in xiaoqu_items" :key="index" cols="4" sm="12" md="6" lg="4">
                            <v-hover v-slot="{ hover }">
                                <v-item v-slot="{ active, toggle }">
                                    <v-card class="d-flex align-center py-8" @click="toggle">
                                        <div class="flex-grow-1 text-center">
                                            <span :class="active ? 'white--text' : ''">{{ item.name }}</span>
                                        </div>
                                        <v-expand-transition>
                                            <div v-if="hover" class="company red white--text text-caption text-center">
                                                <span>{{ item.company.qiyejiancheng }}</span>
                                            </div>
                                        </v-expand-transition>
                                    </v-card>
                                </v-item>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-item-group>
            </v-card-text>
        </v-card>
    </v-col>
</template>
<script>
export default {
    data: () => ({
        // 小区列表
        xiaoqu_items: [],
        // 当前选择项目
        xiaoqu_selected: null,
        loading:false,
    }),

    // 权限管理需要用户能获取所有的小区列表，所以传了一个isall参数过来
    props: ['is-all'],
    mounted() {
        this.get_xiaoqu_list();
    },
    watch: {
        xiaoqu_selected(val) {
            // 有选择
            if (val || val == 0) {
                this.$emit('select_xiaoqu', this.xiaoqu_items[val])
            } else {
                this.$emit('select_xiaoqu')
            }
        },
    },
    methods: {
        // 获取小区列表
        get_xiaoqu_list() {
            this.loading = true
            this.$axios
                .get("/xiaoqu/getlist?isall=" + this.isAll)
                .then((res) => {
                    this.xiaoqu_items = res.data;
                    this.loading = false
                });
        }
    }
};
</script>
<style scoped>
.company {
    position: absolute;
    bottom: 0;
    width: 100%;
}
</style>