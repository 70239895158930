<template>
    <v-row>
        <ShopLists @select_shop="select_shop"></ShopLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="flex:1;overflow: auto;">
                <v-card-title>
                    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
                    </v-snackbar>
                    订单列表</v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="order_items" :options.sync="options"
                        :server-items-length="total_num" :loading="table_loading">
                        <template v-slot:[`item.pay_date`]="{ item }">
                            {{ new Date(item.pay_date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.total_fee`]="{ item }">
                            <span>{{ item.total_fee / 100 }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn small @click="getDetailItem(item)" class="mr-5">详情</v-btn>
                            <v-btn :disabled="item.order_status != 0" small @click="updateOrder(item, order_status = 1)"
                                class="mr-5">接单</v-btn>
                            <v-btn :disabled="item.order_status != 1" small @click="updateOrder(item, order_status = 2)"
                                class="mr-5">完成</v-btn>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialog" width="800">
                        <v-card>
                            <v-card-title>
                                订单编号：{{ detailItem._id }}
                            </v-card-title>
                            <v-card-text>
                                {{ detailItem.address.name }}
                                {{ detailItem.address.phone }}
                                {{ detailItem.address.detail }}
                                {{ detailItem.detail }}
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>

import ShopLists from "@/components/shop-lists.vue";
export default {
    components: {
        ShopLists
    },
    data: () => ({
        shop: undefined,
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: ''
        },
        table_loading: false,
        options: {},
        total_num: 0,
        loading: false,
        order_items: [],
        headers: [
            { text: "订单号", sortable: false, value: "_id" },
            { text: "支付时间", sortable: false, value: "pay_date" },
            { text: "支付供应商", sortable: false, value: "provider" },
            { text: "支付方式", sortable: false, value: "provider_pay_type" },
            { text: "金额", sortable: false, value: "total_fee" },
            { text: "状态", sortable: false, value: "status" },
            { text: "操作", sortable: false, value: "actions" },
        ],
        // 订单详情
        dialog: false,
        detailItem: { address: {} }
    }),
    watch: {
        options: {
            handler() {
                if (this.shop) {
                    this.get_order_lists()
                }
            },
            deep: true,
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_shop(shop) {
            this.shop = shop
            if (shop) {
                // 获取列表
                this.get_order_lists()
            } else {
                this.order_items = []
            }
        },
        // 获取订单列表
        get_order_lists() {
            this.table_loading = true
            this.$axios.get("/wechat/order/lists", { params: { shop: this.shop._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    this.table_loading = false
                    this.order_items = res.data.doc
                    this.total_num = res.data.total_num

                })
        },
        // 获取订单详情
        getDetailItem(item) {
            this.dialog = true
            this.detailItem = item
        },
        // 接单、完成
        updateOrder(item, order_status) {
            this.$axios.post("/wechat/order/update", { order: item._id, order_status: order_status })
                .then((res) => {
                    console.log(res.data)
                    this.get_order_lists()
                })
        }
    },
}
</script>