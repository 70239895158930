import xitongshezhiView from '../views/xitongguanli/xitongshezhiView.vue'
import mokuaiguanliView from '../views/xitongguanli/mokuaiguanliView.vue'
import qiyeguanliView from '../views/xitongguanli/qiyeguanliView.vue'
import quanxianshezhiView from '../views/xitongguanli/quanxianshezhiView.vue'
import joinView from '../views/xitongguanli/joinView.vue'
import layoutView from '../layout/default.vue'

const xitongguanli_routes = {
  path: '/xitongguanli',
  component: layoutView,
  children: [
    {
      path: '/xitongguanli/xitongshezhi',
      name: 'xitongshezhi',
      component: xitongshezhiView
    },
    {
      path: '/xitongguanli/mokuaiguanli',
      name: 'mokuaiguanli',
      component: mokuaiguanliView
    },
    {
      path: '/xitongguanli/qiyeguanli',
      name: 'qiyeguanli',
      component: qiyeguanliView
    },
    {
      path: '/xitongguanli/quanxianshezhi',
      name: 'quanxianshezhi',
      component: quanxianshezhiView
    },
    {
      path: '/xitongguanli/join',
      name: 'join',
      component: joinView
    }
  ]
}

export default xitongguanli_routes