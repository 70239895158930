<template>
    <v-row>
        <v-col cols="12" sm="12" md="12" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>用户列表
                    <v-spacer></v-spacer>
                    <v-btn @click="get_user_lists" color="primary" class="mx-5" :loading="table_loading">
                        刷新
                    </v-btn>
                    <v-btn @click="get_oa_users" color="primary" :loading="table_loading">
                        同步公众号用户信息
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <!-- 表格 -->
                    <v-data-table :headers="headers" :items="user_items" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num" height="66vh">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.wx_unionid`]="{ item }">
                            {{ formatStr(item.wx_unionid) }}
                        </template>
                        <template v-slot:[`item.wx_app_openid`]="{ item }">
                            {{ formatStr(item.wx_app_openid) }}
                        </template>
                        <template v-slot:[`item.wx_mp_openid`]="{ item }">
                            {{ formatStr(item.wx_mp_openid) }}
                        </template>
                        <template v-slot:[`item.wx_oa_openid`]="{ item }">
                            {{ formatStr(item.wx_oa_openid) }}
                        </template>
                        <template v-slot:[`item.apple_openid`]="{ item }">
                            {{ formatStr(item.apple_openid) }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        options: {},
        total_num: 0,
        table_loading: false,
        // 用户列表
        user_items: [],
        headers: [
            { text: "注册/关注时间", sortable: false, value: "date" },
            { text: "归属站点", sortable: false, value: "site.site_name" },
            { text: "认证姓名", sortable: false, value: "nickname" },
            { text: "绑定小区", sortable: false, value: "xiaoqu.name" },
            { text: "微信wx_unionid", sortable: false, value: "wx_unionid" },
            { text: "微信APPwx_app_openid", sortable: false, value: "wx_app_openid" },
            { text: "微信小程序wx_mp_openid", sortable: false, value: "wx_mp_openid" },
            { text: "微信公众号wx_mp_openid", sortable: false, value: "wx_oa_openid" },
            { text: "苹果apple_openid", sortable: false, value: "apple_openid" },
            // { text: "认证姓名", sortable: false, value: "nickname" },
            // { text: "认证身份证", sortable: false, value: "idcard" },
            // { text: "认证电话", sortable: false, value: "phone" }
        ],
    }),
    watch: {
        options: {
            handler() {
                this.get_user_lists()
            },
            deep: true,
        },
    },
    methods: {
        // 获取业主列表
        get_user_lists() {
            this.table_loading = true
            this.$axios.get("/wx_user/allusers", { params: { page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    this.table_loading = false
                    this.user_items = res.data.user_doc
                    this.total_num = res.data.total_num
                })
        },
        // 同步公众号用户信息
        get_oa_users() {
            this.table_loading = true
            this.$axios.get("/weixin/get_oa_users")
                .then((res) => {
                    this.table_loading = false
                    this.get_user_lists()
                    console.log(res.data)
                })
        },
        // 获取字符串的前四位和后四位
        formatStr(str) {
            if (str) {
                return str.slice(0, 4) + '****' + str.slice(-4)
            } else {
                return 'null'
            }
        }
    },
};
</script>
