<template>
    <div class="flex-grow-1 d-flex flex-column">
        <!-- 工具栏 -->
        <vxe-toolbar ref="toolbarRef" export print custom :refresh="{ queryMethod: getTableData }">
            <template #buttons>
                <vxe-button icon="vxe-icon-square-plus" @click="insertEvent()">新增</vxe-button>
            </template>
        </vxe-toolbar>
        <!-- 表格 -->
        <div class="flex-grow-1">
            <vxe-table ref="tableRef" height="auto" size="mini" show-overflow :loading="loading" :data="tableData"
                :export-config="{}" :print-config="{}">
                <vxe-column type="seq" width="60"></vxe-column>
                <vxe-column field="company.site.site_name" title="归属站点"></vxe-column>
                <vxe-column field="company.qiyejiancheng" title="归属企业"></vxe-column>
                <vxe-column field="name" title="小区名称">
                    <template #default="{ row }">
                        <v-avatar rounded size="25">
                            <img
                                :src="'https://api.youzhuguanjia.com/' + (row.picture ? row.picture : 'images/default.png')">
                        </v-avatar>
                        {{ row.name }}
                    </template>
                </vxe-column>
                <vxe-column field="money" title="余额"></vxe-column>
                <vxe-column field="guanjia" title="管家姓名">
                    <template #default="{ row }">
                        <v-avatar rounded size="25">
                            <img :src="'https://api.youzhuguanjia.com/' + (row.avatar ? row.avatar : 'images/avatar.jpg')">
                        </v-avatar>
                        {{ row.guanjia }}
                    </template>
                </vxe-column>
                <vxe-column field="phone" title="管家电话"></vxe-column>
                <vxe-column field="address" title="详细地址"></vxe-column>
                <vxe-column field="shop" title="首页商家">
                    <template #default="{ row }">
                        {{ row.shop.length }}
                    </template>
                </vxe-column>
                <vxe-column title="操作" :align="'center'">
                    <template #default="{ row }">
                        <vxe-button type="text" icon="vxe-icon-edit" @click="editEvent(row)"></vxe-button>
                        <vxe-button type="text" icon="vxe-icon-feedback" @click="anwserEvent(row)"></vxe-button>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>
        <!-- 表单 -->
        <vxe-modal v-model="showEditModal" :title="selectRowData ? '编辑&保存' : '新增&保存'" width="800" resize destroy-on-close>
            <template #default>
                <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent">
                    <vxe-form-item field="_id" title="ID" :span="24"></vxe-form-item>
                    <vxe-form-item field="company" title="归属企业" :span="12">
                        <template #default="{ data }">
                            <vxe-select v-model="data.company._id" placeholder="归属企业">
                                <vxe-option v-for="company in company_items" :key="company._id" :value="company._id"
                                    :label="company.qiyejiancheng"></vxe-option>
                            </vxe-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="shop" title="首页商家" :span="12">
                        <template #default="{ data }">
                            <vxe-select v-model="data.shop" placeholder="首页商家" multiple>
                                <vxe-option v-for="shop in shop_items" :key="shop._id" :value="shop._id"
                                    :label="shop.name"></vxe-option>
                            </vxe-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="name" title="小区名称" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.name" placeholder="小区名称"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="money" title="小区余额" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.money" placeholder="小区余额"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="guanjia" title="小区管家" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.guanjia" placeholder="小区管家"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="phone" title="管家电话" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.phone" placeholder="管家电话"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="address" title="详细地址" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.address" placeholder="详细地址"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="latlng" title="经纬度" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.latlng" placeholder="经纬度"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item :align="'right'" title-align="left" :span="24">
                        <template #default>
                            <vxe-button type="submit">提交</vxe-button>
                        </template>
                    </vxe-form-item>
                </vxe-form>
            </template>
        </vxe-modal>
    </div>
</template>
<script>
import { VXETable } from 'vxe-table'
export default {
    data: () => ({
        loading: false,
        tableData: [],
        company_items: [],
        shop_items: [],
        formData: {
            company: {},
            name: '',
            money: '',
            picture: '',
            avatar: '',
            phone: '',
            guanjia: '',
            shop: [],
            address: '',
            latlng: '39.90871879886639, 116.3973701092209'
        },
        defaultFormData: {
            company: {},
            name: '',
            money: '',
            picture: '',
            avatar: '',
            phone: '',
            guanjia: '',
            shop: [],
            address: '',
            latlng: '39.90871879886639, 116.3973701092209'
        },
        formRules: {
            company: [
                { required: true, message: '请选择归属企业' }
            ],
            name: [
                { required: true, message: '请输入小区名称' }
            ],
            latlng: [
                { required: true, message: '请输入小区经纬度' }
            ],
        },
        selectRowData: null,
        showEditModal: false,
    }),
    watch: {

    },
    created() {
        this.$nextTick(() => {
            // 手动将表格和工具栏进行关联
            this.$refs.tableRef.connect(this.$refs.toolbarRef)
            this.getTableData()
            this.get_company_list()
            this.get_shop_list()
        })
    },
    methods: {
        getTableData() {
            this.loading = true
            this.$axios.get("/xiaoqu/getlist")
                .then((res) => {
                    this.tableData = res.data
                    this.loading = false
                })
        },
        // 获取企业列表
        get_company_list() {
            this.$axios.get('/company/getlist')
                .then((res) => {
                    this.company_items = res.data
                })
        },
        // 获取商家列表
        get_shop_list() {
            var query = {}
            if (JSON.parse(sessionStorage.user_doc).company) {
                query = { company: JSON.parse(sessionStorage.user_doc).company._id }
            }
            this.$axios.get('/shop/lists', { params: query })
                .then((res) => {
                    this.shop_items = res.data
                })
        },
        // 点击【新增】事件
        insertEvent() {
            this.formData = Object.assign({}, this.defaultFormData)
            this.selectRowData = null
            this.showEditModal = true
        },
        // 点击【编辑】事件
        editEvent(row) {
            this.formData = Object.assign({}, row)
            this.selectRowData = row
            this.showEditModal = true
        },
        // 提交数据
        submitEvent() {
            const $table = this.$refs.tableRef
            this.loading = true
            this.$axios.post("/xiaoqu/update", this.formData)
                .then(res => {
                    if (this.selectRowData) {
                        VXETable.modal.message({ content: '保存成功', status: 'success' })
                        Object.assign(this.selectRowData, res.data)
                    } else {
                        VXETable.modal.message({ content: '新增成功', status: 'success' })
                        $table.insert(res.data)
                    }
                    this.loading = false
                    this.showEditModal = false
                }).catch(error => {
                    VXETable.modal.message({ content: error.message, status: 'error' })
                    this.loading = false
                })
        },
    },
};
</script>
