import Vue from 'vue';
import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import 'vxe-table/lib/style.css'
VXETable.use(VXETablePluginExportXLSX)
Vue.use(VXETable)
VXETable.config({
    table: {
        // 表头居中
        headerAlign: 'center',
        // 自动宽高
        autoResize: true,
        // 是否带有边框
        border: true,
        // 是否为圆角边框
        round: true,
        // 当鼠标移到行、鼠标点击行时，高亮当前行
        rowConfig: { useKey: true, keyField: '_id', isHover: true, isCurrent: true },
        // 启用列宽调整 && 当鼠标点击列头、鼠标移到列头时，高亮当前列
        columnConfig: { useKey: true, isHover: true, isCurrent: true, resizable: true },
        // 树形结构配置项
        treeConfig: { transform: true, rowField: '_id', parentField: 'parent_id',accordion:true }
    }
})
VXETable.formats.mixin({
    // 格式化性别
    formatSex({ cellValue }) {
        return cellValue ? (cellValue === '1' ? '男' : '女') : ''
    },
    // 格式化下拉选项
    formatSelect({ cellValue }, list) {
        const item = list.find(item => item.value === cellValue)
        return item ? item.label : ''
    },
    // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
    formatDate({ cellValue }, format) {
        return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
    },
    // 四舍五入金额，每隔3位逗号分隔，默认2位数
    formatAmount({ cellValue }, digits = 2) {
        return cellValue ? XEUtils.commafy(XEUtils.toNumber(cellValue), { digits }) : '-'
    },
    // 格式化银行卡，默认每4位空格隔开
    formatBankcard({ cellValue }) {
        return XEUtils.commafy(XEUtils.toValueString(cellValue), { spaceNumber: 4, separator: ' ' })
    },
    // 四舍五入,默认两位数
    formatFixedNumber({ cellValue }, digits = 2) {
        return XEUtils.toFixed(XEUtils.round(cellValue, digits), digits)
    },
    // 向下舍入,默认两位数
    formatCutNumber({ cellValue }, digits = 2) {
        return XEUtils.toFixed(XEUtils.floor(cellValue, digits), digits)
    }
})
export default VXETable;