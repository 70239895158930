<template>
  <v-row>
    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
    </v-snackbar>
    <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
    <v-col cols="12" sm="6" md="6" style="height: 100%;">
      <v-card height="100%" style="overflow: auto;" :loading="loudong_loading">
        <v-card-title>{{ xiaoqu_selected ? xiaoqu_selected.name : '' }}楼栋列表
          <v-spacer></v-spacer>
          <v-btn :disabled="!xiaoqu_selected" @click="addLoudong">递增+1
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-item-group active-class="primary" v-model="loudong_selected">
            <v-row>
              <v-col v-for="(item, index) in loudong_items" :key="index" cols="4" sm="6" md="3" lg="2">
                <v-hover v-slot="{ hover }">
                  <v-item v-slot="{ active, toggle }">
                    <v-card class="d-flex align-center" style="line-height: 6;" @click="toggle">
                      <div class="flex-grow-1 text-center">
                        <span :class="active ? 'white--text' : ''">{{ item.name }}</span>
                      </div>
                      <v-expand-transition>
                        <div v-if="hover" class="edit d-flex justify-space-around py-1 red">
                          <v-btn x-small rounded @click.stop="btn_edit_loudong(item)">编辑</v-btn>
                          <v-btn x-small rounded @click.stop="btn_del_loudong(item)">删除</v-btn>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-item>
                </v-hover>
              </v-col>
              <v-col v-if="xiaoqu_selected || xiaoqu_selected == 0" cols="4" sm="6" md="3" lg="2">
                <v-dialog v-model="dialog_edit_loudong" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card class="d-flex align-center" style="line-height: 6;" v-on="on" v-bind="attrs">
                      <div class="flex-grow-1 text-center">+</div>
                    </v-card>
                  </template>
                  <v-card>
                    <v-card-title> {{ action }}楼栋 </v-card-title>
                    <v-card-text>
                      <v-form ref="form_loudong">
                        <v-text-field autofocus label="楼栋名称" v-model="edit_loudong.name"
                          :rules="[value => !!value || '必填']">
                        </v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog_edit_loudong = false">
                        取消
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save_loudong">
                        保存
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col v-else>
                <v-alert type="error" outlined dense>请先点击一个小区！</v-alert>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3" md="3" style="height: 100%;">
      <v-card height="100%" style="overflow: auto;" :loading="danyuan_loading">
        <v-card-title>{{ loudong_name }}单元列表
          <v-spacer></v-spacer>
          <v-btn :disabled="loudong_selected == null" @click="addDanyuan">递增+1
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-item-group active-class="primary" v-model="danyuan_selected">
            <v-row>
              <v-col v-for="(item, index) in danyuan_items" :key="index" cols="4" sm="12" md="6" lg="4">
                <v-hover v-slot="{ hover }">
                  <v-item v-slot="{ active, toggle }">
                    <v-card class="d-flex align-center" height="100" @click="toggle">
                      <div class="flex-grow-1 text-center">
                        <span :class="active ? 'white--text' : ''">{{ item.name }}</span>
                      </div>
                      <v-expand-transition>
                        <div v-if="hover" class="edit d-flex justify-space-around py-1 red">
                          <v-btn x-small rounded @click.stop="btn_edit_danyuan(item)">编辑</v-btn>
                          <v-btn x-small rounded @click.stop="btn_del_danyuan(item)">删除</v-btn>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-item>
                </v-hover>
              </v-col>
              <v-col v-if="loudong_selected || loudong_selected == 0" cols="4" sm="12" md="6" lg="4">
                <v-dialog v-model="dialog_edit_danyuan" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card class="d-flex align-center" height="100" v-on="on" v-bind="attrs">
                      <div class="flex-grow-1 text-center">+</div>
                    </v-card>
                  </template>
                  <v-card>
                    <v-card-title> {{ action }}单元 </v-card-title>
                    <v-card-text>
                      <v-form ref="form_danyuan">
                        <v-text-field autofocus label="单元名称" v-model="edit_danyuan.name"
                          :rules="[value => !!value || '必填']">
                        </v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog_edit_danyuan = false">
                        取消
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save_danyuan">
                        保存
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col v-else>
                <v-alert type="error" outlined dense>请先点击一个楼栋！</v-alert>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
  components: {
    XiaoquLists
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: "success",
      site_error: "",
    },

    loudong_loading: false,
    danyuan_loading: false,

    action: "创建",
    dialog_edit_loudong: false,
    dialog_edit_danyuan: false,

    // 被选中时的数据
    xiaoqu_selected: null,
    loudong_selected: null,
    danyuan_selected: null,

    // 展现在title中的字段
    xiaoqu_name: "",
    loudong_name: "",

    // 添加或编辑时在弹窗口中的字段
    edit_loudong: { name: '第栋' },
    edit_danyuan: { name: '单元' },

    // 被循环的数组
    loudong_items: [],
    danyuan_items: [],
  }),
  mounted() {

  },
  methods: {

    // 子组件事件 选择小区
    select_xiaoqu(xiaoqu) {
      this.loudong_items = []
      this.loudong_selected = null
      this.xiaoqu_selected = xiaoqu
      if (this.xiaoqu_selected._id) {
        this.get_loudong_list()
      }
    },

    // 获取楼栋信息
    get_loudong_list() {
      this.loudong_loading = true
      this.$axios
        .get("/loudong/getlist", { params: { xiaoqu: this.xiaoqu_selected._id } })
        .then((res) => {
          console.log(res.data);
          this.loudong_loading = false
          this.loudong_items = res.data;
        });
    },

    // 获取单元信息
    get_danyuan_list() {
      this.danyuan_loading = true
      var loudong = this.loudong_items[this.loudong_selected];
      this.$axios
        .get("/danyuan/getlist", { params: { loudong: loudong._id } })
        .then((res) => {
          console.log(res.data);
          this.danyuan_items = res.data;
          this.danyuan_loading = false
        });
    },
    addLoudong() {
      // 自动新增楼栋
      this.$axios
        .post("/loudong/create", { name: '第' + (this.loudong_items.length + 1) + '栋', xiaoqu: this.xiaoqu_selected })
        .then(() => {
          this.get_loudong_list();
        });
    },
    // 保存楼栋信息
    save_loudong() {
      if (this.$refs.form_loudong.validate()) {
        if (this.action == "编辑") {
          // 更新
          this.$axios.post("/loudong/update", this.edit_loudong).then((res) => {
            console.log(res);
            this.dialog_edit_loudong = false;
          });
        } else if (this.action == "创建") {
          // 新增
          this.$axios
            .post("/loudong/create", { name: this.edit_loudong.name, xiaoqu: this.xiaoqu_selected })
            .then((res) => {
              console.log(res);
              this.dialog_edit_loudong = false;
            });
        }
      }
    },
    addDanyuan() {
      // 自动新增单元
      this.$axios
        .post("/danyuan/create", { name: (this.danyuan_items.length + 1) + '单元', loudong: this.loudong_items[this.loudong_selected] })
        .then(() => {
          this.get_danyuan_list();
        });
    },
    // 保存单元信息
    save_danyuan() {
      if (this.$refs.form_danyuan.validate()) {
        if (this.action == "编辑") {
          // 更新
          this.$axios.post("/danyuan/update", this.edit_danyuan).then((res) => {
            console.log(res);
            this.dialog_edit_danyuan = false;
          });
        } else if (this.action == "创建") {
          // 新增
          var loudong = this.loudong_items[this.loudong_selected];
          this.$axios
            .post("/danyuan/create", { name: this.edit_danyuan.name, loudong: loudong })
            .then((res) => {
              console.log(res);
              this.dialog_edit_danyuan = false;
            });
        }
      }
    },

    // 编辑楼栋
    btn_edit_loudong(item) {
      this.dialog_edit_loudong = true;
      this.edit_loudong = item;
      this.action = "编辑";
    },

    // 删除楼栋
    btn_del_loudong(item) {
      this.$axios.post("/loudong/del", item).then((res) => {
        console.log(res);
        this.get_loudong_list();
        this.dialog_edit_loudong = false;
        this.snackbar = {
          show: true,
          text: res.data.message,
          color: res.data.error ? "error" : "success",
        };
      });
    },

    // 编辑单元
    btn_edit_danyuan(item) {
      this.dialog_edit_danyuan = true;
      this.edit_danyuan = item;
      this.action = "编辑";
    },

    // 删除单元
    btn_del_danyuan(item) {
      this.$axios
        .post("/danyuan/del", item)
        .then((res) => {
          console.log(res);
          this.get_danyuan_list();
          this.dialog_edit_danyuan = false;
          this.snackbar = {
            show: true,
            text: res.data.message,
            color: res.data.error ? "error" : "success",
          };
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = {
            show: true,
            text: error,
            color: "error",
          };
        });
    },
  },
  watch: {
    dialog_edit_loudong(val) {
      // 当弹出框隐藏时，编辑的楼栋名称 清空
      if (!val) {
        this.$refs.form_loudong.resetValidation()
        this.edit_loudong = { name: '第栋' };
        this.action = "创建";
        this.get_loudong_list();
      }
    },
    dialog_edit_danyuan(val) {
      // 当弹出框隐藏时，编辑的楼栋名称 清空
      if (!val) {
        this.$refs.form_danyuan.resetValidation()
        this.edit_danyuan = { name: '单元' };
        this.action = "创建";
        this.get_danyuan_list();
      }
    },
    // val为loudong_selected更新后的值，代表items数组中的index
    loudong_selected(val) {
      // 有选择
      if (val || val == 0) {
        this.loudong_name = this.loudong_items[val].name;
        // 获取单元信息
        this.get_danyuan_list();
      } else {
        // 无选择
        this.loudong_name = "";
        this.danyuan_items = [];
      }
      // 清空单元选择
      this.danyuan_selected = null;
    },
  },
};
</script>
<style scoped>
.edit {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
