<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;" class="d-flex flex-column">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} {{ $route.params.type == 'in' ? '多径收入' : '成本支出'
                }}</v-card-title>
                <v-card-title class="flex-nowrap">
                    <!-- 开始时间 -->
                    <V-menu v-model="startMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="开始时间" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" @input="startMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 结束时间 -->
                    <V-menu v-model="endMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="结束时间" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" @input="endMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 收款方式下拉框 -->
                    <v-select :items="pay_way_items" label="方式" v-model="pay_way_selected" outlined dense hide-details
                        class="mx-5"></v-select>
                    <!-- 状态下拉框 -->
                    <v-select :items="status_items" label="状态" v-model="status_selected" outlined dense hide-details
                        class="mr-5"></v-select>
                    <v-btn color="primary" :disabled="!xiaoqu" @click="getlist" :loading="loading" class="mr-5">
                        查询
                    </v-btn>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="!xiaoqu">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-select :items="type_items" v-model="editedItem.type" label="类型"
                                                    :rules="[value => !!value || '必填']" disabled>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-menu v-model="date_menu" :close-on-content-click="false"
                                                    :nudge-right="40" transition="scale-transition" offset-y
                                                    min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editedItem.date" label="日期" readonly
                                                            v-bind="attrs" v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="editedItem.date"
                                                        @input="date_menu = false"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.name" label="费用名称"
                                                    :rules="[value => !!value || '必填']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.amount" type="number" suffix="元"
                                                    label="金额" :rules="[value => !!value || '必填']"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-radio-group v-model="editedItem.pay_way" row label="方式">
                                                    <v-radio label="现金" value="现金" color="error"></v-radio>
                                                    <v-radio label="微信" value="微信" color="success"></v-radio>
                                                    <v-radio label="支付宝" value="支付宝" color="info"></v-radio>
                                                    <v-radio label="对公" value="对公" color="warning"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.from" label="对方名称"
                                                    :rules="[value => !!value || '必填']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea v-model="editedItem.beizhu" label="费用备注"
                                                    :rules="[value => !!value || '必填']"></v-textarea>
                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-title class="red--text text-subtitle-1">
                    【功能说明】一般用于记录各项目的多径收入及支出<br>
                </v-card-title>
                <v-card-text style="flex:1;overflow: auto;">
                    <div ref="lodop_msg"></div>
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading"
                        :options.sync="options" :server-items-length="total_num" style="height:100%;overflow: auto;">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleDateString() }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <v-chip :color="getColor(item.type)" dark>
                                {{ item.type == 'in' ? '收入' : '支出' }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="primary" class="mr-2" @click="printItem(item)">
                                mdi-printer
                            </v-icon>
                            <v-icon color="primary" class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-icon color="error" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:[`body.append`]="{ headers }">
                            <tr>
                                <td v-for="(header, i) in headers" :key="i">
                                    <div v-if="header.text == '日期'">
                                        汇总
                                    </div>
                                    <div v-if="header.text == '金额(元)'">
                                        {{ pageTotal }}
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import { getLodop } from '@/utils/LodopFuncs' //导入模块
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: "",
        },
        // 日期选择框
        startMenu: false,
        endMenu: false,
        startDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        endDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        status_items: [{ value: 1, text: '正常' }, { value: 0, text: '删除' }],
        pay_way_items: [
            { value: '全部', text: '全部' },
            { value: '现金', text: '现金' },
            { value: '微信', text: '微信' },
            { value: '支付宝', text: '支付宝' },
            { value: '对公', text: '对公' }
        ],

        status_selected: 1,
        pay_way_selected: '全部',
        pageTotal: 0,
        options: {},
        total_num: 0,
        dialog: false,
        xiaoqu: null,
        loading: false,
        date_menu: false,
        editedIndex: -1,
        editedItem: {
            type: 'in',
            name: '',
            from: '',
            amount: 0,
            pay_way: '现金',
            beizhu: '',
            date: new Date().toISOString().substr(0, 10)
        },
        defaultItem: {
            type: 'in',
            name: '',
            from: '',
            amount: 0,
            pay_way: '现金',
            beizhu: '',
            date: new Date().toISOString().substr(0, 10)
        },
        // 计费类型
        type_items: [
            { text: '收入', value: 'in' },
            { text: '支出', value: 'out' }
        ],
        // 表单列表
        items: [],
        headers: [
            { text: "日期", sortable: false, value: "date" },
            { text: "类型", sortable: true, value: "type" },
            { text: "名称", sortable: false, value: "name" },
            { text: "对方", sortable: false, value: "from" },
            { text: "收款", sortable: true, value: "user.nickname" },
            { text: "金额(元)", sortable: false, value: "amount" },
            { text: "方式", sortable: false, value: "pay_way" },
            { text: "操作", sortable: false, value: "actions" },
            { text: "删除", sortable: false, value: "delete" },
        ],
    }),
    created() {
        this.editedItem.type = this.$route.params.type
        this.defaultItem.type = this.$route.params.type
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '添加' : '编辑'
        },
    },
    watch: {
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.getlist()
                }
            },
            deep: true,
        },
        dialog(val) {
            val || this.close()
        }
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            this.items = []
            this.pageTotal = 0
        },

        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.editedItem.date = new Date(this.editedItem.date).toLocaleDateString()
            this.dialog = true
        },

        // 删除费用
        deleteItem(item) {
            this.$axios.post('/business/del', item)
                .then((res) => {
                    if (!res.data.error) {
                        this.getlist()
                    }
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.error ? "error" : "success",
                    }
                })
        },
        close() {
            this.dialog = false
            this.$refs.form.resetValidation()
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        // 获取费项列表
        getlist() {
            this.loading = true
            this.items = []
            this.pageTotal = 0
            var params = {
                startDate: this.startDate, endDate: this.endDate, xiaoqu: this.xiaoqu._id, type: this.$route.params.type, status: this.status_selected, page: this.options.page, itemsPerPage: this.options.itemsPerPage
            }
            if (this.pay_way_selected != '全部') {
                params.pay_way = this.pay_way_selected
            }
            this.$axios.get('/business/lists/detail', { params: params })
                .then((res) => {
                    res.data.doc.forEach(element => {
                        this.pageTotal += element.amount
                    });
                    this.pageTotal = Math.round(this.pageTotal * 100) / 100

                    this.loading = false
                    this.items = res.data.doc
                    this.total_num = res.data.total_num
                })
        },
        // 保存到数据库---------------------------------------------------------------
        save() {
            if (this.$refs.form.validate()) {
                this.editedItem.amount = Math.round(this.editedItem.amount * 100) / 100
                if (this.editedIndex > -1) {
                    // 更新
                    this.$axios.post('/business/update', this.editedItem)
                        .then((res) => {
                            console.log(res)
                            this.close()
                            this.getlist()
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: res.data.errors ? "error" : "success",
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.snackbar = {
                                show: true,
                                text: error,
                                color: "error"
                            }
                        })
                } else {
                    // 添加
                    this.editedItem.xiaoqu = this.xiaoqu
                    console.log(this.editedItem)
                    this.$axios.post('/business/create', this.editedItem)
                        .then((res) => {
                            console.log(res)
                            this.close()
                            this.getlist()
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: res.data.errors ? "error" : "success",
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.snackbar = {
                                show: true,
                                text: error,
                                color: "error"
                            }
                        })
                }
            }
        },
        printItem(item) {
            let LODOP = getLodop()//调用getLodop获取LODOP对象
            if (LODOP.error) {
                this.snackbar = {
                    show: true,
                    text: "打印模块错误，请参考提示操作！",
                    color: "error"
                }
                this.$refs.lodop_msg.innerHTML = LODOP.msg
            } else {
                LODOP.PRINT_INIT("")
                LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Width");

                LODOP.SET_PRINT_PAGESIZE(1, 2100, 930, "");
                LODOP.SET_SHOW_MODE("PREVIEW_NO_MINIMIZE", true); //预览窗口禁止最小化，并始终最前	
                LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印后自动关闭预览窗口

                var strBodyHtml = "<body style='line-height: 1.8;'>";
                strBodyHtml = strBodyHtml + "<p style='text-align:center;font-weight:bold;font-size:20px;'>" + item.xiaoqu.name + "物业服务中心" + (item.type == 'in' ? '收费' : '支付') + "凭据</p>";
                strBodyHtml = strBodyHtml + "<hr><p>" + item.date + "向【" + item.from + "】" + (item.type == 'in' ? '收取' : '支付') + item.name + "共计人民币￥" + item.amount + '元（' + this.convertCurrency(item.amount) + "）。</p>";
                strBodyHtml = strBodyHtml + "<p>备注：" + item.beizhu + "</p>";
                strBodyHtml = strBodyHtml + "<p style='text-align:right'>【" + (item.type == 'in' ? '收费' : '支付') + "方式：" + item.pay_way + "】【操作：" + item.user.nickname + "】</p>";
                strBodyHtml = strBodyHtml + "</body>";
                LODOP.ADD_PRINT_HTM(32, 16, "100%", "100%", strBodyHtml);
                LODOP.PREVIEW();
            }
        },
        // 转换大小写
        convertCurrency(n) {
            var fraction = ['角', '分'];
            var digit = [
                '零', '壹', '贰', '叁', '肆',
                '伍', '陆', '柒', '捌', '玖'
            ];
            var unit = [
                ['元', '万', '亿'],
                ['', '拾', '佰', '仟']
            ];
            var head = n < 0 ? '欠' : '';
            n = Math.abs(n);
            var s = '';
            for (let i = 0; i < fraction.length; i++) {
                s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
            }
            s = s || '整';
            n = Math.floor(n);
            for (let i = 0; i < unit[0].length && n > 0; i++) {
                var p = '';
                for (let j = 0; j < unit[1].length && n > 0; j++) {
                    p = digit[n % 10] + unit[1][j] + p;
                    n = Math.floor(n / 10);
                }
                s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
            }
            return head + s.replace(/(零.)*零元/, '元')
                .replace(/(零.)+/g, '零')
                .replace(/^整$/, '零元整');
        },
        getColor(type) {
            if (type == 'in') return 'primary'
            else return 'red'
        },
    },
};
</script>
