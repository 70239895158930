<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <v-col cols="12" sm="12" md="12" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>订单列表
                    <v-spacer></v-spacer>
                    <!-- 开始时间 -->
                    <V-menu v-model="startMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="开始时间" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" @input="startMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 结束时间 -->
                    <V-menu v-model="endMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="结束时间" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" @input="endMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    
                    <v-btn @click="get_order_lists" color="primary" class="mx-5" :loading="loading">
                        查询
                    </v-btn>
                    <download-excel :data="order_items" :fields="excelFields" name="订单列表">
                        <v-btn color="success" :disabled="!order_items.length" :loading="loading">
                            导出
                        </v-btn>
                    </download-excel>
                </v-card-title>
                <v-card-text>
                    <v-dialog v-model="dialog" max-width="280px" persistent>
                        <v-card>
                            <v-card-title>
                                <span>退款</span>
                            </v-card-title>

                            <v-card-text>
                                <p class="red--text">退款后无法恢复，确定退款么？</p>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close" :loading="loading">
                                    取消
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="refunds(refunds_item)" :loading="loading">
                                    确定退款
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- 表格 -->
                    <v-data-table :headers="headers" :items="order_items" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num" show-expand height="66vh">
                        <template v-slot:[`item.total_fee`]="{ item }">
                            {{ item.total_fee / 100 }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn v-if="item.status == 'SUCCESS'" dark color="red" @click="open_dialog(item)">退款
                            </v-btn>
                        </template>
                        <template v-slot:[`item.create_date`]="{ item }">
                            {{ new Date(item.create_date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.pay_date`]="{ item }">
                            {{ new Date(item.pay_date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.provider_pay_type`]="{ item }">
                            {{ item.provider_pay_type == 'jsapi' ? '小程序' : 'APP' }}
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div v-for="(detail_item, index) of item.detail" :key="index">
                                    <p class="text-h5">订单详情第{{ index + 1 }}条：</p>
                                    <div v-for="(detail_obj_item, index) of Object.entries(detail_item)" :key="index">{{
                                        detail_obj_item[0] }}：{{ detail_obj_item[1] }}</div>
                                </div>

                                <div v-for="(detail_item, index) of item.refund_list" :key="index">
                                    <p class="text-h5">退款详情第{{ index + 1 }}条：</p>
                                    <div v-for="(detail_obj_item, index) of Object.entries(detail_item)" :key="index">{{
                                        detail_obj_item[0] }}：{{ detail_obj_item[1] }}</div>
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import JsonExcel from "vue-json-excel";
export default {
    components: {
        'downloadExcel': JsonExcel
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        // 日期选择框
        startMenu: false,
        endMenu: false,
        startDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        endDate: new Date().toLocaleDateString().replace(/\//g, '-'),

        refunds_item: null,
        options: {},
        total_num: 0,
        loading: false,
        table_loading: false,
        dialog: false,
        // 费项列表
        order_items: [],
        headers: [
            { text: '', value: 'data-table-expand' },
            { text: "公司", sortable: false, value: "company.qiyejiancheng" },
            { text: "商户订单号", sortable: false, value: "_id" },
            { text: "微信支付单号", sortable: false, value: "transaction_id" },
            { text: "创建时间", sortable: false, value: "create_date" },
            { text: "支付时间", sortable: false, value: "pay_date" },
            { text: "支付供应商", sortable: false, value: "provider" },
            { text: "支付方式", sortable: false, value: "provider_pay_type" },
            { text: "类型", sortable: false, value: "type" },
            { text: "金额", sortable: false, value: "total_fee" },
            { text: "状态", sortable: false, value: "status" },
            { text: "操作", sortable: false, value: "actions" },
        ],
        excelFields: {
            "公司": "company.qiyejiancheng",
            "商户订单号": "_id",
            "微信支付单号": {
                field: "transaction_id",
                callback: (value) => {
                    return '`' + value
                }
            },
            "支付时间": {
                field: "pay_date",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                }
            },
            "支付供应商": "provider",
            "支付方式": {
                field: "provider_pay_type",
                callback: (value) => {
                    return value == 'jsapi' ? '小程序' : 'APP'
                }
            },
            "类型": {
                field: "type",
                callback: (value) => {
                    return value == 'bill' ? '物业收款' : '服务商收款'
                }
            },
            "金额": {
                field: "total_fee",
                callback: (value) => {
                    return value / 100
                }
            },
            "状态": "status"
        }
    }),
    watch: {
        options: {
            handler() {
                this.get_order_lists()
            },
            deep: true,
        },
    },
    methods: {
        // 获取订单列表
        get_order_lists() {
            this.table_loading = true
            var get_data = {
                startDate: this.startDate, endDate: this.endDate, page: this.options.page, itemsPerPage: this.options.itemsPerPage
            }
            this.$axios.get("/wechat/order/lists/all", { params: get_data })
                .then((res) => {
                    if (res.data.doc.length) {
                        this.table_loading = false
                        this.order_items = res.data.doc
                        this.total_num = res.data.total_num
                    } else {
                        this.table_loading = false
                        this.snackbar = {
                            show: true,
                            text: '数据为空或您暂未开通微信商户平台',
                            color: "error",
                        }
                    }

                })
        },
        close() {
            this.refunds_item = null
            this.dialog = false
        },
        open_dialog(item) {
            this.refunds_item = item
            this.dialog = true
        },
        refunds(refunds_item) {
            this.loading = true
            this.$axios.post("/wechat/pay/order/refunds", refunds_item)
                .then((res) => {
                    console.log(res)
                    if (res.data.message) {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "error",
                        }
                    } else {
                        this.snackbar = {
                            show: true,
                            text: res.data.status,
                            color: "success",
                        }
                    }
                    this.get_order_lists()
                    this.close()
                    this.loading = false
                })
        }
    }
}
</script>
