<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }}【访客记录】列表
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="download_qrcode" :disabled="xiaoqu ? false : true" class="mx-5">
                        访客二维码下载
                    </v-btn>
                    <v-dialog v-model="dialog" max-width="548px">
                        <v-card>
                            <v-card-title>
                                下载访客二维码
                            </v-card-title>

                            <v-card-text>
                                <div ref="qrcode_print">
                                    <img :src="qrcode_img_src" :alt="qrcode_url">
                                    <div class="grey lighten-3 font-weight-black text-h4 text-center pa-5">{{ xiaoqu ?
                                        xiaoqu.name : '' }}访客出入登记</div>
                                </div>
                            </v-card-text>

                            <v-card-actions>
                                {{ qrcode_url }}
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="downloadQrcode">
                                    下载
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <download-excel :data="items" :fields="excelFields" :name="(xiaoqu ? xiaoqu.name : '') + '访客记录'">
                        <v-btn color="success" :disabled="!items.length">
                            导出
                        </v-btn>
                    </download-excel>
                </v-card-title>
                <v-card-title class="red--text text-subtitle-1">【使用说明】入场/离场均使用相同二维码，当天首次扫描为入场，再次扫描为离场</v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading"
                        :options.sync="options" :server-items-length="total_num">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.outDate`]="{ item }">
                            {{ new Date(item.outDate).toLocaleString() }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import JsonExcel from "vue-json-excel";
import html2canvas from 'html2canvas'
import QRCode from "qrcode";
export default {
    components: {
        XiaoquLists,
        'downloadExcel': JsonExcel
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        dialog: false,
        loading: false,
        total_num: 0,
        options: {},
        items: [],
        xiaoqu: null,
        headers: [
            { text: "时间", sortable: true, value: "date" },
            { text: "姓名", sortable: false, value: "nickname" },
            { text: "电话", sortable: false, value: "phone" },
            { text: "身份证", sortable: false, value: "idcard" },
            { text: "车牌", sortable: false, value: "plate" },
            { text: "来访原因", sortable: false, value: "content" },
            { text: "离场时间", sortable: false, value: "outDate" },
        ],
        excelFields: {
            "时间": {
                field: "date",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
            "姓名": "nickname",
            "电话": "phone",
            "身份证": "idcard",
            "车牌": "plate",
            "来访原因": "content",
            "离场时间": {
                field: "outDate",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
        },
        qrcode_url: '',
        qrcode_img_src: ''
    }),
    watch: {
        dialog(val) {
            val || this.close()
        },
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_visitor_lists(this.xiaoqu._id)
                }
            },
            deep: true,
        },
    },
    methods: {

        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.get_visitor_lists(xiaoqu._id)
            } else {
                this.items = []
            }
        },

        close() {
            this.qrcode_img_src = ''
            this.qrcode_url = ''
            this.dialog = false
        },
        // 获取来访人记录
        get_visitor_lists(xiaoqu_id) {
            this.loading = true
            this.$axios.get('/qrcode/record/get_visitor_lists', { params: { xiaoqu: xiaoqu_id, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    res.data.doc.forEach(element => {
                        element.detail.forEach(element_detail => {
                            element[element_detail.name] = element_detail.value
                        })
                    });
                    setTimeout(() => {
                        this.loading = false
                        this.items = res.data.doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        },

        // 下载二维码
        download_qrcode() {
            this.qrcode_img_src = ''
            this.qrcode_url = ''
            this.dialog = true
            let _this = this
            this.qrcode_url = 'https://api.youzhuguanjia.com/qrcode/' + this.xiaoqu.company.site.site_code + '?type=visitor' + '&xiaoqu=' + this.xiaoqu._id
            // 生成普通二维码，微信扫码打开小程序
            QRCode.toDataURL(this.qrcode_url, { width: 500, margin: 2 }, function (err, url) {
                _this.qrcode_img_src = url
            })

        },
        downloadQrcode() {
            html2canvas(this.$refs.qrcode_print).then((canvas) => {
                const link = canvas.toDataURL("image/jpg");
                let a = document.createElement('a')
                a.setAttribute('download', this.xiaoqu.name + '访客出入登记')
                a.href = link
                a.click()
            })
        },
    },
};
</script>
