import qrcodeVisitorView from '../views/qrcode/visitorsView.vue'
import qrcodeSheetView from '../views/qrcode/sheetsView.vue'
import qrcodeRecordView from '../views/qrcode/recordsView.vue'


import layoutView from '../layout/default.vue'

const vote_routes = {
    path: '/qrcode',
    component: layoutView,
    children: [
        {
            path: '/qrcode/visitor',
            name: 'qrcode_visitors',
            component: qrcodeVisitorView
        },
        {
            path: '/qrcode/sheets',
            name: 'qrcode_sheets',
            component: qrcodeSheetView
        },
        {
            path: '/qrcode/records',
            name: 'qrcode_records',
            component: qrcodeRecordView
        }
    ]
}

export default vote_routes