<template>
  <div style="height:100vh;display: flex;">
    <v-navigation-drawer :mini-variant="drawer" :expand-on-hover="drawer" app dark style="padding-bottom: 73px;z-index: 999;">
      <my-navigationdrawer></my-navigationdrawer>
      <!-- 退出 -->
      <v-list nav style="position: absolute;width: 100%;bottom: 0;">
        <v-divider></v-divider>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-account-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>退出登录</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dark color="primary" app>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon>{{ !drawer ? 'mdi-menu-open' : 'mdi-menu' }}</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ user_doc.company ? user_doc.company.qiyequancheng : '企业全称' }}
        <span> - {{ user_doc.permission ? user_doc.permission.name : '角色名称' }} - </span>
        【{{ user_doc.nickname }}】
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- 运行时间 -->
      <v-chip color="green" v-if="user_doc.company">{{ '已运行 ' + ((new Date() - new
        Date(user_doc.company.kaishishijian)) / (1000 * 60 * 60 * 24) + 1).toFixed(0) + ' 天 | 剩余' + ((new
          Date(user_doc.company.daoqishijian) - new Date()) / (1000 * 60 * 60 * 24)).toFixed(0) + ' 天' }}</v-chip>

      <!-- 帮助中心 -->
      <v-btn icon @click="help">
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>

      <!-- 全屏 -->
      <v-btn icon @click="fullScreenState = !fullScreenState">
        <v-icon>{{ fullScreenState ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all' }}</v-icon>
      </v-btn>

      <!-- 用户菜单 -->
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account-arrow-down</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <!-- 用户信息 -->
          <v-list-item>
            <v-list-item-title v-text="user_doc.nickname + '【' + user_doc.username + '】'"></v-list-item-title>
          </v-list-item>
          <!-- 扫码绑定账号 -->
          <v-list-item v-if="qrcode_img_src">
            <div>
              <img :src="qrcode_img_src">
              <div class="text-center text-caption">微信扫码绑定</div>
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <!-- 修改密码 -->
          <v-menu :nudge-width="100" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon>mdi-lock-reset</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>修改资料</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title>修改账号资料</v-card-title>
              <v-card-text>
                <v-text-field v-model="user_doc.nickname" label="姓名"></v-text-field>
                <v-text-field v-model="user_doc.phone" label="电话"></v-text-field>
                <v-text-field v-model="user_doc.password" label="新密码（留空则不修改）"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="updateUserInfo">保存</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <!-- 退出登录 -->
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-account-arrow-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>退出账号</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-slot:extension>
        <v-tabs>
          <v-tab to="/home">
            首页
          </v-tab>
          <v-tab v-for="(tab, index) in $store.state.tabs" :key="index" :to="tab.link">
            {{ tab.name }}
            <v-btn @click.prevent="tab_close_btn(index)" class="tab_close" x-small icon>
              <v-icon x-small>mdi-close</v-icon>
            </v-btn>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <v-container fluid style="height: 100%;overflow: auto;" class="d-flex">
        <keep-alive>
          <router-view :key="$route.path"></router-view>
        </keep-alive>
      </v-container>
      <!-- 到期提醒对话框 -->
      <v-dialog v-if="user_doc.company" persistent v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">
            到期提醒
          </v-card-title>
          <v-card-text class="text-h6">
            系统将于
            <span class="red--text">{{ new Date(user_doc.company.daoqishijian).toLocaleDateString() }}</span>
            到期，剩余使用天数
            <span class="red--text">{{ ((new Date(user_doc.company.daoqishijian) - new Date()) / (1000 * 60 * 60 *
              24)).toFixed(0) }}</span>
            天，到期后将不能登陆使用，为不影响您的正常使用，请您及时续费<span class="red--text">({{ user_doc.company.service_money }})</span>。
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false">
              知道了
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v-overlay :value="overlay" class="text-center">
      <div class="mb-2">正在打开收费窗口</div>
      <v-progress-linear indeterminate color="white"></v-progress-linear>
    </v-overlay>
  </div>
</template>
  
<script>
import navigationdrawer from "@/components/navigation-drawer.vue";
import QRCode from "qrcode";
export default {
  name: "layoutView",
  data: () => ({
    drawer: true,
    overlay: false,
    dialog: false,
    user_doc: JSON.parse(sessionStorage.getItem('user_doc')),
    fullScreenState: false,
    qrcode_img_src: ''
  }),
  watch: {
    fullScreenState(newValue) {
      if (newValue) {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  },
  methods: {
    tab_close_btn(index) {
      // 清楚keepalive页面缓存
      console.log('当前keepalive缓存页面')
      console.log(this.$children[2].$children[0].$vnode.parent.componentInstance.cache)
      console.log('即将清除页面：' + this.$store.state.tabs[index].link)
      delete this.$children[2].$children[0].$vnode.parent.componentInstance.cache[this.$store.state.tabs[index].link]
      console.log('清除完成，剩余keepalive缓存页面')
      console.log(this.$children[2].$children[0].$vnode.parent.componentInstance.cache)

      // 如果仅剩一个页面被关闭，则跳转到首页
      if (this.$store.state.tabs.length == 1 && this.$route.path != '/home') {
        this.$router.push('/')
      } else {
        // 如果关闭的是当前页面
        if (this.$route.path == this.$store.state.tabs[index].link) {
          var new_index = 0
          // 第一种情况：如果当前页面是最后一个tab，则跳转到倒数第二个页面
          if (index == this.$store.state.tabs.length - 1) {
            new_index = this.$store.state.tabs.length - 2
          }
          // 第二种情况，如果当前页面不是最后一个，则跳转到最后的页面
          else {
            new_index = this.$store.state.tabs.length - 1
          }
          var new_link = this.$store.state.tabs[new_index].link
          this.$router.push(new_link)
        }
      }

      // 清除tab中的选定项
      this.$store.state.tabs.splice(index, 1)

    },
    // 修改用户信息
    updateUserInfo() {
      // 如果密码为空或者不存在,则删除对象属性
      if (!this.user_doc.password) {
        delete this.user_doc.password
      } else {
        this.user_doc.password = this.user_doc.password.trim()
        if (!this.user_doc.password) {
          delete this.user_doc.password
        }
      }
      // 更新
      this.$axios.post('/user/update', this.user_doc)
        .then((res) => {
          console.log(res)
          this.logout()
        })
    },
    // 退出
    logout() {
      sessionStorage.clear()
      this.$store.state.tabs = []
      this.$router.push('/login')
    },
    help() {
      window.open('https://xzrn96327j.k.topthink.com/@youzhuguanjia', '_blank')
    }
  },
  components: {
    "my-navigationdrawer": navigationdrawer,
  },
  beforeCreate() {
    // 判断是否存在token，不存在则跳转到登录页
    if (!sessionStorage.token) {
      this.$router.push("/login")
    }
  },
  created() {
    if (this.user_doc.username != 'admin') {
      var qrcode_url = 'https://api.youzhuguanjia.com/qrcode/' + this.user_doc.company.site.site_code + '?type=user&action=bind&id=' + this.user_doc._id
      QRCode.toDataURL(qrcode_url, ((err, url) => {
        this.qrcode_img_src = url
      }))
    }

    // 1s后打开蒙版
    setTimeout(() => {
      this.overlay = true
      // 2s后隐藏蒙版
      setTimeout(() => {
        if (this.user_doc.username != 'admin' && this.user_doc.company && ((new Date(this.user_doc.company.daoqishijian) - new Date()) / (1000 * 60 * 60 * 24)) < 7) {
          this.dialog = true
        }
        this.overlay = false
      }, 2000);
    }, 1000);
  }
};
</script>
<style scoped>
.tab_close {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
  