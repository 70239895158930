import vmsRecordView from '../views/vms/recordView.vue'
import vmsReportView from '../views/vms/reportView.vue'
import layoutView from '../layout/default.vue'

const vms_routes = {
  path: '/vms',
  component: layoutView,
  children: [
    {
      path: '/vms/record',
      name: 'vmsRecord',
      component: vmsRecordView
    },{
      path: '/vms/report',
      name: 'vmsReport',
      component: vmsReportView
    }
  ]
}

export default vms_routes