<template>
    <v-container fluid class="flex-grow-1 d-flex flex-column">
        <!-- 标题 -->
        {{ title }}管理
        <!-- 工具栏 -->
        <vxe-toolbar ref="toolbarRef" export print custom :refresh="{ queryMethod: getTableData }">
            <template #buttons>
                <vxe-button icon="vxe-icon-square-plus" @click="insertEvent()">新增{{ title }}</vxe-button>
            </template>
        </vxe-toolbar>
        <!-- 表格 -->
        <div class="flex-grow-1">
            <vxe-table ref="tableRef" height="auto" :loading="tableLoading" :data="tableData" :export-config="{}"
                :print-config="{}" :tree-config="{}" size="mini" show-overflow show-footer
                :footer-method="footerMethod">
                <vxe-column type="checkbox" width="60"></vxe-column>
                <vxe-column type="seq" width="60"></vxe-column>
                <vxe-column field="_id" title="ID"></vxe-column>
                <vxe-column field="company.qiyejiancheng" title="企业"></vxe-column>
                <vxe-column field="permission.name" title="权限"></vxe-column>
                <vxe-column field="username" title="账号"></vxe-column>
                <vxe-column field="nickname" title="姓名"></vxe-column>
                <vxe-column field="phone" title="电话"></vxe-column>
                <vxe-column field="wx_user" title="微信"></vxe-column>
                <vxe-column field="status" title="状态">

                    <template #default="{ row }">
                        <vxe-switch v-model="row.status" open-label="已启用" close-label="已禁用"
                            @change="change_status(row)"></vxe-switch>
                    </template>
                </vxe-column>
                <vxe-column title="操作">
                    <template #default="{ row }">
                        <vxe-button type="text" icon="vxe-icon-edit" @click="editEvent(row)"></vxe-button>
                        <!-- 下载二维码 -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <vxe-button v-bind="attrs" v-on="on" type="text"
                                    icon="vxe-icon-cloud-upload"></vxe-button>
                            </template>
                            <div class="my-2">
                                <v-img width="250" :src="toDataURL(row)"></v-img>
                            </div>
                        </v-tooltip>
                        <!-- <vxe-button type="text" icon="vxe-icon-delete" @click="removeEvent(row)"></vxe-button> -->
                    </template>
                </vxe-column>
            </vxe-table>
        </div>
        <!-- 表单 -->
        <vxe-modal v-model="showEditModal" :title="selectRowData ? '编辑&保存' : '新增&保存'" width="800"
            :loading="editModalLoading" resize destroy-on-close>

            <template #default>
                <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100"
                    @submit="submitEvent">
                    <vxe-form-item field="_id" title="ID" :span="12"></vxe-form-item>
                    <vxe-form-item field="company" title="企业" :span="12">
                        <template #default="{ data }">
                            <vxe-select v-model="data.company._id" placeholder="企业">
                                <vxe-option v-for="item in companyList" :key="item._id" :value="item._id"
                                    :label="item.qiyejiancheng"></vxe-option>
                            </vxe-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="username" title="账号" :span="12">

                        <template #default="{ data }">
                            <vxe-input v-model="data.username" placeholder="请输入账号"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="password" title="密码" :span="12">

                        <template #default="{ data }">
                            <vxe-input v-model="data.password" placeholder="请输入密码"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="nickname" title="姓名" :span="12">

                        <template #default="{ data }">
                            <vxe-input v-model="data.nickname" placeholder="请输入姓名"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="phone" title="电话" :span="12">

                        <template #default="{ data }">
                            <vxe-input v-model="data.phone" placeholder="请输入电话"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="status" title="状态" :span="12">

                        <template #default="{ data }">
                            <vxe-switch v-model="data.status" open-label="已启用" close-label="已禁用"></vxe-switch>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="permission" title="角色" :span="24">
                        <template #default="{ data }">
                            <vxe-radio-group v-model="data.permission._id">
                                <vxe-radio v-for="item in permissionList" :key="item._id" :label="item._id"
                                    :content="item.name"></vxe-radio>
                            </vxe-radio-group>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item :align="'right'" title-align="left" :span="24">

                        <template #default>
                            <vxe-button type="submit">提交</vxe-button>
                        </template>
                    </vxe-form-item>
                </vxe-form>
            </template>
        </vxe-modal>
    </v-container>
</template>

<script>
import { VXETable } from 'vxe-table'
import QRCode from "qrcode";
export default {
    data() {
        return {
            title: '账号',
            apiUrl: {
                findUrl: '/user/lists',
                createUrl: '/user/add',
                updateUrl: '/user/update',
            },
            // toolbar配置
            // table配置
            tableLoading: false,
            tableData: [],
            sumFields: [],// 汇总的字段
            // form配置
            formData: {
                username: '',
                password: '',
                nickname: '',
                phone: '',
                permission: {},
                company: {},
                status: true,
            },
            defaultFormData: {
                username: '',
                password: '',
                nickname: '',
                phone: '',
                permission: {},
                company: {},
                status: true,
            },
            formRules: {
                username: [
                    { required: true, message: '请输入账号' }
                ],
                password: [
                    { required: true, message: '请输入密码' }
                ],
                permission: [
                    {
                        validator({ itemValue }) {
                            // 自定义校验
                            if (!itemValue._id) {
                                return new Error('角色必选')
                            }
                        }
                    }
                ],
                company: [
                    {
                        validator({ itemValue }) {
                            // 自定义校验
                            if (!itemValue._id) {
                                return new Error('企业必选')
                            }
                        }
                    }
                ]
            },
            selectRowData: null,
            showEditModal: false,
            editModalLoading: false,
            // 企业
            companySelected: '',
            companyList: [],
            // 角色
            permissionSelected: '',
            permissionList: [],
        }
    },
    mounted() {
        // 将表格和工具栏进行关联
        const $table = this.$refs.tableRef
        const $toolbar = this.$refs.toolbarRef
        if ($table && $toolbar) {
            $table.connect($toolbar)
        }
        this.getTableData()
        this.getCompanyList()
        this.getPermissionList()
    },
    methods: {
        // 获取企业列表
        getCompanyList() {
            this.$axios.get('/company/getlist')
                .then(res => {
                    this.companyList = res.data
                })
        },
        // 选择企业触发事件
        companyChange(e) {
            console.log(e)
            this.getTableData()
        },
        // 获取角色列表
        getPermissionList() {
            this.$axios.get('/permission')
                .then(res => {
                    this.permissionList = res.data
                })
        },
        // 获取表格数据
        getTableData() {
            this.tableLoading = true
            var params = {}
            if (this.companySelected) {
                params.company = this.companySelected
            }
            this.$axios.get(this.apiUrl.findUrl, { params: params })
                .then(res => {
                    this.tableData = res.data
                    this.tableLoading = false
                    VXETable.modal.message({ content: '请求成功', status: 'success' })
                }).catch(error => {
                    VXETable.modal.message({ content: error.message, status: 'error' })
                    this.tableLoading = false
                })
        },
        // 汇总表尾需要sum的字段
        footerMethod({ columns, data }) {
            var sum = 0
            return [
                columns.map((column, columnIndex) => {
                    if (columnIndex === 0) {
                        return '汇总'
                    }
                    if (this.sumFields.includes(column.field)) {
                        data.forEach(item => {
                            sum += Number(item[column.field])
                        })
                        return sum
                    }
                    return '/'
                })
            ]
        },
        // 点击【新增】事件
        insertEvent() {
            this.selectRowData = null
            this.formData = Object.assign({}, this.defaultFormData)
            this.showEditModal = true
        },
        // 点击【编辑】事件
        editEvent(row) {
            if (row.username === 'admin') {
                VXETable.modal.alert({ content: '超级管理员禁止编辑' })
                return
            }
            this.formData = Object.assign({}, row)
            this.selectRowData = row
            this.showEditModal = true
        },
        change_status(item) {
            // 更新状态
            if (item.username == 'admin') {
                VXETable.modal.alert({ content: '超级管理员禁止编辑' })
            } else {
                this.$axios.post('/user/update', item)
                    .then(() => {
                        VXETable.modal.alert({ content: '更新成功' })
                    })
                    .catch(error => {
                        console.log(error)
                        VXETable.modal.alert({ content: error })
                    })
            }
        },
        // 提交数据
        submitEvent() {
            const $table = this.$refs.tableRef
            this.editModalLoading = true
            this.$axios.post(this.selectRowData ? this.apiUrl.updateUrl : this.apiUrl.createUrl, this.formData)
                .then(res => {
                    if (this.selectRowData) {
                        VXETable.modal.message({ content: '保存成功', status: 'success' })
                        Object.assign(this.selectRowData, res.data)
                    } else {
                        VXETable.modal.message({ content: '新增成功', status: 'success' })
                        $table.insert(res.data)
                    }
                    this.editModalLoading = false
                    this.showEditModal = false
                }).catch(error => {
                    VXETable.modal.message({ content: error.message, status: 'error' })
                    this.editModalLoading = false
                })
        },
        // 生成二维码图片地址
        toDataURL(item) {
            var result = ''
            this.qrcode_url = 'https://api.youzhuguanjia.com/qrcode/' + (item.company && item.company.site && item.company.site.site_code) + '?type=user&action=bind&id=' + item._id
            QRCode.toDataURL(this.qrcode_url, ((err, url) => {
                result = url
            }))
            return result
        }
    }
}
</script>

<style scoped></style>
