<template>
    <v-row>
        <ShopLists @select_shop="select_shop"></ShopLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="flex:1;overflow: auto;">
                <v-card-title>
                    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
                    </v-snackbar>
                    商品分类
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="!shop">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">编辑</span>
                            </v-card-title>

                            <v-card-text>
                                <v-text-field v-model="categoryItem.sort" label="排序" type="number"></v-text-field>
                                <v-text-field v-model="categoryItem.name" label="分类名称"></v-text-field>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="category_items" :loading="loading">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon color="primary" class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="error" class="mr-2" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import ShopLists from "@/components/shop-lists.vue";
export default {
    components: {
        ShopLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: ''
        },
        loading: false,
        dialog: false,
        shop: undefined,
        headers: [
            { text: '商家', value: 'shop.name' },
            { text: '排序', value: 'sort', },
            { text: '名称', value: 'name', },
            { text: '操作', align: 'center', value: 'actions' },
        ],
        category_items: [],
        categoryItem: {
            sort:0,
            shop:'',
            name:''
        }
    }),

    watch: {
        dialog(val) {
            if (!val) {
                this.close()
            }
        }
    },

    methods: {
        // 子组件事件 选择小区
        select_shop(shop) {
            this.shop = shop
            if (shop) {
                // 获取列表
                this.get_category_lists()
            } else {
                this.category_items = []
            }
        },

        get_category_lists() {
            this.category_items = []
            this.loading = true
            this.$axios.get('/shop/category/lists', { params: { shop: this.shop._id } })
                .then((res) => {
                    this.category_items = res.data
                    this.loading = false
                })
        },

        editItem(item) {
            this.categoryItem = item
            this.dialog = true
        },
        deleteItem(item){
            this.$axios.post('/shop/category/delete', item)
                .then((res) => {
                    this.close()
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
        },
        close() {
            this.dialog = false
            this.get_category_lists()
            this.categoryItem = {sort:0,shop:'',name:''}
        },
        // 保存
        save() {
            if (this.categoryItem._id) {
                // 更新
                this.$axios.post('/shop/category/update', this.categoryItem)
                .then((res) => {
                    this.close()
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
            } else {
                this.categoryItem.shop = this.shop
                // 添加
                this.$axios.post('/shop/category/create', this.categoryItem)
                    .then((res) => {
                        this.close()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            }
        },
    },
}
</script>