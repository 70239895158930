import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '../views/LoginView.vue'

import layoutView from '../layout/default.vue'
import homeView from '../views/homeView.vue'

import BigDataView from '../views/BigDataView.vue'
// import NotFoundView from '../views/NotFoundView.vue'
import xitongguanli_routes from './xitongguanli.js' //系统管理
import fangchanguanli_routes from './fangchanguanli.js'  //房产管理
import account_routes from './account.js'  //账号管理
import service_routes from './service.js'  //小区服务
import qrcode_routes from './qrcode.js'  //小区服务
import vms_routes from './vms.js'  //物料管理
import bill_routes from './bill.js' //费用管理
import weixin_routes from './weixin' //微信
import shop_routes from './shop' //商家

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/bigdata',
    name: 'bigdata',
    component: BigDataView
  },
  {
    path: '/',
    name: 'layoutView',
    component: layoutView,
    redirect: '/home',
    children: [
      ...[{ path: '/home', name: 'home', component: homeView }],
      // 直接将【系统管理】模块里的子节点加载到这里(...将数组展开)
      ...xitongguanli_routes.children,
      ...fangchanguanli_routes.children,
      ...account_routes.children,
      ...bill_routes.children,
      ...weixin_routes.children,
      ...service_routes.children,
      ...vms_routes.children,
      ...qrcode_routes.children,
      ...shop_routes.children,
      // // 未找到页面
      // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView }
    ]
  },
  // 当然也可以加载到父节点（这时要求xitongguanli.js中/xitongguanli页面的组件为layoutView）
  // xitongguanli_routes,
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由全局前置守卫，当会话存储中不存在token时都跳转到登录页
// geocoder腾讯地图请求地址api中（本地代理请求）
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'bigdata' && !sessionStorage.token && !to.path.includes('geocoder')) next({ name: 'login' })
  else next()
})

export default router
