import axios from 'axios'
import jsonp from 'jsonp'

const http = axios.create({
  baseURL: 'https://api.youzhuguanjia.com', // 所有请求的公共地址部分
  // baseURL: 'http://localhost:3000', // 所有请求的公共地址部分
  timeout: 60000, // 请求超时时间 这里的意思是当请求时间超过60秒还未取得结果时 提示用户请求超时
})

http.jsonp = jsonp
// 添加请求拦截器
http.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (sessionStorage.getItem("token")) {
    config.headers['Authorization'] = sessionStorage.getItem("token");
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default http