<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 批量催缴
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="!xiaoqu" @click="search" class="mx-5" :loading="loading">
                        查询欠费房屋
                    </v-btn>
                    <download-excel :data="items" :fields="excelFields" :name="(xiaoqu ? xiaoqu.name : '') + '催缴记录'">
                        <v-btn color="success" :disabled="!items.length" :loading="loading">
                            导出
                        </v-btn>
                    </download-excel>
                </v-card-title>
                <v-card-title class="flex-nowrap">
                    <!-- 费项下拉框 -->
                    <v-select multiple :disabled="xiaoqu ? false : true" :items="feixiang_items" label="费项"
                        :item-text="(item) => item.mingcheng + item.danjia" item-value="_id" v-model="feixiang_selected"
                        outlined dense hide-details class="mr-5">
                        <template v-slot:selection="{ item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item.mingcheng }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ feixiang_selected.length - 1 }} 其他)
                            </span>
                        </template>
                    </v-select>

                    <!-- 楼栋下拉框 -->
                    <v-select :disabled="xiaoqu ? false : true" :items="loudong_items" label="楼栋" item-text="name"
                        item-value="_id" v-model="loudong_selected" outlined dense hide-details class="mr-5"
                        @change="change_loudong"></v-select>

                    <!-- 单元下拉框 -->
                    <v-select :disabled="loudong_selected != 0 ? false : true" :items="danyuan_items" label="单元"
                        item-text="name" item-value="_id" v-model="danyuan_selected" outlined dense hide-details
                        class="mr-5"></v-select>
                    <!-- 日期之前 -->
                    <V-menu v-model="beforeDateMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="beforeDate" label="费用在日期之前" outlined dense readonly v-bind="attrs"
                                v-on="on" hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="beforeDate" @input="beforeDateMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>

                </v-card-title>
                <v-card-title class="red--text text-subtitle-1">
                    当前企业余额 {{ money }} 元<br>
                    计费说明：语音通知（0.2元/条）、短信通知（0.1元/条）。<br>
                    温馨提醒：尊敬的业主【XX】您好，您有管理服务费【XXXX】元待缴纳，请尽快到【XXXX】物业服务中心缴清费用，感谢您的支持。
                    <v-spacer></v-spacer>
                    <v-btn color="error" :disabled="!(xiaoqu && item_selected.length)" @click="callPhone" class="mx-5"
                        :loading="loading">
                        电话通知
                    </v-btn>
                    <!-- <v-btn color="error" :disabled="!(xiaoqu && item_selected.length)" @click="callPhone"
                        :loading="loading">
                        短信催缴
                    </v-btn> -->
                </v-card-title>
                <v-card-text ref="table">
                    <v-data-table v-model="item_selected" :headers="headers" :items="items" item-key="_id.fangwu"
                        :show-select="showselect" class="elevation-0" :loading="loading" disable-sort disable-pagination
                        hide-default-footer height="50vh">
                        <template v-slot:[`item.sumZong`]="{ item }">
                            {{ Math.round(item.sumZong * 100) / 100 }}
                        </template>
                        <template v-slot:[`item.result`]="{ item }">
                            {{ item.result }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import JsonExcel from "vue-json-excel";
export default {
    components: {
        XiaoquLists,
        'downloadExcel': JsonExcel
    },
    data: () => ({
        xiaoqu: null,
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        totalRecord: 0,
        loading: false,
        // 下拉框数组
        feixiang_items: [],
        loudong_items: [{ _id: 0, name: '全部' }],
        danyuan_items: [{ _id: 0, name: '全部' }],

        // 下拉框选择项
        feixiang_selected: [],
        loudong_selected: 0,
        danyuan_selected: 0,
        money: 0,
        // 费用台账选择项
        item_selected: [],
        showselect: true,
        beforeDateMenu: false,
        beforeDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        headers: [
            { text: '楼栋', value: 'loudongObj.name', },
            { text: '单元', value: 'danyuanObj.name' },
            { text: '房屋', value: 'fangwuObj.name' },
            { text: '业主', value: 'fangwuObj.yezhu' },
            { text: '电话', value: 'fangwuObj.phone' },
            { text: "总计", value: "sumZong" },
            { text: "结果", value: "result" },
        ],
        // 费用台账数组列表
        items: [],
        excelFields: {
            "楼栋": "loudongObj.name",
            "单元": "danyuanObj.name",
            "房号": "fangwuObj.name",
            "业主": "fangwuObj.yezhu",
            "电话": "fangwuObj.phone",
            "总计": "sumZong",
            "结果": "result"
        }
    }),
    methods: {

        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            // 清除
            this.feixiang_items = [{ _id: 0, mingcheng: '全部' }]
            this.feixiang_selected = []
            this.loudong_items = [{ _id: 0, name: '全部' }]
            this.loudong_selected = 0
            this.danyuan_items = [{ _id: 0, name: '全部' }]
            this.danyuan_selected = 0

            this.items = []
            this.item_selected = []

            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                // 获取费项列表
                this.$axios.get('/feixiang/lists', { params: { xiaoqu: xiaoqu._id } })
                    .then((res) => {
                        this.feixiang_items = res.data
                    })

                // 获取楼栋列表
                this.$axios.get("/loudong/getlist", { params: { xiaoqu: xiaoqu._id } })
                    .then((res) => {
                        this.loudong_items = [{ _id: 0, name: '全部' }, ...res.data]
                    })
            }
        },

        // 选择楼栋
        change_loudong() {
            this.danyuan_items = [{ _id: 0, name: '全部' }]
            this.danyuan_selected = 0
            if (this.loudong_selected != 0) {
                this.$axios.get('/danyuan/getlist', { params: { loudong: this.loudong_selected } })
                    .then((res) => {
                        this.danyuan_items = [{ _id: 0, name: '全部' }, ...res.data]
                    })
            }
        },

        // 查询
        search() {
            this.loading = true
            this.items = []
            this.item_selected = []
            var post_data = {
                xiaoqu: this.xiaoqu,
                feixiang: this.feixiang_selected,
                loudong: this.loudong_selected,
                danyuan: this.danyuan_selected,
                beforeDate: this.beforeDate,
            }
            this.$axios.post('/taizhang/push', post_data)
                .then((res) => {
                    this.loading = false
                    this.items = res.data
                })
        },
        callPhone() {
            this.fun()
        },
        async fun() {
            this.loading = true
            // 重置table的scroll
            var dom_table_parent = this.$refs.table.getElementsByClassName('v-data-table__wrapper')[0]
            dom_table_parent.scrollTop = 0
            for (let index = 0; index < this.item_selected.length; index++) {
                // 自动滚动，保留5条
                dom_table_parent.scrollTop = index < 5 ? 0 : (index - 4) * 48

                const element = this.item_selected[index];
                element.result = element.fangwuObj.phone + '提醒中...'
                const result = await this.$axios.post('/feiyong/push_phone', { total: element.sumZong, xiaoquObj: this.xiaoqu, fangwuObj: element.fangwuObj })

                // 重新设置table的key(_id.fangwu)后才会实时更新
                var _id = element._id.fangwu
                element._id.fangwu = ''
                element.result = result.data.message
                element._id.fangwu = _id

                if ((index + 1) == this.item_selected.length) {
                    this.loading = false
                }
            }
        }
    },
};
</script>
