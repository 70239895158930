import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        tabs: []
    },
    mutations: {
        // 添加tab
        add(state,new_tab) {
            state.tabs.push(new_tab)
        },
        // 删除tab
        // 在页面中直接修改的store.state.tabs
    }
})

export default store