<template>
    <v-row>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>
                    {{ xiaoqu ? xiaoqu.name : '' }} 【饱和已收】按费项统计
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="!xiaoqu" @click="search" :loading="loading">
                        查询
                    </v-btn>
                </v-card-title>
                <v-card-title class="red--text text-subtitle-1">
                    【指标说明】统计按面积、按户数的费项
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="items" item-key="_id._id" class="elevation-0"
                        :loading="loading" disable-pagination hide-default-footer style="height:100%;overflow: auto;">
                        <!-- 历年欠收/已收/应收/欠费率 -->
                        <template v-slot:[`item.sumLastYear`]="{ item }">
                            <div>
                                <span>{{ toWan(item.baoheqianshou.sumLastYear) }}</span>
                                <span>{{ toWan(item.baoheyishou.sumLastYear) }}</span>
                                <span class="green--text">+{{ toWan(item.yishou.sumLastYear) }}</span>
                                <span>{{ toWan(item.baoheyishou.sumLastYear + item.baoheqianshou.sumLastYear +
                                    item.yishou.sumLastYear) }}</span>
                            </div>
                            <div class="yellow lighten-4 overflow-hidden">
                                <div class="text-right red" :style="'width:' + toPercent(item.baoheqianshou.sumLastYear, (item.baoheyishou.sumLastYear
                                    + item.baoheqianshou.sumLastYear + item.yishou.sumLastYear)) + ';'">
                                    <span style="display: inline-block;">{{ toPercent(item.baoheqianshou.sumLastYear,
                                        (item.baoheyishou.sumLastYear
                                            + item.baoheqianshou.sumLastYear + item.yishou.sumLastYear)) }}</span>
                                </div>
                            </div>
                        </template>
                        <!-- '当年已收/应收/完成率 -->
                        <template v-slot:[`item.sumThisYear`]="{ item }">
                            <div>
                                <span>{{ toWan(item.baoheyishou.sumThisYear) }}</span>
                                <span class="green--text">+{{ toWan(item.yishou.sumThisYear) }}</span>
                                <span>{{ toWan(item.yingshouSum) }}</span>
                            </div>
                            <div class="yellow lighten-4 overflow-hidden">
                                <div class="text-right green"
                                    :style="'width:' + toPercent(item.baoheyishou.sumThisYear, item.yingshouSum) + ';'">
                                    <span style="display: inline-block;">{{ toPercent(item.baoheyishou.sumThisYear +
                                        item.yishou.sumThisYear,
                                        item.yingshouSum) }} </span>
                                </div>
                            </div>
                        </template>
                        <!-- 来年预收 -->
                        <template v-slot:[`item.sumNextYear`]="{ item }">
                            <div>
                                <span>{{ toWan(item.baoheyishou.sumNextYear) }}</span>
                                <span class="green--text">+{{ toWan(item.yishou.sumNextYear) }}</span>
                                <span>{{ toWan(item.yingshouSum) }}</span>
                            </div>
                            <div class="yellow lighten-4 overflow-hidden">
                                <div class="text-right green"
                                    :style="'width:' + toPercent(item.baoheyishou.sumNextYear + item.yishou.sumNextYear, item.yingshouSum) + ';'">
                                    <span style="display: inline-block;">{{ toPercent(item.baoheyishou.sumNextYear +
                                        item.yishou.sumNextYear,
                                        item.yingshouSum) }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`body.append`]="{ headers }">
                            <tr>
                                <td v-for="(header, i) in headers" :key="i">
                                    <div v-if="header.text == '费项名称'">
                                        汇总
                                    </div>
                                    <div v-if="header.text == '历年欠收/已收/应收/欠费率'">
                                        <div>
                                            <span>{{ toWan(baoheqianshou.sumLastYear) }}</span>
                                            <span>{{ toWan(baoheyishou.sumLastYear) }}</span>
                                            <span class="green--text">+{{ toWan(yishou.sumLastYear) }}</span>
                                            <span>{{ toWan(baoheyishou.sumLastYear + baoheqianshou.sumLastYear +
                                                yishou.sumLastYear)
                                            }}</span>
                                        </div>
                                        <div class="yellow lighten-4 overflow-hidden">
                                            <div class="text-right red" :style="'width:' + toPercent(baoheqianshou.sumLastYear, (baoheyishou.sumLastYear
                                                    + baoheqianshou.sumLastYear + yishou.sumLastYear)) + ';'">
                                                <span style="display: inline-block;">{{
                                                    toPercent(baoheqianshou.sumLastYear,
                                                        (baoheyishou.sumLastYear
                                                            + baoheqianshou.sumLastYear + yishou.sumLastYear)) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="header.text == '当年已收/应收/完成率'">
                                        <div>
                                            <span>{{ toWan(baoheyishou.sumThisYear) }}</span>
                                            <span class="green--text">+{{ toWan(yishou.sumThisYear) }}</span>
                                            <span>{{ toWan(yingshouSum) }}</span>
                                        </div>
                                        <div class="yellow lighten-4 overflow-hidden">
                                            <div class="text-right green"
                                                :style="'width:' + toPercent(baoheyishou.sumThisYear + yishou.sumThisYear, yingshouSum) + ';'">
                                                <span style="display: inline-block;">{{
                                                    toPercent(baoheyishou.sumThisYear + yishou.sumThisYear,
                                                        yingshouSum) }} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="header.text == '来年预收/应收/完成率'">
                                        <div>
                                            <span>{{ toWan(baoheyishou.sumNextYear) }}</span>
                                            <span class="green--text">+{{ toWan(yishou.sumNextYear) }}</span>
                                            <span>{{ toWan(yingshouSum) }}</span>
                                        </div>
                                        <div class="yellow lighten-4 overflow-hidden">
                                            <div class="text-right green"
                                                :style="'width:' + toPercent(baoheyishou.sumNextYear + yishou.sumNextYear, yingshouSum) + ';'">
                                                <span style="display: inline-block;">{{ toPercent(baoheyishou.sumNextYear +
                                                    yishou.sumNextYear,
                                                    yingshouSum) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <!-- 修正 -->
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn small color="error" elevation="0"
                                @click="dialog = true; changeYishous(item._id._id)">修正</v-btn>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-card :loading="loading">
                            <v-card-title>
                                <span class="text-h5">修正{{ dialogValue && dialogValue.mingcheng +
                                    dialogValue.danjia }}收入</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="primary" @click="add">
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-title>
                                <div class="red--text">录入暂未在优住管家收取的年度费用汇总</div>
                                <div class="red--text">点击右上角 + 号新增</div>
                            </v-card-title>
                            <v-card-text v-if="dialogValue && dialogValue.yishous">
                                <v-form ref="form">
                                    <v-container fluid>
                                        <v-row v-for="(item, index) in dialogValue.yishous" :key="index">
                                            <v-col cols="12" sm="5" md="5">
                                                <v-text-field v-model="item.year" type="number" label="年度" dense
                                                    :rules="[value => !!value || '必填', value => !/[\.]/.test(value) || '整数', value => value.toString().length == 4 || '年份错误']">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5">
                                                <v-text-field v-model="item.yishou" type="number" suffix="元" label="收入"
                                                    dense :rules="[value => !!value || '必填']"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-btn icon color="error" @click="del(index)">
                                                    <v-icon>mdi-minus-box</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :loading="loading" color="blue darken-1" text @click="close">
                                    取消
                                </v-btn>
                                <v-btn :loading="loading" color="blue darken-1" text @click="save">
                                    保存
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        xiaoqu: null,
        loading: false,
        dialog: false,
        dialogValue: null,
        headers: [
            { text: '费项名称', value: '_id.mingcheng' },
            { text: '费项单价', value: '_id.danjia' },
            { text: '历年欠收/已收/应收/欠费率', value: 'sumLastYear' },
            { text: '当年已收/应收/完成率', value: 'sumThisYear' },
            { text: "来年预收/应收/完成率", value: "sumNextYear" },
            { text: "操作", value: "action" },
        ],
        // 费用台账数组列表
        items: [],
        // 汇总
        yingshouSum: 0,
        baoheyishou: {
            sumLastYear: 0,
            sumThisYear: 0,
            sumNextYear: 0
        },
        baoheqianshou: {
            sumLastYear: 0,
            sumThisYear: 0,
            sumNextYear: 0
        },
        yishou: {
            sumLastYear: 0,
            sumThisYear: 0,
            sumNextYear: 0
        }
    }),
    watch: {
        dialog(val) {
            val || this.close()
        }
    },
    methods: {

        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            // 清除
            this.int()
            this.xiaoqu = xiaoqu
        },
        int() {
            this.items = []
            this.yingshouSum = 0
            this.baoheyishou = {
                sumLastYear: 0,
                sumThisYear: 0,
                sumNextYear: 0
            }
            this.baoheqianshou = {
                sumLastYear: 0,
                sumThisYear: 0,
                sumNextYear: 0
            }
            this.yishou = {
                sumLastYear: 0,
                sumThisYear: 0,
                sumNextYear: 0
            }
        },
        // 查询
        async search() {
            this.loading = true
            this.int()
            var post_data = {
                xiaoqu: this.xiaoqu._id
            }
            // 先获取各费项的年度饱和应收
            const baoheyingshou = await this.$axios.post('/feixiang/report/get_by_xiaoqu', post_data)

            // 再获取各费项历年、本年、来年全量饱和欠收、已收
            const baoheyishou_and_baoheqianshou = await this.$axios.post('/feiyong/report/get_by_xiaoqu_and_year', post_data)

            baoheyingshou.data.forEach(element => {
                element.baoheyishou_and_baoheqianshou = baoheyishou_and_baoheqianshou.data.find((val) => val._id.feixiang == element._id._id)
                element.baoheyishou_and_baoheqianshou.billData.forEach(baoheyishou_and_baoheqianshou_item => {
                    // 饱和已收
                    if (baoheyishou_and_baoheqianshou_item.status) {
                        element.baoheyishou = {
                            sumLastYear: baoheyishou_and_baoheqianshou_item.sumLastYear ? baoheyishou_and_baoheqianshou_item.sumLastYear : 0,
                            sumThisYear: baoheyishou_and_baoheqianshou_item.sumThisYear ? baoheyishou_and_baoheqianshou_item.sumThisYear : 0,
                            sumNextYear: baoheyishou_and_baoheqianshou_item.sumNextYear ? baoheyishou_and_baoheqianshou_item.sumNextYear : 0
                        }
                        // 饱和欠收
                    } else {
                        element.baoheqianshou = {
                            sumLastYear: baoheyishou_and_baoheqianshou_item.sumLastYear ? baoheyishou_and_baoheqianshou_item.sumLastYear : 0,
                            sumThisYear: baoheyishou_and_baoheqianshou_item.sumThisYear ? baoheyishou_and_baoheqianshou_item.sumThisYear : 0,
                            sumNextYear: baoheyishou_and_baoheqianshou_item.sumNextYear ? baoheyishou_and_baoheqianshou_item.sumNextYear : 0
                        }
                    }
                })
                // 有可能为空，初始化一下
                element.baoheyishou = element.baoheyishou ? element.baoheyishou : {
                    sumLastYear: 0,
                    sumThisYear: 0,
                    sumNextYear: 0
                }
                // 有可能为空，初始化一下
                element.baoheqianshou = element.baoheqianshou ? element.baoheqianshou : {
                    sumLastYear: 0,
                    sumThisYear: 0,
                    sumNextYear: 0
                }

                // 添加修正数据
                element.yishou = {
                    sumLastYear: 0,
                    sumThisYear: 0,
                    sumNextYear: 0
                }
                if (element._id.yishous) {
                    element._id.yishous.forEach(item => {
                        if (item.year < new Date().getFullYear()) {
                            element.yishou.sumLastYear += item.yishou ? item.yishou : 0
                        } else if (item.year == new Date().getFullYear()) {
                            element.yishou.sumThisYear += item.yishou ? item.yishou : 0
                        } else {
                            element.yishou.sumNextYear += item.yishou ? item.yishou : 0
                        }
                    })
                }
            });
            this.items = baoheyingshou.data
            this.items.forEach(item => {
                // 饱和应收汇总
                this.yingshouSum += item.yingshouSum
                // 饱和已收汇总
                this.baoheyishou.sumLastYear += item.baoheyishou.sumLastYear
                this.baoheyishou.sumThisYear += item.baoheyishou.sumThisYear
                this.baoheyishou.sumNextYear += item.baoheyishou.sumNextYear
                // 饱和欠收汇总
                this.baoheqianshou.sumLastYear += item.baoheqianshou.sumLastYear
                this.baoheqianshou.sumThisYear += item.baoheqianshou.sumThisYear
                this.baoheqianshou.sumNextYear += item.baoheqianshou.sumNextYear
                // 修正收入
                this.yishou.sumLastYear += item.yishou.sumLastYear
                this.yishou.sumThisYear += item.yishou.sumThisYear
                this.yishou.sumNextYear += item.yishou.sumNextYear
            })
            this.loading = false
        },
        changeYishous(id) {
            this.loading = true
            this.$axios.get('/feixiang/detail', { params: { id: id } })
                .then(res => {
                    console.log(res.data)
                    this.loading = false
                    this.dialogValue = res.data
                })
        },
        add() {
            this.dialogValue.yishous.push({ year: '', yishou: 0 })
        },
        del(index) {
            this.dialogValue.yishous.splice(index, 1)
        },
        close() {
            this.dialog = false
            this.dialogValue = null
            this.$refs.form.resetValidation()
        },
        save() {
            if (this.$refs.form.validate()) {
                // 如果校验通过则修正收入
                this.loading = true
                this.$axios.post('/feixiang/update', this.dialogValue)
                    .then(() => {
                        this.loading = false
                        this.close()
                        this.search()
                    })
            }
        },
        toWan(num) {
            return '￥' + (Math.round(num * 100) / 1000000).toFixed(2) + 'W '
        },
        toPercent(num, total) {
            return total ? (Math.round(num / total * 10000) / 100.00 + '%') : '0%'
        }
    },
};
</script>
