<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height: 300px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="onCreated" @onChange="onChange" />
    </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default ({
    components: { Editor, Toolbar },
    props: ['htmlContent'],
    data() {
        return {
            editor: null,
            html: this.htmlContent,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        server: 'https://api.youzhuguanjia.com/uploads',
                        fieldName: 'uploads',
                        maxNumberOfFiles: 1,
                        headers: {
                            Authorization: sessionStorage.getItem("token")
                        },
                        customInsert(res, insertFn) {
                            console.log(res)
                            // res 即服务端的返回结果
                            // 从 res 中找到 url alt href ，然后插入图片
                            insertFn('https://api.youzhuguanjia.com/'+res.path)
                        },
                    }
                }
            },
            mode: 'simple', // default or 'simple'
        }
    },
    watch: {
        // 如果 `html` 发生改变，这个函数就会运行
        htmlContent() {
            this.html = this.htmlContent
        },
    },
    mounted() {

    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        onChange() {
            this.$emit('htmlChange', this.html)
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>