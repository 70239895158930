<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <v-col cols="12" sm="3" md="3" style="height:100%">
            <v-card height="100%" class="d-flex flex-column">
                <v-card-title>楼栋列表</v-card-title>
                <v-card-text class="d-flex flex-column" style="overflow:auto;">
                    <v-select :items="xiaoqu_items" item-text="name" item-value="_id" v-model="select_xiaoqu" label="选择小区"
                        single-line @change="change_xiaoqu"></v-select>
                    <div class="d-flex flex-column" style="overflow:auto;">
                        <!-- 判断小区对象是否为空 -->
                        <v-progress-linear v-show="Object.keys(select_xiaoqu).length != 0 && loudong_items.length == 0"
                            indeterminate color="primary"></v-progress-linear>
                        <v-alert type="error" outlined dense v-show="Object.keys(select_xiaoqu).length == 0">您还没有选择小区！
                        </v-alert>
                        <v-treeview :items="loudong_items" item-key="_id" item-disabled="locked" transition
                            :active.sync="active" open-on-click activatable dense>
                            <template v-slot:prepend="{ item }">
                                <v-icon v-if="item.xiaoqu" small>
                                    mdi-city
                                </v-icon>
                                <v-icon v-if="item.loudong" small>
                                    mdi-door-open
                                </v-icon>
                            </template>
                        </v-treeview>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="9" md="9" height="100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>{{ fangwu_items.length }} 条
                    <v-btn class="ml-5" color="success" :disabled="btn_save_all_disabled" @click="btn_save">保 存
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5" color="error" :disabled="select_fangwu.length == 0 ? true : false"
                        @click="btn_del">删 除</v-btn>
                    <v-btn class="mr-5" color="primary" @click="btn_add_single"
                        :disabled="active.length == 0 ? true : false">单个添加</v-btn>
                    <v-dialog v-model="dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" :disabled="active.length == 0 ? true : false" v-bind="attrs"
                                v-on="on">批量添加
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                批量生成
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.start_louceng" type="number" label="开始楼层">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.end_louceng" type="number" label="结束楼层">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.start_fanghao" type="number" label="起始房号">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.end_fanghao" type="number" label="结束房号">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.mianji" type="number" label="默认面积">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.lianjiefu" label="连接符"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3">
                                            <v-text-field v-model="editedItem.biaoshi" label="标识字"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <span class="primary--text">数量：{{ count }} 个</span>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="btn_add_multiple">
                                    生成
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-data-table v-model="select_fangwu" checkbox-color="error" show-select disable-pagination
                        hide-default-footer height="68vh" :headers="headers" :items="fangwu_items" disable-sort
                        item-key="_id" :loading="loading">
                        <template v-slot:[`item.name`]="{ item }">
                            <v-text-field dense v-model="item.name" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto"></v-text-field>
                        </template>
                        <template v-slot:[`item.mianji`]="{ item }">
                            <v-text-field dense v-model="item.mianji" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto" type="number"></v-text-field>
                        </template>
                        <template v-slot:[`item.yezhu`]="{ item }">
                            <v-text-field dense v-model="item.yezhu" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto"></v-text-field>
                        </template>
                        <template v-slot:[`item.sex`]="{ item }">
                            <v-select dense v-model="item.sex" :items="sex_items" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto"></v-select>
                        </template>
                        <template v-slot:[`item.phone`]="{ item }">
                            <v-text-field dense v-model="item.phone" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto"></v-text-field>
                        </template>
                        <template v-slot:[`item.idcard`]="{ item }">
                            <v-text-field dense v-model="item.idcard" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto"></v-text-field>
                        </template>
                        <template v-slot:[`item.plate`]="{ item }">
                            <v-text-field dense v-model="item.plate" color="primary" flat solo-inverted
                                @change="fangwu_change(item)" hide-details="auto"></v-text-field>
                        </template>
                        <template v-slot:[`item._edited`]="{ item }">
                            <v-icon v-if="item._edited" small color="error">mdi-pencil</v-icon>
                            <v-icon v-else small color="success">mdi-check</v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({

        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: "",
        },
        active: [],
        // 按钮组的禁用控制
        btn_save_all_disabled: true,
        btn_del_disabled: true,
        // 房屋选择的数据
        select_fangwu: [],
        // 性别下拉框
        sex_items: [{ value: 0, text: '未知' }, { value: 1, text: '男性' }, { value: 2, text: '女性' }],

        // 公司及小区数组
        xiaoqu_items: [],
        loudong_items: [],

        // 当前选择项目
        select_xiaoqu: {},
        loading: false,
        headers: [
            { text: '名称', width: 100, value: 'name' },
            { text: '面积', width: 100, value: 'mianji' },
            { text: '业主', width: 100, value: 'yezhu' },
            { text: '性别', width: 50, value: 'sex' },
            { text: '电话', width: 120, value: 'phone' },
            { text: '身份证', width: 180, value: 'idcard' },
            { text: '车牌', width: 100, value: 'plate' },
            { text: '状态', width: 50, value: '_edited' },
        ],
        fangwu_items: [],

        // 批量添加默认值
        dialog: false,
        editedItem: {
            start_louceng: 1,
            end_louceng: 1,
            start_fanghao: 1,
            end_fanghao: 1,
            mianji: 0,
            lianjiefu:'-',
            biaoshi: '号'
        },
        count: 1
    }),

    mounted() {
        this.get_xiaoqu_list()
    },

    watch: {
        // 判断是否存在修改的项目
        fangwu_items: {
            handler: function (val) {
                try {
                    val.forEach((item) => {
                        if (item._edited) {
                            this.btn_save_all_disabled = false
                            throw Error('终端循环：发现有需要修改的房屋')
                        } else {
                            this.btn_save_all_disabled = true
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            deep: true,//深度监听
        },
        // 弹出框恢复默认
        // dialog(val) {
        //     if (val == false) {
        //         this.editedItem = {
        //             start_louceng: 1,
        //             end_louceng: 1,
        //             start_fanghao: 1,
        //             end_fanghao: 1,
        //             biaoshi: '号'
        //         }
        //     }
        // },
        editedItem: {
            handler: function (val) {
                console.log(val)
                var x = Number(val.start_louceng)
                var y = Number(val.end_louceng)
                var a = Number(val.start_fanghao)
                var b = Number(val.end_fanghao)
                this.count = (y - x + 1) * (b - a + 1)
            },
            deep: true
        },
        active(val) {
            this.btn_save_all_disabled = true
            if (val.length == 0) {
                this.fangwu_items = []
            } else {
                this.get_fangwu_list()
            }
        }
    },
    methods: {
        // 获取小区列表
        get_xiaoqu_list() {
            this.$axios.get('/xiaoqu/getlist')
                .then((res) => {
                    console.log(res.data)
                    this.xiaoqu_items = res.data
                })
        },
        // 获取楼栋列表
        get_loudong_list() {
            this.loudong_items = [] // 清空楼栋列表
            this.$axios.get('/loudong/get_loudong_danyuan', { params: { xiaoqu: this.select_xiaoqu } })
                .then((res) => {
                    this.loudong_items = res.data
                })
        },

        // 获取房屋列表
        get_fangwu_list() {
            this.loading = true
            this.fangwu_items = []
            this.$axios.get('/fangwu/getlist', { params: { danyuan: this.active[0] } })
                .then((res) => {
                    console.log(res.data)
                    // 增加_edited
                    if (res.data) {
                        res.data.forEach((item) => {
                            item._edited = false
                        })
                        this.fangwu_items = res.data
                        this.loading = false
                    }
                })
            this.select_fangwu = []
        },

        // 选择小区
        change_xiaoqu() {
            console.log('当前选择的小区为：' + this.select_xiaoqu)
            this.fangwu_items = [] // 清空房屋列表
            this.get_loudong_list() // 获取楼栋列表
        },

        // 单个添加
        btn_add_single() {
            this.fangwu_items.push({ name: '', mianji: 0, yezhu: '', sex: 0, phone: '', idcard: '', plate: '', danyuan: this.active[0], _edited: true })
        },

        // 批量添加
        btn_add_multiple() {
            var x = Number(this.editedItem.start_louceng)
            var y = Number(this.editedItem.end_louceng)
            var a = Number(this.editedItem.start_fanghao)
            var b = Number(this.editedItem.end_fanghao)
            console.log(this.count)
            for (x; x <= y; x++) {
                var i = a
                var j = b
                for (i; i <= j; i++) {
                    var name = x + this.editedItem.lianjiefu + i + this.editedItem.biaoshi
                    this.fangwu_items.push({ name: name, mianji: this.editedItem.mianji, yezhu: '', sex: 0, phone: '', idcard: '', plate: '', danyuan: this.active[0], _edited: true })
                }
            }
            this.dialog = false
        },

        // 删除选择项-------------------------------------------------
        btn_del() {
            var delete_items = []
            this.select_fangwu.forEach((item) => {
                if (item._id) {
                    delete_items.push(item)
                }
            })
            this.$axios.post('/fangwu/delete', delete_items)
                .then((res) => {
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: "success",
                    };
                    this.select_fangwu = []
                    this.get_fangwu_list()
                })
        },

        // 保存到数据库---------------------------------------------------------------
        async btn_save() {
            var update_items = [] // 更新的项目
            var create_items = [] // 新增的项目
            this.fangwu_items.forEach((item) => {
                if (item._edited) {
                    if (item._id) {
                        update_items.push(item)
                    } else {
                        create_items.push(item)
                    }
                }
            })
            // 更新保存
            const promise1 = this.$axios.post('/fangwu/update', update_items)
            // 创建保存
            const promise2 = this.$axios.post('/fangwu/create', create_items)

            await Promise.all([promise1, promise2]).then((res) => {
                console.log(res);
                this.snackbar = {
                    show: true,
                    text: '保存成功',
                    color: "success",
                };
            })
            this.get_fangwu_list()
        },

        // 修改数据后
        fangwu_change(item) {
            item._edited = true
        },
    }
}
</script>