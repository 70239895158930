<template>
    <v-card height="100%" style="flex:1;overflow: auto;">
        <v-card-title>
            操作日志
            <v-spacer></v-spacer>
            <v-text-field v-model="content" label="筛选事件内容" outlined dense hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getlist" :loading="loading" class="mr-5">
                查询
            </v-btn>
            <download-excel :data="items" :fields="excelFields" name="操作日志">
                <v-btn color="success" :disabled="!items.length" :loading="loading">
                    导出
                </v-btn>
            </download-excel>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total_num"
                :loading="loading">
                <template v-slot:top>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.date).toLocaleString() }}
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>
import JsonExcel from "vue-json-excel";
export default {
    components: {
        'downloadExcel': JsonExcel
    },
    data: () => ({
        loading: false,
        total_num: 0,
        options: {},
        content: '',
        headers: [
            { text: '时间', sortable: false, value: 'date' },
            { text: '小区', sortable: false, value: 'xiaoqu.name' },
            { text: '房屋', sortable: false, value: 'fangwu.name' },
            { text: '账号', sortable: false, value: 'user.username', },
            { text: '姓名', sortable: false, value: 'user.nickname' },
            { text: '事件', sortable: false, value: 'info' },
        ],
        items: [],
        excelFields: {
            "时间": {
                field: "date",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
            "小区": "xiaoqu.name",
            "房屋": "fangwu.name",
            "账号": "user.username",
            "姓名": "user.nickname",
            "事件": "info",
        }
    }),

    watch: {
        options: {
            handler() {
                this.getlist()
            },
            deep: true,
        },
    },

    methods: {
        getlist() {
            this.loading = true
            this.$axios.get('/log', { params: { page: this.options.page, itemsPerPage: this.options.itemsPerPage, content: this.content } })
                .then((res) => {
                    setTimeout(() => {
                        this.loading = false
                        this.items = res.data.log_doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        }
    }
}
</script>