<template>
    <v-data-table :headers="headers" :items="items" sort-by="daoqishijian" class="elevation-2" style="flex:1">
        <template v-slot:top>
            <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
            </v-snackbar>
            <v-toolbar flat>
                <v-toolbar-title>系统列表</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                            新增
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.site_name" label="站点名称" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.site_code" label="站点代码" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="editedItem.wx_op.app_id" label="微信开放平台应用app_id"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field v-model="editedItem.wx_op.app_secret" label="微信开放平台应用app_secret"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="editedItem.wx_mp.app_id" label="微信小程序app_id"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field v-model="editedItem.wx_mp.app_secret" label="微信小程序app_secret"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="editedItem.wx_oa.app_id" label="微信公众号app_id"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field v-model="editedItem.wx_oa.app_secret" label="微信公众号app_secret"
                                            dense></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="10" md="10">
                                        模板消息
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2">
                                        <v-btn color="primary" small @click="addTemplateId">添加</v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12"
                                        v-for="(templateItem, index) in editedItem.wx_oa.template_ids" :key="index">
                                        <v-row>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="templateItem.name" label="模板名称" dense></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="templateItem.id" label="模板id" dense></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="2">
                                                <v-btn color="error" small @click="delTemplateId(index)">删除</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                取消
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="primary" small @click="editItem(item)">
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>
<script>
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        dialog: false,
        start_date_picker_dialog: false,
        end_date_picker_dialog: false,
        headers: [
            { text: 'id', sortable: false, value: '_id', },
            { text: '站点名称', sortable: false, value: 'site_name', },
            { text: '站点代码', sortable: false, value: 'site_code', },
            { text: '微信开放平台APP', sortable: false, value: 'wx_op.app_id', },
            { text: '小程序', sortable: false, value: 'wx_mp.app_id', },
            { text: '微信公众号', sortable: false, value: 'wx_oa.app_id', },
            { text: '编辑/删除', align: 'center', sortable: false, value: 'actions' },
        ],
        items: [],
        companyItems: [],
        editedIndex: -1,
        editedItem: {
            site_name: '',
            site_code: '',
            wx_op: {
                app_id: '',
                app_secret: ''
            },
            wx_mp: {
                app_id: '',
                app_secret: ''
            },
            wx_oa: {
                app_id: '',
                app_secret: '',
                template_ids: []
            },
            wx_partner: {
                sp_mchid: '',
                serial_no: '',
                privateKey: '',
                apiv3_private_key: ''
            }
        },
        defaultItem: {
            ite_name: '',
            site_code: '',
            wx_op: {
                app_id: '',
                app_secret: ''
            },
            wx_mp: {
                app_id: '',
                app_secret: ''
            },
            wx_oa: {
                app_id: '',
                app_secret: '',
                template_ids: []
            },
            wx_partner: {
                sp_mchid: '',
                serial_no: '',
                privateKey: '',
                apiv3_private_key: ''
            }
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '添加' : '编辑'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        }
    },

    created() {
        this.getlist()
    },

    methods: {
        getlist() {
            this.$axios.get('/config')
                .then((res) => {
                    this.items = res.data
                })
        },

        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        addTemplateId() {
            this.editedItem.wx_oa.template_ids.push({ name: '', id: '' })
        },
        delTemplateId(index){
            this.editedItem.wx_oa.template_ids.splice(index,1)
        },
        save() {
            if (this.editedIndex > -1) {
                // 更新
                this.$axios.post('/config/update', this.editedItem)
                    .then((res) => {
                        this.close()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.msg,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            } else {
                // 添加
                this.$axios.post('/config/create', this.editedItem)
                    .then((res) => {
                        this.close()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.msg,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            }
        },
    },
}
</script>