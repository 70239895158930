<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
#app{
  background:#fafafa;
}
/* 全局自定义滚动条 */
::-webkit-scrollbar {width: 6px;}
::-webkit-scrollbar-thumb{background-color:#363636;border-radius:4px;}
</style>