<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 邻里说列表
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers" :items="forum_items" item-key="_id" :loading="loading" show-expand
                        :options.sync="options" :server-items-length="total_num">
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div>详细内容： {{ item.content }}</div>
                                <div>评论列表：</div>
                                <div v-for="(commentItem, index) in item.comment" :key="index">
                                    {{ new Date(commentItem.date).toLocaleString() }}
                                    @{{ commentItem.create_wx_user.nickname }}
                                    :{{ commentItem.content }}
                                </div>
                            </td>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            {{ typeLists.find((element) => { return element.value == item.type }).text }}
                        </template>
                        <template v-slot:[`item.content`]="{ item }">
                            {{ item.content.slice(0, 30) }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-switch @change="change_status(item)" v-model="item.status"></v-switch>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        total_num: 0,
        options: {},
        loading: false,
        xiaoqu: null,
        // 费项列表
        forum_items: [],
        typeLists: [{
            value: 'xqzt',
            text: '#小区杂谈',
        }, {
            value: 'zpqz',
            text: '#招聘求职',
        }, {
            value: 'znjy',
            text: '#子女教育',
        }, {
            value: 'esxz',
            text: '#二手闲置',
        }, {
            value: 'xqah',
            text: '#兴趣爱好',
        }, {
            value: 'fwzl',
            text: '#房屋租赁',
        }, {
            value: 'fwmm',
            text: '#房屋买卖',
        }],
        headers: [
            { text: '', value: 'data-table-expand' },
            { text: "时间", sortable: false, value: "date" },
            { text: "类型", sortable: true, value: "type" },
            { text: "发表人", sortable: true, value: "create_wx_user.nickname" },
            { text: "内容", sortable: true, value: "content" },
            { text: "评论数", sortable: true, value: "comment.length" },
            { text: "展示", sortable: false, value: "status" },
        ],
    }),
    watch: {
        dialog(val) {
            val || this.close()
        },
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_forums_list()
                }
            },
            deep: true,
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.get_forums_list()
            } else {
                this.forum_items = []
            }
        },

        // 获取公告列表
        get_forums_list() {
            this.loading = true
            this.$axios.get("/forum/lists", { params: { xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    this.loading = false
                    this.forum_items = res.data.forum_doc
                    this.total_num = res.data.total_num
                })
        },
        // 审核
        change_status(item) {
            this.$axios.post("/forum/changeStatus", item)
                .then((res) => {
                    this.get_forums_list()
                    this.snackbar = {
                        show: true,
                        text: res.data,
                        color: 'success'
                    }
                })
        }
    },
};
</script>
