<template>
  <v-main>
    <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}</v-snackbar>
    <!-- 登录窗口 -->
    <v-dialog v-model="loginDialog" width="380" persistent>
      <v-card dark>
        <v-card-title>登录</v-card-title>
        <v-card-text>
          <v-text-field autofocus clearable v-model="form_login.username" outlined prepend-inner-icon="mdi-account"
            label="账号">
          </v-text-field>
          <v-text-field hide-details @click:append="password_show = !password_show"
            :type="password_show ? 'text' : 'password'" :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="form_login.password" outlined prepend-inner-icon="mdi-key" label="密码">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="login">
            登录
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 头部 -->
    <div class="header d-flex justify-space-between" style="padding:0 2vw">
      <div class="pa-5">
        <v-menu dark max-height="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-5" color="primary" dark v-bind="attrs" v-on="on">
              切换
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="loopXiaoqus()">
              <v-list-item-title>全部小区</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(item, index) in xiaoqus" :key="index" @click="selectedXiaoqu = item; timerRun = false;">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="success">刷新</v-btn>
      </div>
      <div style="font-size: 3.8vh;">【{{ selectedXiaoqu.name ? selectedXiaoqu.name : '未知小区' }}】管家驾驶舱</div>
      <div class="pa-5">
        <v-btn color="primary" class="mr-5" @click="fullScreenState = !fullScreenState">全屏</v-btn>
        <v-btn color="error" @click="logout">登出</v-btn>
      </div>
    </div>
    <!-- 内容 -->
    <div style="padding:0 2vw 4vh 2vw;height: 92vh;" class="d-flex">
      <v-container fluid class="flex-sub d-flex flex-column overflow-auto">
        <v-row>
          <v-col class="d-flex flex-column">
            <v-row>
              <v-col>
                <div class="box">
                  <p class="box_title">服务工单</p>
                  <div class="box_content">
                    <p class="text-h5">
                      待接单 <span class="red--text text-h2 font-weight-bold">{{ report_num.report_0_num }}</span>条 /
                      待处理 <span class="red--text text-h2 font-weight-bold">{{ report_num.report_1_num }}</span>条
                    </p>
                    <p class="text-h5">总计完成 <span class="green--text text-h2 font-weight-bold">
                        {{ report_num.report_2_3_num }}</span>条
                    </p>
                  </div>
                  <!-- 四角效果 -->
                  <span class="left-top"></span>
                  <span class="right-top"></span>
                  <span class="left-bottom"></span>
                  <span class="right-bottom"></span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="box">
                  <p class="box_title">管家信息</p>
                  <div class="box_content">
                    <div class="d-flex align-center">
                      <div class="mr-10"><v-img height="140" width="120"
                          src="https://api.youzhuguanjia.com/images/avatar.jpg"></v-img></div>
                      <div>
                        <p class="text-h5">管家：{{ selectedXiaoqu.guanjia }}</p>
                        <p class="text-h5">电话：{{ selectedXiaoqu.phone }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 四角效果 -->
                  <span class="left-top"></span>
                  <span class="right-top"></span>
                  <span class="left-bottom"></span>
                  <span class="right-bottom"></span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <div class="box">
              <div id="container" style="height: 100%;width:100%"></div>
              <!-- 地图上层覆盖 -->
              <div style="background:#00000080;position: absolute;top:10px;left:10px;padding:10px;">
                <div style="background:#00000095;padding: 10px;margin:10px;" class="text-h5">楼栋：{{
                  xiaoquCount.loudongCount }}</div>
                <div style="background:#00000095;padding: 10px;margin:10px;" class="text-h5">单元：{{
                  xiaoquCount.danyuanCount }}</div>
                <div style="background:#00000095;padding: 10px;margin:10px;" class="text-h5">房产：{{ xiaoquCount.fangwuCount
                }}</div>
                <div style="background:#00000095;padding: 10px;margin:10px;" class="text-h5">业主：<span
                    class="green--text font-weight-bold">{{ xiaoquCount.userCount }}</span> 人</div>
              </div>
              <div style="position: absolute;bottom:10px;right:10px;padding:10px;">
                <div class="text-h5">{{ (user_doc && user_doc.company && user_doc.company.qiyejiancheng) ?
                  user_doc.company.qiyejiancheng : '企业简称' }}</div>
              </div>
            </div>
          </v-col>
          <v-col class="d-flex flex-column">
            <v-row>
              <v-col>
                <div class="box">
                  <p class="box_title">今日巡逻巡更打卡</p>
                  <div class="box_content">
                    <p class="text-h5 text-center"><span class="green--text text-h1 font-weight-bold">{{
                      qrcode_num.sheet_num }}</span>次</p>
                  </div>
                  <!-- 四角效果 -->
                  <span class="left-top"></span>
                  <span class="right-top"></span>
                  <span class="left-bottom"></span>
                  <span class="right-bottom"></span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="box">
                  <p class="box_title">今日社区访客登记</p>
                  <div class="box_content">
                    <p class="text-h5 text-center"><span class="red--text text-h1 font-weight-bold">{{
                      qrcode_num.visitor_num }}</span>人</p>
                  </div>
                  <!-- 四角效果 -->
                  <span class="left-top"></span>
                  <span class="right-top"></span>
                  <span class="left-bottom"></span>
                  <span class="right-bottom"></span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3">
            <div class="box">
              <p class="box_title">今日在岗</p>
              <!-- 巡逻巡更统计用户唯一数量 -->
              <div class="box_content">
                <p class="text-h5 text-center"><span class="green--text text-h1 font-weight-bold">{{ qrcode_num.worker_num
                }}</span>人</p>
              </div>
              <!-- 四角效果 -->
              <span class="left-top"></span>
              <span class="right-top"></span>
              <span class="left-bottom"></span>
              <span class="right-bottom"></span>
            </div>
          </v-col>
          <v-col class="col-3">
            <div class="box">
              <p class="box_title">最新社区公告</p>
              <div class="box_content">
                <p class="overflow-hidden">
                  <span class="text-h3 font-weight-bold text-no-wrap notice"
                    :style="'animation-duration:' + ((new_notice && new_notice.content) ? (new_notice.content.length / 2) : 10) + 's'">
                    {{ (new_notice && new_notice.content) ? new_notice.content : '暂无发布公告！' }}
                  </span>
                </p>
              </div>
              <!-- 四角效果 -->
              <span class="left-top"></span>
              <span class="right-top"></span>
              <span class="left-bottom"></span>
              <span class="right-bottom"></span>
            </div>
          </v-col>
          <v-col class="col-3">
            <div class="box">
              <p class="box_title">今日业主发表说说</p>
              <div class="box_content">
                <p class="text-h5 text-center"><span class="red--text text-h1 font-weight-bold">{{ forum_num }}</span>条
                </p>
              </div>
              <!-- 四角效果 -->
              <span class="left-top"></span>
              <span class="right-top"></span>
              <span class="left-bottom"></span>
              <span class="right-bottom"></span>
            </div>
          </v-col>
          <v-col class="col-3">
            <div class="box">
              <p class="box_title">服务商订单</p>
              <div class="box_content">
                <p class="text-h5">
                  待接单 <span class="red--text text-h2 font-weight-bold">{{ order_num.order_0_num }}</span>条 /
                  待配送 <span class="red--text text-h2 font-weight-bold">{{ order_num.order_1_num }}</span>条
                </p>
                <p class="text-h5">总计完成 <span class="green--text text-h2 font-weight-bold">{{ order_num.order_2_num
                }}</span>条</p>
              </div>
              <!-- 四角效果 -->
              <span class="left-top"></span>
              <span class="right-top"></span>
              <span class="left-bottom"></span>
              <span class="right-bottom"></span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: "BigDataView",
  data: () => {
    return {
      snackbar: {
        show: false,
        text: "登录成功",
        color: "success"
      },
      fullScreenState: false,
      loading: false,
      password_show: false,
      form_login: {
        username: '',
        password: ''
      },
      user_doc: {},
      AMap: null,
      loginDialog: false,
      // 定时器
      timerRun: true,
      // 所有小区数组
      xiaoqus: [],
      // 当前选择的小区
      selectedXiaoqu: {},
      // 当前小区楼栋、单元、房屋、业主数量
      xiaoquCount: {
        loudongCount: 0,
        danyuanCount: 0,
        fangwuCount: 0,
        userCount: 0,
      },
      // 报事报修
      report_num: {
        report_0_num: 0,
        report_1_num: 0,
        report_2_3_num: 0
      },
      // 扫码记录
      qrcode_num: {
        sheet_num: 0,
        sheet_today: [],
        visitor_num: 0,
        worker_num: 0
      },
      // 最新公告
      new_notice: {},
      // 今日业主发表
      forum_num: 0,
      // 服务商订单
      order_num: {
        order_0_num: 0,
        order_1_num: 0,
        order_2_num: 0
      }
    }
  },
  mounted: function () {
    this.initMap();
    window._AMapSecurityConfig = { securityJsCode: 'ba730a89a2659e000369389801196a8d' }
  },
  // 监听当前选择的小区
  watch: {
    fullScreenState(newValue) {
      if (newValue) {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    selectedXiaoqu: function (newXiaoqu) {
      if (newXiaoqu.latlng && newXiaoqu.latlng) {
        var lng = newXiaoqu.latlng.split(',')[1]
        var lat = newXiaoqu.latlng.split(',')[0]
        this.map.setCenter([lng, lat], true)
      }
      // 小区切换后直接获取一次
      if (newXiaoqu._id) {
        this.fetchDate()
        // 获取楼栋、单元、房屋数量
        this.$axios.post('/xiaoqu/bigdata/count', newXiaoqu)
          .then(res => {
            this.xiaoquCount = res.data[0]
          })
      }
    }
  },
  methods: {
    // 初始化高德地图
    initMap() {
      AMapLoader.load({
        key: "d1c41d42c5f8acf8bfc2465ebe037685",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.AMap = AMap
        this.map = new AMap.Map("container", {  //设置地图容器id
          pitch: 45, // 地图俯仰角度，有效范围 0 度- 83 度
          viewMode: '3D', // 地图模式
          zoom: 18,           //初始化地图级别
          center: [102.824575, 24.880425], //初始化地图中心点位置
          layers: [
            // 建筑
            new AMap.Buildings({
              'wallColor': 'blue',
              'roofColor': 'red',
              'opacity': 0.8,
              'heightFactor': 2// 2 倍于默认高度（3D 视图下生效）
            }),
            // 路网
            new AMap.TileLayer.RoadNet(),
            // 卫星
            new AMap.TileLayer.Satellite({})
          ],
        });
        // 监听点击事件
        this.map.on('click', this.MapOnClick);
        // 3秒后开始旋转
        var deg = 0
        setInterval(() => {
          deg += 0.1
          this.map.setRotation(deg)
        }, 20)

        // 判断是否登录
        if (sessionStorage.getItem('token')) {
          // 已登录
          console.log('已登录')
          this.user_doc = JSON.parse(sessionStorage.getItem('user_doc'))
          console.log(this.user_doc)
          this.get_xiaoqu_lists()
        } else {
          // 未登录
          console.log('未登录')
          this.user_doc = {}
          this.loginDialog = true
        }
      }).catch(e => {
        console.log(e);
      })
    },
    // 点击地图获取经纬度
    MapOnClick(e) {
      console.log(e.lnglat.lng + ',' + e.lnglat.lat)
    },
    // 用户登录
    login() {
      if (!this.form_login.username || !this.form_login.password) {
        this.snackbar = {
          show: true,
          text: "用户名和密码不能为空！",
          color: "error"
        }
      } else {
        this.loginDialog = false
        this.loading = true
        this.$axios.post('/user/login', this.form_login)
          .then(res => {
            // 登录成功
            if (res.data.token) {
              sessionStorage.setItem('token', res.data.token)
              sessionStorage.setItem('user_doc', JSON.stringify(res.data.user_doc))
              setTimeout(() => {
                this.user_doc = res.data.user_doc
                this.loading = false
                this.get_xiaoqu_lists()
              }, 500);
            } else {
              setTimeout(() => {
                this.loading = false
                this.loginDialog = true
                this.snackbar = {
                  show: true,
                  text: res.data.message,
                  color: res.data.errors ? "error" : "success",
                }
              }, 500);
            }
          })
          .catch(error => {
            this.snackbar = {
              show: true,
              text: error,
              color: "error"
            }
            this.loading = false
            this.loginDialog = true
          })
      }
    },
    // 清除数据-初始化数据
    clearDate() {
      this.xiaoquCount = {
        loudongCount: 0,
        danyuanCount: 0,
        fangwuCount: 0,
        userCount: 0,
      }
    },
    // 获取小区列表
    get_xiaoqu_lists() {
      this.$axios
        .get("/xiaoqu/getlist")
        .then((res) => {
          this.xiaoqus = res.data
          this.loopXiaoqus()
          // 每隔5S根据小区请求数据
          var timer = setInterval(() => {
            if (this.selectedXiaoqu._id) {
              this.fetchDate()
            } else {
              clearInterval(timer)
            }
          }, 5000);
        });
    },
    loopXiaoqus() {
      this.timerRun = true
      // 默认第一个小区
      this.selectedXiaoqu = this.xiaoqus[0]
      // 每10秒轮训请求一次小区数据
      var i = 1
      var timer = setInterval(() => {
        if (this.timerRun) {
          this.selectedXiaoqu = this.xiaoqus[i]
          i < (this.xiaoqus.length - 1) ? i++ : i = 0
        } else {
          clearInterval(timer)
        }
      }, 10000);
    },
    // 根据小区请求数据
    fetchDate() {
      this.$axios
        .get("/bigdata/index", { params: { xiaoqu: this.selectedXiaoqu._id } })
        .then((res) => {

          // 播报声音
          if (res.data.qrcode_num.sheet_num && res.data.qrcode_num.sheet_num != this.qrcode_num.sheet_num) {
            this.set_poly_line(res.data.qrcode_num.sheet_today)
            let speechInstance = new SpeechSynthesisUtterance('已完成对' + res.data.qrcode_num.sheet_today[res.data.qrcode_num.sheet_num - 1].qrcode_name + '巡逻巡检。');
            speechSynthesis.speak(speechInstance);
          }
          this.report_num = res.data.report_num
          this.qrcode_num = res.data.qrcode_num
          this.new_notice = res.data.new_notice
          this.forum_num = res.data.forum_num
          this.order_num = res.data.order_num
        });
    },
    // 根据sheet_today画折线图
    set_poly_line(sheet_today) {
      this.map.clearMap()
      if (sheet_today.length) {
        var path = [];
        var markerList = [];

        sheet_today.forEach(element => {
          var lnglatObj = element.detail.find(item => item.name == 'lnglat')
          if (lnglatObj) {
            var lnglatArr = lnglatObj.value.split(',')
            lnglatArr = new this.AMap.LngLat(lnglatArr[0], lnglatArr[1])
            path.push(lnglatArr)
          }
        });
        // 其他坐标转高德坐标
        this.AMap.convertFrom(path, 'gps', (status, result) => {
          if (result.info === 'ok') {
            result.locations.forEach((item, index) => {
              markerList.push(new this.AMap.Marker({ position: item, content: (index + 1) }))
            })
            var polyline = new this.AMap.Polyline({
              path: result.locations,
              strokeOpacity: 1,
              strokeColor: 'yellow'
            });
            console.log(markerList)
            this.map.add(polyline);
            this.map.add(markerList);
          }
        })
      }
    },
    // 退出登录
    logout() {
      sessionStorage.clear()
      this.clearDate()
      this.timerRun = false
      this.xiaoqus = []
      this.selectedXiaoqu = {}
      this.loginDialog = true
    }
  }
};
</script>
<style scoped>
.v-main {
  background: black url(@/assets/bigdata_bg.jpg);
  background-size: 100% 100%;
  color: white;
}

.header {
  height: 8vh;
  line-height: 8vh;
}

.box {
  background: #00000080;
  padding: 10px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box_title {
  font-size: xx-large;
}

.box_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes marquee {
  0% {
    transform: translateX(30vw);
  }

  100% {
    transform: translateX(-100%);
  }
}

.notice {
  display: inline-block;
  animation-name: marquee;
  animation-duration: 10s;
  /* 默认10s动画，在html中通过文本长度动态设置 */
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.left-top,
.right-top,
.left-bottom,
.right-bottom {
  position: absolute;
  width: 10px;
  height: 10px;
}

.left-top {
  left: 0;
  top: 0;
  border-left: solid 2px white;
  border-top: solid 2px white;
}

.right-top {
  right: 0;
  top: 0;
  border-right: solid 2px white;
  border-top: solid 2px white;
}

.left-bottom {
  left: 0;
  bottom: 0;
  border-left: solid 2px white;
  border-bottom: solid 2px white;
}

.right-bottom {
  right: 0;
  bottom: 0;
  border-right: solid 2px white;
  border-bottom: solid 2px white;
}
</style>