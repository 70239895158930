<template>
    <v-row>
        <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
        </v-snackbar>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9">
            <v-card min-height="100%">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 公告列表
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on" :disabled="xiaoqu ? false : true">
                                新增
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">发布</span>
                            </v-card-title>

                            <v-card-text>
                                <v-text-field v-model="editedItem.title" label="标题"></v-text-field>
                                <v-textarea v-model="editedItem.content" rows="15" label="内容">
                                </v-textarea>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="dialog = false" :loading="loading">
                                    取消
                                </v-btn>
                                <v-btn color="primary" @click="save" :loading="loading">
                                    {{ editType == 'save' ? '保存' : '更新' }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear v-show="finish" striped :value="finish" height="25">
                        <strong>{{ Math.round(finish) }}%</strong>
                    </v-progress-linear>
                    <v-data-table :headers="headers" :items="notice_items" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num">
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="primary" class="mr-2" small @click="editItem(item)" :loading="loading">编辑</v-btn>
                            <v-btn color="success" class="mr-2" small @click="pushItem(item)" :loading="loading">推送</v-btn>
                            <v-btn color="primary" class="mr-2" small @click="printItem(item)" :loading="loading">打印</v-btn>
                            <v-btn color="error" small @click="deleteItem(item)" :loading="loading">删除</v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { getLodop } from '@/utils/LodopFuncs' //导入模块
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
        },
        total_num: 0,
        options: {},
        editType: 'save', //新增发布还是更新
        defaulItem: {
            title: '',
            content: '',
        },
        editedItem: {
            title: '',
            content: '',
        },
        finish: 0,
        dialog: false,
        loading: false,
        table_loading: false,
        xiaoqu: null,
        // 费项列表
        notice_items: [],
        headers: [
            { text: "时间", sortable: false, value: "date" },
            { text: "标题", sortable: true, value: "title" },
            { text: "业主", sortable: true, value: "all_num" },
            { text: "推送", sortable: true, value: "finish_num" },
            { text: "操作", sortable: false, value: "actions", align: 'center' },
        ],
    }),
    watch: {
        dialog(val) {
            val || this.close()
        },
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_notice_list()
                }
            },
            deep: true,
        },
    },
    methods: {
        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                this.get_notice_list()
            } else {
                this.notice_items = []
            }
        },

        // 获取公告列表
        get_notice_list() {
            this.table_loading = true
            this.$axios.get("/notice", { params: { xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    setTimeout(() => {
                        this.table_loading = false
                        this.notice_items = res.data.notice_doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        },

        close() {
            this.editedItem = Object.assign(this.defaulItem)
            this.editType = 'save'
        },
        editItem(item) {
            this.editType = 'update'
            this.editedItem = Object.assign(item)
            this.dialog = true
        },
        // 保存到数据库---------------------------------------------------------------
        save() {
            if (this.notice_title == '' || this.notice_content == '') {
                this.snackbar = {
                    show: true,
                    text: "不允许为空",
                    color: "error",
                }
                return
            }
            this.loading = true
            // 如果是新发布
            if (this.editType == 'save') {
                this.editedItem.xiaoqu = this.xiaoqu
                this.$axios.post("/notice", this.editedItem)
                    .then((res) => {
                        console.log(res.data)
                        this.dialog = false
                        this.loading = false
                        this.get_notice_list()
                    })
            } else {
                // 如果是编辑更新
                this.$axios.post("/notice/update", this.editedItem)
                    .then((res) => {
                        console.log(res.data)
                        this.dialog = false
                        this.loading = false
                        this.get_notice_list()
                    })
            }
        },
        // 推送公告
        pushItem(item) {
            this.finish = 0
            this.loading = true
            this.$axios.post("/notice/push", item)
                .then(() => {
                    // 循环查询进度
                    var fun = setInterval(() => {
                        this.$axios.post("/notice/finish", item)
                            .then((res) => {
                                this.finish = res.data.finish
                                item.finish_num = res.data.doc.finish_num
                                item.all_num = res.data.doc.all_num
                                if (res.data.finish == 100) {
                                    clearInterval(fun)
                                    this.loading = false
                                    this.get_notice_list()
                                }
                            })
                    }, 1000);
                })
        },
        // 删除公告
        deleteItem(item) {
            this.$axios.post("/notice/del", item)
                .then((res) => {
                    this.get_notice_list()
                    this.snackbar = {
                        show: true,
                        text: res.data,
                        color: 'success'
                    }
                })
        },

        // 打印公告
        printItem(item) {
            let LODOP = getLodop()//调用getLodop获取LODOP对象
            if (LODOP.error) {
                console.log(LODOP.error)
            } else {
                LODOP.PRINT_INIT("")
                LODOP.SET_PRINT_PAGESIZE(1, 2100, 2970, "");
                LODOP.SET_SHOW_MODE("PREVIEW_NO_MINIMIZE", true); //预览窗口禁止最小化，并始终最前	
                LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印后自动关闭预览窗口

                var strFormHtml = ""
                strFormHtml = strFormHtml + "<div style='padding:10%;margin:0;letter-spacing:2;'>";
                strFormHtml = strFormHtml + "<h1 style='line-height:2;text-align:center;border-bottom: double;'>" + item.title + "</h1>"
                strFormHtml = strFormHtml + "<h3>尊敬的业主/住户：</h3>"
                strFormHtml = strFormHtml + "<h3 style='line-height:2;text-indent:1cm;'>" + item.content + "</h3>"
                strFormHtml = strFormHtml + "<h3 style='text-align: right;margin-top:10%'>" + this.xiaoqu.company.qiyequancheng + "</h3>"
                strFormHtml = strFormHtml + "<h3 style='text-align: right;'>" + this.xiaoqu.name + "物业服务中心</h3>"
                strFormHtml = strFormHtml + "<h3 style='text-align: right;'>" + this.formatDate(new Date(item.date)) + "</h3>"
                strFormHtml = strFormHtml + "</div>"

                LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", strFormHtml);
                LODOP.PREVIEW();
            }
        },

        // 格式化日期为YYYY-MM-DD
        formatDate(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? '0' + m : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        }
    },
};
</script>
