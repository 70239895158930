<template>
    <v-row>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 业主列表</v-card-title>
                <v-card-text>
                    <!-- 表格 -->
                    <v-data-table :headers="headers" :items="user_items" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num" height="66vh">
                        <template v-slot:[`item.date`]="{ item }">
                            {{new Date(item.date).toLocaleString()}}
                        </template>
                        <template v-slot:[`item.fanghao`]="{ item }">
                            {{ item.loudong.name + item.danyuan.name + item.fangwu.name }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
export default {
    components: {
        XiaoquLists
    },
    data: () => ({
        xiaoqu: null,
        options: {},
        total_num: 0,
        loading: false,
        table_loading: false,
        // 用户列表
        user_items: [],
        headers: [
            { text: "注册时间", sortable: false, value: "date" },
            { text: "归属站点", sortable: false, value: "site.site_name" },
            { text: "房号", sortable: false, value: "fanghao" },
            { text: "姓名", sortable: false, value: "nickname" },
            { text: "身份证", sortable: false, value: "idcard" },
            { text: "电话", sortable: false, value: "phone" },
        ],
    }),
    watch: {
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.get_user_lists()
                }
            },
            deep: true,
        },
    },
    methods: {

        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                // 获取业主列表
                this.get_user_lists()
            } else {
                this.user_items = []
            }
        },

        // 获取业主列表
        get_user_lists() {
            this.table_loading = true
            this.$axios.get("/wx_user/lists", { params: { xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage } })
                .then((res) => {
                    setTimeout(() => {
                        this.table_loading = false
                        this.user_items = res.data.user_doc
                        this.total_num = res.data.total_num
                    }, 500);
                })
        }
    },
};
</script>
