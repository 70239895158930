<template>
    <v-data-table :headers="headers" :items="items" class="elevation-2" style="flex:1" :loading="loading">
        <template v-slot:top>
            <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
            </v-snackbar>
            <v-toolbar flat>
                <v-toolbar-title>商家列表</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                            新增
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                        <v-avatar tile size="100">
                                            <img :src="'https://api.youzhuguanjia.com/' + editedItem.logo">
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" sm="9" md="9">
                                        <v-file-input accept="image/*" label="商家LOGO" @change="upload_logo"
                                            :loading="fileLoading"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select :items="company_items" item-text="qiyejiancheng" item-value="_id"
                                            v-model="editedItem.company" label="物业公司"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.name" label="商家名称"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.phone" label="商家电话"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="editedItem.latlng" label="经纬度" :disabled="true">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field v-model="editedItem.address" label="详细地址">
                                            <template v-slot:append>
                                                <v-btn icon color="primary" @click="get_latlng(editedItem.address)">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                        <div ref="map_container" style="height:200px"></div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field v-model="editedItem.yilianyun_machine_code"
                                            label="易联云设备号码"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                取消
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <!-- logo -->
        <template v-slot:[`item.logo`]="{ item }">
            <v-img :src="'https://api.youzhuguanjia.com/' + item.logo" max-width="50"></v-img>
        </template>
        <!-- 默认订单状态 -->
        <template v-slot:[`item.order_status`]="{ item }">
            <v-switch v-model="item.order_status" @change="update_order_status(item)"></v-switch>
        </template>
        <!-- 状态 -->
        <template v-slot:[`item.state`]="{ item }">
            <v-switch v-model="item.state" @change="update_state(item)"></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="primary" class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-dialog :v-model="false" max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" class="mr-2" @click="bind_wechat_user(item)" v-bind="attrs" v-on="on">
                        mdi-wechat
                    </v-icon>
                </template>
                <v-card>
                    <v-card-title>微信扫码</v-card-title>
                    <v-card-text class="text-center">
                        <img :src="qrcode_img_src">
                        <p>{{ qrcode_url }}</p>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </v-data-table>
</template>
<script>
import QRCode from "qrcode";
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: ''
        },
        fileLoading: false,
        loading: true,
        dialog: false,
        date_picker_dialog: false,
        company_items: [],
        headers: [
            { text: 'ID', value: '_id' },
            { text: '归属站点', value: 'company.site.site_name' },
            { text: '物业公司', value: 'company.qiyejiancheng' },
            { text: 'LOGO', value: 'logo', },
            { text: '商家名称', value: 'name' },
            { text: '详细地址', value: 'address' },
            { text: '商家电话', value: 'phone' },
            { text: '绑定用户', value: 'wexinuser' },
            { text: '易联云', value: 'yilianyun_machine_code' },
            { text: '自动接单', value: 'order_status' },
            { text: '营业/打烊', value: 'state' },
            { text: '编辑/删除', align: 'center', value: 'actions' },
        ],
        items: [],
        editedIndex: -1,
        editedItem: {
            company: '',
            logo: '',
            name: '',
            address: '',
            latlng: '39.90871879886639, 116.3973701092209',
            phone: '',
            yilianyun_machine_code: ''
        },
        defaultItem: {
            company: '',
            logo: '',
            name: '',
            address: '',
            latlng: '39.90871879886639, 116.3973701092209',
            phone: '',
            yilianyun_machine_code: ''
        },
        map: null,
        marker: null,
        qrcode_url: '',
        qrcode_img_src: ''
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? '添加' : '编辑'
        },
    },

    watch: {
        dialog(val) {
            if (val) {
                this.$nextTick(() => {
                    this.initMap()
                })
            } else {
                this.close()
            }
        }
    },
    created() {
        this.getlist()
        // 获取企业列表
        this.$axios.get('/company/getlist')
            .then((res) => {
                this.company_items = res.data
            })
    },

    methods: {
        getlist() {
            this.items = []
            this.loading = true
            var query = {}
            if (JSON.parse(sessionStorage.user_doc).company) {
                query = { company: JSON.parse(sessionStorage.user_doc).company._id }
            }
            this.$axios.get('/shop/lists', { params: query })
                .then((res) => {
                    console.log(res)
                    this.loading = false
                    this.items = res.data
                })
        },

        editItem(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.logo_url = 'https://api.youzhuguanjia.com/' + this.editedItem.logo
            this.dialog = true
        },

        // 自动接单
        update_order_status(item) {
            item.order_status ? item.order_status = 2 : item.order_status = 0
            this.$axios.post('/shop/update', item)
                .then((res) => {
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
        },

        // 营业/打烊
        update_state(item) {
            this.$axios.post('/shop/update', item)
                .then((res) => {
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: res.data.errors ? "error" : "success",
                    }
                })
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                this.logo_file = null
                this.logo_url = ''
            })
        },

        initMap() {
            if (this.map) {
                this.map.destroy()
            }
            var latlng = this.editedItem.latlng ? this.editedItem.latlng : '39.90871879886639, 116.3973701092209'
            var lat = latlng.split(",")[0]
            var lng = latlng.split(",")[1]
            setTimeout(() => {
                console.log(this.$refs.map_container)
                var center = new window.TMap.LatLng(lat, lng)
                this.map = new window.TMap.Map(this.$refs.map_container, {
                    center: center,//设置地图中心点坐标
                    zoom: 3
                })
                this.setmarker()
                this.map.on("panend", this.panendHandler)
            }, 500);
        },

        // 拖动地图
        panendHandler(e) {
            console.log('拖动了')
            var key = "TGYBZ-ISKCF-MSGJM-NZ3ZF-U5QG2-LKF5I"
            var lat = e.target.getCenter().getLat()
            var lng = e.target.getCenter().getLng()
            this.$axios.jsonp('https://apis.map.qq.com/ws/geocoder/v1/?key=' + key + '&location=' + lat + ',' + lng + '&output=jsonp&callback=callback1', null, (err, res) => {
                if (err) {
                    console.log(err)
                } else {
                    this.editedItem.address = res.result.formatted_addresses.recommend
                    this.editedItem.latlng = lat + ',' + lng
                    this.setmarker()
                }
            })
        },

        // 通过文字地址到经纬度的转换能力
        get_latlng(address) {
            var key = "TGYBZ-ISKCF-MSGJM-NZ3ZF-U5QG2-LKF5I"
            this.$axios.jsonp('https://apis.map.qq.com/ws/geocoder/v1/?key=' + key + '&address=' + address + '&output=jsonp&callback=callback1', null, (err, res) => {
                if (err) {
                    console.log(err)
                } else {
                    var lat = res.result.lat
                    var lng = res.result.lng
                    this.editedItem.latlng = lat + ',' + lng
                    this.map.setCenter(res.result.location)
                    this.setmarker()
                }
            })
        },

        // 地图中心点标记
        setmarker() {
            if (this.marker) {
                this.marker.setMap(null);
                this.marker = null;
            }
            this.marker = new window.TMap.MultiMarker({
                map: this.map,
                styles: {
                    // 点标记样式
                    marker: new window.TMap.MarkerStyle({
                        width: 20, // 样式宽
                        height: 30, // 样式高
                        anchor: { x: 10, y: 30 }, // 描点位置
                    }),
                },
                geometries: [
                    // 点标记数据数组
                    {
                        // 标记位置(纬度，经度，高度)
                        position: this.map.getCenter(),
                        id: 'marker',
                    },
                ],
            });
        },

        // 上传logo
        upload_logo(e) {
            if (e) {
                this.fileLoading = true
                const fileData = new FormData()
                fileData.append('uploads', e)
                this.$axios.post('/uploads', fileData)
                    .then((res) => {
                        this.editedItem.logo = res.data.path
                        this.fileLoading = false
                    })
            } else {
                this.editedItem.logo = ''
            }
        },

        // 保存
        save() {
            if (this.editedIndex > -1) {
                // 更新
                this.$axios.post('/shop/update', this.editedItem)
                    .then((res) => {
                        console.log(res)
                        this.close()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            } else {
                // 添加
                this.$axios.post('/shop/create', this.editedItem)
                    .then((res) => {
                        console.log(res)
                        this.close()
                        this.getlist()
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: res.data.errors ? "error" : "success",
                        }
                    })
            }
        },

        // 绑定微信用户
        bind_wechat_user(item) {
            // 获取商家-》企业-》站点code

            this.qrcode_img_src = ''
            this.qrcode_url = ''
            this.qrcode_url = 'https://api.youzhuguanjia.com/qrcode/' + item.company.site.site_code + '?type=shop&action=bind&id=' + item._id
            let _this = this
            // 生成普通二维码，微信扫码打开小程序
            QRCode.toDataURL(this.qrcode_url, function (err, url) {
                _this.qrcode_img_src = url
            })
        }
    },
}
</script>