<template>
    <v-card style="height:100%">
        <v-card-text ref="chart" style="height:100%">

        </v-card-text>
    </v-card>
</template>
<script>
import * as echarts from 'echarts';
export default {
    props: ['echart_option'],
    data() {
        return {
            chart: null
        }
    },
    watch: {
        echart_option: {
            handler: function (val) {
                console.log(val)
                this.drawer()
            },
            deep: true
        }
    },
    methods: {
        drawer() {
            if (this.chart == null || this.chart == "" || this.chart == undefined) {
                this.chart = echarts.init(this.$refs.chart)
            }
            var option = {
                title: Object.assign(this.echart_option).title,
                legend: {},
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: 'red'
                        }
                    }
                },
                toolbox: {
                    feature: {
                        dataView: {},
                        magicType: {
                            type: ['line', 'bar', 'stack']
                        },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: Object.assign(this.echart_option).xAxis,
                yAxis: Object.assign(this.echart_option).yAxis,
                series: Object.assign(this.echart_option).series
            }
            this.chart.setOption(option, true);
            window.onresize = () => {
                if (this.$refs.chart.offsetHeight) {
                    this.chart.resize();
                }
            };
        }
    }
};
</script>