<template>
    <v-card>
        <v-card-title>
            编辑 {{fangwu_info.name}}
        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fangwu_info.name" color="primary" label="房号">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fangwu_info.mianji" color="primary" type="number" label="面积">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fangwu_info.yezhu" color="primary" label="业主姓名">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fangwu_info.phone" color="primary" label="业主电话">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fangwu_info.plate" color="primary" label="车牌">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fangwu_info.idcard" color="primary" label="身份证">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="fangwu_info.beizhu" color="primary" label="备注">
                    </v-textarea>
                </v-col>
            </v-row>
            <span class="red--text">注意：修改房屋面积将自动重新计算未缴台账中按面积收取的费用。</span>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">
                取 消
            </v-btn>
            <v-btn color="blue darken-1" text @click="fangwu_save">
                保存
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        fangwu_info: {
            _id:'',
            name: '',
            mianji: '',
            yezhu: '',
            phone:'',
            idcard:'',
            plate:'',
            beizhu:''
        }
    }),
    props: {
        fangwu_id: String
    },
    watch: {
        fangwu_id(val) {
            this.fangwu_info._id = val
            this.fangwu_info.name = ''
            this.fangwu_info.mianji = ''
            this.fangwu_info.yezhu = ''
            this.fangwu_info.phone = ''
            this.fangwu_info.idcard = ''
            this.fangwu_info.plate = ''
            this.fangwu_info.beizhu = ''
            if (val != '') {
                this.get_fangwu(val)
            }
        }
    },
    created() {
        // 首次挂载时先加载一次信息
        this.get_fangwu(this.fangwu_id)
    },
    methods: {
        // 获取房屋信息
        get_fangwu(_id) {
            this.$axios.get('/fangwu', { params: { id: _id } })
                .then((res) => {
                    this.fangwu_info = res.data
                })
        },

        // 保存
        fangwu_save() {
            this.$axios.post('/fangwu/update/one', this.fangwu_info)
                .then(() => {
                    this.$emit('fangwu_update',this.fangwu_info)
                })
        }
    }
}
</script>