<template>
    <v-container fluid class="flex-grow-1 d-flex flex-column">
        <!-- 标题 -->
        {{ title }}管理
        <!-- 工具栏 -->
        <vxe-toolbar ref="toolbarRef" export print custom :refresh="{ queryMethod: getTableData }">
            <template #buttons>
                <vxe-button icon="vxe-icon-square-plus" @click="insertEvent()">新增一级{{ title }}</vxe-button>
            </template>
        </vxe-toolbar>
        <!-- 表格 -->
        <div class="flex-grow-1">
            <vxe-table ref="tableRef" height="auto" :loading="tableLoading" :data="tableData" :export-config="{}"
                :print-config="{}" :tree-config="{ transform: true, rowField: '_id', parentField: 'parent' }"
                size="mini" show-overflow show-footer :footer-method="footerMethod">
                <vxe-column type="checkbox" width="60"></vxe-column>
                <vxe-column type="seq" width="60"></vxe-column>
                <vxe-column field="_id" title="ID">
                </vxe-column>
                <vxe-column field="site.site_name" title="归属站点">
                </vxe-column>
                <vxe-column field="qiyejiancheng" title="企业简称" tree-node>
                </vxe-column>
                <vxe-column field="qiyequancheng" title="企业全称">
                </vxe-column>
                <vxe-column field="sub_mchid" title="特约商户号"></vxe-column>
                <vxe-column field="money" title="余额"></vxe-column>
                <vxe-column field="lianxiren" title="联系人"></vxe-column>
                <vxe-column field="lianxidianhua" title="联系电话"></vxe-column>
                <vxe-column field="kaishishijian" title="开始时间" :formatter="formatTime"></vxe-column>
                <vxe-column field="daoqishijian" title="到期时间" :formatter="formatTime"></vxe-column>
                <vxe-column field="service_money" title="所需费用"></vxe-column>
                <vxe-column title="操作">

                    <template #default="{ row }">
                        <vxe-button v-if="!row.parent_id" type="text" icon="vxe-icon-square-plus"
                            @click="insertChildrenEvent(row)"></vxe-button>
                        <vxe-button type="text" icon="vxe-icon-edit" @click="editEvent(row)"></vxe-button>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>
        <!-- 表单 -->
        <vxe-modal v-model="showEditModal" :title="selectRowData ? '编辑&保存' : '新增&保存'" width="800"
            :loading="editModalLoading" resize destroy-on-close>

            <template #default>
                <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100"
                    @submit="submitEvent">
                    <vxe-form-item field="_id" title="ID" :span="24"></vxe-form-item>
                    <vxe-form-item field="site" title="站点" :span="12">
                        <template #default="{ data }">
                            <vxe-select v-model="data.site._id" placeholder="站点">
                                <vxe-option v-for="(item,index) in siteList" :key="index" :value="item._id"
                                    :label="item.site_name"></vxe-option>
                            </vxe-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="parent" :title="'上级' + title" :span="12">
                        <template #default="{ data }">
                            {{ data.parent.qiyejiancheng || '一级不需要填写' }}
                        </template>
                    </vxe-form-item>

                    <vxe-form-item field="qiyequancheng" title="企业全称" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.qiyequancheng" placeholder="企业全称"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="qiyejiancheng" title="企业简称" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.qiyejiancheng" placeholder="企业简称"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="sub_mchid" title="特约商户号" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.sub_mchid" placeholder="特约商户号"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="money" title="余额" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.money" placeholder="余额"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="lianxiren" title="联系人" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.lianxiren" placeholder="联系人"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="lianxidianhua" title="联系电话" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.lianxidianhua" placeholder="联系电话"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="kaishishijian" title="开始时间" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.kaishishijian" placeholder="开始时间" type="date"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="daoqishijian" title="到期时间" :span="12">
                        <template #default="{ data }">
                            <vxe-input v-model="data.daoqishijian" placeholder="到期时间" type="date"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="service_money" title="所需费用" :span="24">
                        <template #default="{ data }">
                            <vxe-input v-model="data.service_money" placeholder="所需费用"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="describe" title="企业描述" :span="24">

                        <template #default="{ data }">
                            <vxe-textarea v-model="data.describe" placeholder="企业描述"></vxe-textarea>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item :align="'right'" title-align="left" :span="24">

                        <template #default>
                            <vxe-button type="submit">提交</vxe-button>
                        </template>
                    </vxe-form-item>
                </vxe-form>
            </template>
        </vxe-modal>
    </v-container>
</template>

<script>
import { VXETable } from 'vxe-table'
import XEUtils from 'xe-utils'
export default {
    data() {
        return {
            title: '企业',
            apiUrl: {
                findUrl: '/company/getlist',
                createUrl: '/company/create',
                updateUrl: '/company/update',
            },
            // table配置
            tableLoading: false,
            tableData: [],
            sumFields: [],// 汇总的字段
            // form配置
            formData: {
                site: {},
                parent: '',
                qiyequancheng: '',
                qiyejiancheng: '',
                sub_mchid: '',
                money: '',
                lianxiren: '',
                lianxidianhua: '',
                kaishishijian: (new Date()).toISOString().substr(0, 10),
                daoqishijian: (new Date()).toISOString().substr(0, 10),
                service_money:'',
                describe: ''
            },
            defaultFormData: {
                site: '',
                parent: '',
                qiyequancheng: '',
                qiyejiancheng: '',
                sub_mchid: '',
                money: '',
                lianxiren: '',
                lianxidianhua: '',
                kaishishijian: (new Date()).toISOString().substr(0, 10),
                daoqishijian: (new Date()).toISOString().substr(0, 10),
                service_money:'',
                describe: ''
            },
            formRules: {
                qiyequancheng: [
                    { required: true, message: '请输入名称' }
                ]
            },
            selectRowData: null,
            showEditModal: false,
            editModalLoading: false,
            // 站点选择
            siteSelected: '',
            siteList: []
        }
    },
    mounted() {
        // 将表格和工具栏进行关联
        const $table = this.$refs.tableRef
        const $toolbar = this.$refs.toolbarRef
        if ($table && $toolbar) {
            $table.connect($toolbar)
        }
        this.getTableData()
        this.getSiteList()
    },
    methods: {
        getSiteList() {
            this.$axios.get('/config')
                .then(res => {
                    this.siteList = res.data
                })
        },
        // 获取表格数据
        getTableData() {
            this.tableLoading = true
            var params = {}
            if (this.companySelected) {
                params.company = this.companySelected
            }
            this.$axios.get(this.apiUrl.findUrl, { params: params })
                .then(res => {
                    console.log(res.data)
                    this.tableData = res.data ? res.data : []
                    this.tableLoading = false
                    VXETable.modal.message({ content: '请求成功', status: 'success' })
                }).catch(error => {
                    VXETable.modal.message({ content: error.message, status: 'error' })
                    this.tableLoading = false
                })
        },
        // 汇总表尾需要sum的字段
        footerMethod({ columns, data }) {
            var sum = 0
            return [
                columns.map((column, columnIndex) => {
                    if (columnIndex === 0) {
                        return '汇总'
                    }
                    if (this.sumFields.includes(column.field)) {
                        data.forEach(item => {
                            sum += Number(item[column.field])
                        })
                        return sum
                    }
                    return '/'
                })
            ]
        },
        // 点击【新增】事件
        insertEvent() {
            this.selectRowData = null
            this.formData = Object.assign({}, this.defaultFormData)
            delete this.formData.parent_id
            this.showEditModal = true
        },
        // 点击【子菜单】事件
        insertChildrenEvent(row) {
            this.selectRowData = null
            this.formData = Object.assign({}, this.defaultFormData)
            this.formData.parent_id = row._id
            this.formData.company = row.company
            this.showEditModal = true
        },
        // 点击【编辑】事件
        editEvent(row) {
            this.formData = Object.assign({}, row)
            this.selectRowData = row
            this.showEditModal = true
        },
        // 提交数据
        submitEvent() {
            const $table = this.$refs.tableRef
            this.editModalLoading = true
            this.$axios.post(this.selectRowData ? this.apiUrl.updateUrl : this.apiUrl.createUrl, this.formData)
                .then(res => {
                    if (this.selectRowData) {
                        VXETable.modal.message({ content: '保存成功', status: 'success' })
                        Object.assign(this.selectRowData, res.data)
                    } else {
                        VXETable.modal.message({ content: '新增成功', status: 'success' })
                        $table.insert(res.data)
                    }
                    this.editModalLoading = false
                    this.showEditModal = false
                }).catch(error => {
                    VXETable.modal.message({ content: error.message, status: 'error' })
                    this.editModalLoading = false
                })
        },
        formatTime({ cellValue }) {
            return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:ss:mm')
        }
    }
}
</script>

<style scoped></style>