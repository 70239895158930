<template>
    <v-row>
        <v-col cols="12">
            <v-card style="height: 100%;overflow: auto;">
                <v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
                </v-snackbar>
                <v-toolbar>
                    <v-toolbar-title>模块管理<span class="text-caption"> 图标获取地址(mdi-[name]):<a target="_blank"
                                href="https://materialdesignicons.com/">https://materialdesignicons.com/</a></span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mb-2" @click="add">
                        新增模块
                    </v-btn>
                </v-toolbar>
                <v-card-text class="d-flex">
                    <div style="width:300px;height:75vh;overflow: auto;">
                        <v-treeview transition :items="items" item-key="_id">
                            <template v-slot:label="{ item }">
                                <!-- 如果为母项 -->
                                <v-btn v-if="!item.parent" color="primary" text @click="edit(item)">
                                    <v-icon small>{{ item.icon }}</v-icon>{{ item.name }}({{ item.sort }})
                                </v-btn>
                                <!-- 如果为子项 -->
                                <v-btn v-if="item.parent" color="primary" text @click="edit(item)">
                                    <v-badge color="error" overlap :content="item.icon" offset-x="-10"
                                        :value="item.icon ? true : false">
                                        <span>{{ item.name }}({{ item.sort }})</span>
                                    </v-badge>
                                </v-btn>
                            </template>
                        </v-treeview>
                    </div>
                    <v-divider vertical class="mr-5"></v-divider>
                    <v-form :disabled="disabled" class="flex-grow-1">
                        <v-text-field v-model="edit_item.sort" label="排序"></v-text-field>
                        <v-text-field v-model="edit_item.name" label="名称"></v-text-field>
                        <v-text-field v-model="edit_item.icon" label="图标"></v-text-field>
                        <v-text-field v-model="edit_item.link" label="链接"></v-text-field>
                        <v-select :items="parents" v-model="edit_item.parent" label="上级菜单"></v-select>
                        <v-btn :disabled="disabled" color="primary" class="mr-4" @click="save">
                            {{ save_text }}
                        </v-btn>
                        <v-btn :disabled="disabled" color="error" class="mr-4" @click="cancel">
                            取消
                        </v-btn>
                        <v-btn :disabled="disabled" color="error" @click="del" class="float-right">
                            删除
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        snackbar: {
            show: false,
            text: "",
            color: "success",
            site_error: ''
        },
        edit_item: {},
        disabled: true,
        save_text: "保存",
        items: [],
    }),
    computed: {
        parents: function () {
            var parent_item = [{ value: '', text: '顶级模块' }]
            this.items.forEach((item) => {
                parent_item.push({ value: item._id, text: item.name })
            })
            return parent_item
        },
    },
    created() {
        this.getlist()
    },
    methods: {
        getlist() {
            this.$axios.get('navigation')
                .then((res) => {
                    this.items = res.data
                })
        },
        edit(item) {
            this.disabled = true
            this.edit_item = {}
            this.save_text = '保存'
            setTimeout(() => {
                this.edit_item = item
                this.disabled = false
            }, 500)
        },
        add() {
            this.disabled = true
            this.edit_item = {}
            this.save_text = '新增'
            setTimeout(() => {
                this.disabled = false
            }, 500);
        },
        save() {
            if (this.save_text == '新增') {
                this.$axios.post('navigation/create', this.edit_item)
                    .then((res) => {
                        this.snackbar = {
                            show: true,
                            text: res.data.message,
                            color: "success"
                        }
                        this.disabled = true
                        this.edit_item = {}
                        this.getlist()
                    })
            } else {
                // 更新
                // 不允许设置自己为自己的上级菜单
                console.log(this.edit_item._id != this.edit_item.parent)
                if (this.edit_item._id != this.edit_item.parent_id) {
                    this.$axios.post('navigation/update', this.edit_item)
                        .then((res) => {
                            this.snackbar = {
                                show: true,
                                text: res.data.message,
                                color: "success"
                            }
                            this.getlist()
                            this.disabled = true
                            this.edit_item = {}
                        })
                } else {
                    this.snackbar = {
                        show: true,
                        text: '不允许设置自己为自己的上级菜单',
                        color: "error"
                    }
                }
            }
        },
        cancel() {
            this.disabled = true
            this.edit_item = {}
            this.getlist()
        },
        del() {
            this.$axios.post('navigation/del', this.edit_item)
                .then((res) => {
                    console.log(res)
                    this.disabled = true
                    this.edit_item = {}
                    this.snackbar = {
                        show: true,
                        text: res.data.message,
                        color: "success"
                    }
                    this.getlist()
                })
        }
    }
}
</script>